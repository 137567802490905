import { useCallback, useMemo } from 'react'
import { Platform } from 'react-native'
import type { BusinessIconType, PageConfigSnapshot } from '@vatom/sdk/core'
import { useConfig } from '@vatom/sdk/react'
import { TabRoutes } from '@vatom/wallet-routes'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { theme } from '@vatom/wombo'

import { useBusinessSelector } from '../../../hooks/useBusinessSelector'
import { useBusinessTheme } from '../../../hooks/useBusinessTheme'

type Route = keyof typeof TabRoutes

type Item = BusinessIconType
type FilterScreenBusiness = Record<
  string,
  {
    filter: (item: Item) => boolean
    route: Route
  }
>

export const businessScreenFilters: FilterScreenBusiness = {
  wallet: {
    filter: (item: Item) => true,
    route: TabRoutes.Wallet
  },
  home: {
    filter: (item: Item) => item.id === 'home' && Object.keys(item).includes('link'),
    route: TabRoutes.Home
  },
  connect: {
    filter: (item: Item) => item.id === 'connect' || item.id === 'people' || item.id === 'spaces',
    route: TabRoutes.Connect
  },
  map: {
    filter: (item: Item) => item.id === 'map',
    route: TabRoutes.Map
  },
  ar: {
    filter: (item: Item) => item.id === 'ar',
    route: TabRoutes.MapAr
  }
}

type BusinessRoute = BusinessIconType & {
  route: Route
}
const getBusinessScreens = (navigationConfig: PageConfigSnapshot['features']['footer']) => {
  const filteredScreen: BusinessRoute[] = []

  if (!navigationConfig?.enabled) {
    return filteredScreen
  }
  if (navigationConfig.icons?.length) {
    navigationConfig.icons.forEach(item => {
      if (!item.id) {
        return false
      }
      const screenId = item.id.toLowerCase()
      const foundRoute = businessScreenFilters[screenId]
      if (!foundRoute) {
        // TODO: check for other microsites with knowing the key
        // Not route defined in the app
        return false
      }

      const isValidScreen = foundRoute.filter(item)
      if (isValidScreen) {
        filteredScreen.push({
          ...item,
          route: foundRoute.route
        })
        return
      }
    })
  }
  return filteredScreen
}

const useTabNavConfig = () => {
  const { business } = useBusinessSelector()
  const walletConfig = useVatomWalletSdkStore()
  const config = useConfig()

  const businessScreens = useMemo(() => {
    if (!business) {
      return []
    }
    // Apply filter for business screens
    const customScreens = business?.pageConfig?.features?.footer
    if (!customScreens) {
      return []
    }
    // @ts-expect-error not sure how to fix this
    return getBusinessScreens(customScreens)
  }, [business])

  const shouldRenderTab = useCallback(
    (tabName: string) => {
      if (!business) {
        if (tabName === TabRoutes.Home) {
          // INFO: No Home for default Vatom app at the moment
          return false
        }
        if (tabName === TabRoutes.MapAr) {
          // INFO: No MapAr for Vatom, you can access from Map screen
          return false
        }

        if (Platform.OS === 'web') {
          if (!walletConfig.isEmbedded && walletConfig.config.visibleTabs.includes(tabName)) {
            // TODO: check for tabConfigs.includes('inventory')??
            return true
          }
        }
        return true
      }
      // Check for business
      if (tabName === TabRoutes.Wallet) {
        // INFO: always display wallet for businesses
        return true
      }
      const availableInBusiness = businessScreens.find(
        screen => screen.route.toLocaleLowerCase() === tabName.toLocaleLowerCase()
      )
      return Boolean(availableInBusiness)
    },
    [business, businessScreens, walletConfig]
  )

  const getTabConfig = useCallback(
    (tabName: string) => {
      if (!business) {
        // TODO: check for Wallet SDK Config
        return {}
      }

      const config = businessScreens.find(
        screen => screen.route?.toLowerCase() === tabName.toLocaleLowerCase()
      )

      return {
        label: config?.title,
        icon: config?.src,
        link: config?.link ?? null
      }
    },
    [business, businessScreens]
  )

  const tabsConfig = Object.keys(TabRoutes).reduce(
    (acc, route) => ({
      ...acc,
      [route]: getTabConfig(route)
    }),
    {} as Record<Route, ReturnType<typeof getTabConfig>>
  )

  const tabsToRender = Object.keys(TabRoutes).filter(route =>
    shouldRenderTab(route)
  ) as Array<Route>

  const initialRoute = useMemo(() => {
    if (!business) {
      return TabRoutes.Wallet
    }
    const defaultScreen = businessScreens.find(screen => screen?.default === true)
    const defaultInitial = undefined
    if (defaultScreen?.route) {
      return tabsToRender.includes(defaultScreen?.route) ? defaultScreen?.route : defaultInitial
    }
    return defaultInitial
  }, [business, businessScreens, tabsToRender])

  const firstTimeRoute = useMemo(() => {
    if (!business) {
      return TabRoutes.Wallet
    }
    const firstTimeScreen = businessScreens.find(screen => screen?.initial === true)
    if (firstTimeScreen?.route) {
      return tabsToRender.includes(firstTimeScreen?.route) ? firstTimeScreen?.route : undefined
    }
    return undefined
  }, [business, businessScreens, tabsToRender])

  return {
    tabsConfig,
    tabsToRender,
    initialRoute,
    firstTimeRoute,
    isEmbedded: walletConfig.isEmbedded,
    SDKNavigationHidden: config.features.hideNavigation,
    SDKDrawerHidden: config.features.hideDrawer,
    SDKdisableArPickup: config.features.screensConfig.Map.disableArPickup,
    isHidden: business?.pageConfig?.features?.footer?.visible === false
  }
}

const useTabNavColors = () => {
  const { pageTheme, isDark } = useBusinessTheme()
  const tabBarColorScheme = {
    light: {
      inactiveTint: theme.colors.lightText,
      activeTint: theme.colors.darkText,
      border: theme.colors.extraLightText,
      background: theme.colors.white,
      indicatorBackground: theme.colors.black
    },
    dark: {
      inactiveTint: theme.colors.grayCool[600],
      activeTint: theme.colors.grayCool[300],
      border: theme.colors.grayCool[800],
      background: pageTheme.background,
      indicatorBackground: theme.colors.grayCool[300]
    }
  }

  const tabBarColors = useMemo(() => {
    return isDark ? tabBarColorScheme.dark : tabBarColorScheme.light
  }, [tabBarColorScheme.dark, tabBarColorScheme.light, isDark])

  return tabBarColors
}

export { useTabNavColors, useTabNavConfig }
