import { Platform } from 'react-native'
import { onSnapshot } from 'mobx-state-tree'

import logger from '../../logger'
import * as storage from '../../utils/storage'
import { Environment } from '../environment'

import type { RootStore } from './'
import { RootStoreModel } from './'
/**
 * The key we'll be saving our state as within async storage.
 */
const ROOT_STATE_STORAGE_KEY = 'root'

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment() {
  const env = new Environment()
  await env.setup()
  return env
}

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  let rootStore: RootStore
  let data: any

  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment()
  try {
    // load data from storage
    data = (await storage.load(ROOT_STATE_STORAGE_KEY)) || {}
    rootStore = RootStoreModel.create(data, env)
    logger.info('[setupRootStore] restored successfully')
  } catch (error) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootStoreModel.create({}, env)

    logger.error(
      '******************************* [setupRootStore] ********************************'
    )
    logger.error(
      '*******                                                                   *******'
    )
    logger.error(
      '*******                   FAILED TO RESTORE APP FROM DISK!                     *******'
    )
    logger.error(
      '*******                                                                   *******'
    )
    logger.error(
      '*********************************************************************************'
    )
    logger.error(`Error: ${error}`)

    // but please inform us what happened
    // __DEV__ && logger.tron.error(e.message, null)
  }

  // reactotron logging
  if (__DEV__) {
    env.reactotron.setRootStore(rootStore, data)
    if (Platform.OS !== 'web') {
      const { DevSettings } = require('react-native')
      if (DevSettings) {
        DevSettings.addMenuItem('Clear Store', () => {
          rootStore.clear()
        })
      }
    }
  }

  // track changes & save to storage
  // @ts-ignore expected error
  onSnapshot(rootStore, snapshot => storage.save(ROOT_STATE_STORAGE_KEY, snapshot))

  return rootStore
}
