/**
 * Constants used by the P2P system
 */
export default {
  /** Binary message opcodes */
  Opcodes: {
    /** A ping packet, followed by a 4-byte random data payload */
    Ping: 0,

    /** A ping reply packet, followed by the same 4-byte random data payload */
    Pong: 1,

    /** A JSON command packet, followed by 4 bytes specifying the size, and then the utf8 encoded buffer after that */
    Command: 2,

    /** (deprecated) Update to the Realtime Data. Next two bytes specify number of fields, and each field after that is an 8-byte float. */
    UpdateRealtimeDataOld: 3,

    /** An update packet from a specific user, which contains the user's metadata as well as their audio data */
    BundledUpdatePacket: 4
  }
}
