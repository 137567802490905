import { useCallback } from 'react'
import { Platform } from 'react-native'
import { useLinkTo } from '@react-navigation/native'
import { useGetUserProfile, usePatchUserProfile } from '@vatom/sdk/react'
import { pathToRegexp } from 'path-to-regexp'
import { create } from 'zustand'

import { useStore } from '../models'
import { VatomWallet } from '../utils/WalletSdk'

interface WasVisitedStore {
  isVisited: boolean
  isDeeplink: boolean
}

export const useWasVisitedStore = create<WasVisitedStore>(set => ({
  isVisited: false,
  isDeeplink: false
}))

// auto navigate base on the last visited routes from '/me'
export const useLastVisitedRoute = () => {
  // TODO: check if the user opened the app via deeplink.
  // const store = useStore()

  // const isDifferentDeepLink = (lastVisitedRoute: string) => {
  //   console.log('LOG: > useLastVisitedRoute > store.linking.linkingUrl:', store.linking.linkingUrl)
  //   if (store.linking.linkingUrl === undefined) {
  //     return false
  //   }
  //   const isLastVisitedBusiness = pathToRegexp('/b/:business').test(lastVisitedRoute)
  //   const isDeepLinkSameBusiness = pathToRegexp('/b/:business').test(store.linking.linkingUrl)
  //   if (isLastVisitedBusiness && !isDeepLinkSameBusiness) {
  //     return true
  //   }
  //   return false
  // }

  const linkTo = useLinkTo()
  useGetUserProfile({
    refetchOnMount: true,
    select: user => {
      return user?.lastVisitedRoute ?? ''
    },
    onSuccess: (lastVisitedRoute: string) => {
      const { isVisited, isDeeplink } = useWasVisitedStore.getState()
      console.log(
        'lastVisitedRoute',
        Boolean(lastVisitedRoute && !isVisited && !isDeeplink),
        lastVisitedRoute,
        isVisited,
        isDeeplink
      )
      // if (isDifferentDeepLink(lastVisitedRoute)) {
      //   return
      // }
      // TODO: We might want a similar handling for mobile
      const isRoot = Platform.OS === 'web' ? window.location.pathname === '/' : true

      if (lastVisitedRoute && !isVisited && !isDeeplink && isRoot) {
        useWasVisitedStore.setState({ isVisited: true })
        linkTo(lastVisitedRoute)
      }
    },
    cacheTime: 0,
    staleTime: 0,
    enabled: !VatomWallet.isEmbedded()
  })
}

export const useLastVisitedRouteMutation = () => {
  const patchUserMutation = usePatchUserProfile()

  return useCallback(
    (businessId: string) => {
      if (VatomWallet.isEmbedded()) {
        return
      }
      patchUserMutation.mutate({ lastVisitedRoute: businessId ? `/b/${businessId}` : '' })
    },
    [patchUserMutation]
  )
}
