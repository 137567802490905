import type { PropsWithChildren } from 'react'
import { createContext, useState } from 'react'

import { useMessage } from '../hooks/useMessage'
import type { BusinessNavigation, navigateTap, tabRoute } from '../types'

export const BusinessNavigationContext = createContext<BusinessNavigation>({} as BusinessNavigation)

export const BusinessNavigationProvider = (props: PropsWithChildren) => {
  const [tabs, setTabs] = useState<tabRoute[]>([])

  const { sendToParent } = useMessage('GET_BUSINESS_TABS', (_, payload) => {
    setTabs(payload)
  })

  const canNavigate = (route?: string) => {
    if (tabs.length === 0) return false
    return tabs.some(t => t === route)
  }

  const navigate = (params: navigateTap) => {
    sendToParent({ type: 'NAVIGATE_TO_CONNECT_TAB', payload: params })
  }

  const navigateToUserprofile = (userId: string) => {
    sendToParent({
      type: 'NAVIGATE_TO_USER_PROFILE',
      payload: {
        userId
      }
    })
  }

  return (
    <BusinessNavigationContext.Provider
      value={{ tabs, canNavigate, navigate, navigateToUserprofile }}
    >
      {props.children}
    </BusinessNavigationContext.Provider>
  )
}
