import { useEffect, useRef } from 'react'
import { AppState } from 'react-native'

export function useOnAppFocus<T>(refetch: () => Promise<T>) {
  const appState = useRef(AppState.currentState)

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        refetch()
      }
      appState.current = nextAppState
    })

    return () => subscription.remove()
  }, [refetch])
}

export default useOnAppFocus
