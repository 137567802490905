import { useCallback, useEffect } from 'react'
import { Platform } from 'react-native'
import { useQuery } from '@tanstack/react-query'
// import { BVatomPluginType } from '@vatom/BVatom/plugin'
import type { SessionTokenSnapshot } from '@vatom/sdk/core'
import { isSessionObject, SessionSource, SessionType } from '@vatom/sdk/core'
import { getConfig, useOnAppFocus, useSDK } from '@vatom/sdk/react'
import { observer } from 'mobx-react-lite'

import logger from '../logger'

const TOKEN_EXPIRE_LEEWAY = 20 * 1000 // 20 sec

type RefreshTokenProviderProps = {
  onRefreshFailed: () => void
}

const isWebLogout = () => Platform.OS === 'web' && window.location.href.includes('logout-callback')

export const isSessionExpiring = (sdk: ReturnType<typeof useSDK>) => {
  const sessionStore = sdk.dataPool.sessionStore
  if (!sessionStore.vatomExpiresAt) {
    return false
  }

  const expiresIn = () => sessionStore.vatomExpiresAt - Date.now()
  const isAboutToExpire = expiresIn() < TOKEN_EXPIRE_LEEWAY
  return isAboutToExpire
}

export const refreshToken = async (
  sdk: ReturnType<typeof useSDK>
): Promise<SessionTokenSnapshot | null> => {
  const config = getConfig()
  const sessionStore = sdk.dataPool.sessionStore
  const isAboutToExpire = isSessionExpiring(sdk)
  if (isAboutToExpire) {
    if (!isWebLogout()) {
      const baseURL = config.api.oidc
      const newSession = await sessionStore.refreshVatomToken({
        baseURL,
        clientId: config.authentication.clientId
      })
      return newSession ?? null
    }
  }
  const currentSession =
    sessionStore.getFirstByTypeAndSource(SessionType.JWT, SessionSource.Vatom) ?? null

  return (currentSession?.value as SessionTokenSnapshot) ?? null
}

export const VatomRefreshTokenProvider = observer(
  ({ onRefreshFailed }: RefreshTokenProviderProps) => {
    const sdk = useSDK()
    const sessionStore = sdk.dataPool?.sessionStore
    // const queryClient = useQueryClient()

    const logout = useCallback(() => {
      try {
        onRefreshFailed()
      } catch (error) {
        console.log('LOG: VatomRefreshTokenProvider > logout > error:', error)
      }
    }, [onRefreshFailed])

    // @TODO: need to dig this event out of the VatomPlugin regions...
    // vatomApi.client.on('logout', () => {
    //   logger.warn('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! LOG OUT')
    //   self.doLogout()
    // })

    // const bVatomPlugin = sdk.dataPool.getPlugin('BVatomPlugin') as BVatomPluginType

    const leeway = 20 * 1000
    const expiresIn = () => sessionStore.vatomExpiresAt - Date.now()

    // https://stackoverflow.com/questions/12633405/what-is-the-maximum-delay-for-setinterval#:~:text=%22What's%20the%20maximum%20delay%20for,2**32ms%20~%2050%20days.
    const biggetInterval = 2147483647
    const getInterval = () => {
      const refetchInterval =
        expiresIn() > biggetInterval ? false : Math.floor(expiresIn() - leeway)
      return refetchInterval
    }
    const queryFn = async () => {
      if (sessionStore.sessions.find(s => s.source === SessionSource.Vatom) === undefined) {
        logout()
      }
      console.log(
        '[RefreshTokenProvider.useEffect] refreshToken',
        expiresIn() / (1000 * 60),
        'minutes'
      )
      console.log('[RefreshTokenProvider.useEffect] refresing? ', expiresIn() < leeway)
      return await refreshToken(sdk)
    }

    const { refetch } = useQuery({
      queryKey: ['refresh-token'],
      queryFn,
      retry(failureCount, error) {
        logger.error('refresh token failed', error)
        if (failureCount > 3) {
          logout()
          return false
        }
        return true
      },
      refetchIntervalInBackground: true,
      refetchOnReconnect: true,
      refetchInterval: getInterval(),
      refetchOnMount: true,
      refetchOnWindowFocus: true
    })

    const refreshBlockVToken = useCallback(async () => {
      try {
        const plugin = sdk.dataPool.getPlugin('BVatomPlugin') as any
        const refreshResult = await plugin.api.client.refreshToken()
        console.log('Refreshed token', refreshResult)
      } catch (refreshError) {
        const session = sessionStore.getFirstByTypeAndSource(SessionType.JWT, SessionSource.Vatom)
        if (!session) {
          return
        }

        if (isSessionObject(session.value)) {
          const token = session?.value.accessToken

          if (token) {
            sdk.vatomIncApi.performTokenExchange(token)
          }
        }
      }
    }, [sdk.dataPool, sdk.vatomIncApi, sessionStore])

    useEffect(() => {
      refreshBlockVToken()
    }, [refreshBlockVToken])

    useOnAppFocus(refetch)
    return null
  }
)

export default VatomRefreshTokenProvider
