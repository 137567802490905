import type { Instance } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import logger from '../../../logger'
import type { Plugin, PluginableProps } from '../../BasePlugin'
import { composePluginableStore } from '../../BasePlugin'
import type { Region, RegionTypes } from '../../Region'
import { composePluginableRegionStore } from '../../Region/helpers'
import { DataPoolStore } from '../DataPoolStore'

export const composePluginableDataPoolStore = (props: PluginableProps) => {
  const CompositeRegionModel = composePluginableRegionStore(props)
  type CompositeRegion = Instance<typeof CompositeRegionModel>
  return types
    .compose(
      DataPoolStore,
      composePluginableStore(props),
      types.model({
        regions: types.optional(types.array(CompositeRegionModel), [])
      })
    )
    .actions(self => ({
      addRegion(region: CompositeRegion) {
        self.regions.push(region)
      },
      // Fetches or creates a region (in this cases its an aggregate of all the regions)
      region(id: RegionTypes, descriptor?: string): CompositeRegion {
        // logger.info(
        //   '[DataPool.region] Looking for composite region: ',
        //   self.regions.length,
        //   id,
        //   descriptor
        // )
        const existingCompositeRegion = self.regions.find(r => r.matches(id, descriptor))
        if (existingCompositeRegion) {
          // logger.info(
          //   '[DataPool.region] Found composite region: ',
          //   id,
          //   descriptor,
          //   existingCompositeRegion
          // )
          return existingCompositeRegion
        }

        // If region is not found, close all the existing regions with the same id - this is to prevent memory filling up with geopos regions with different coords

        // const compositeRegion = CompositeRegionModel.create({ id, descriptor, _id: nanoid() })
        const compositeRegion = CompositeRegionModel.create({
          id,
          descriptor,
          _id: descriptor ? `CompositeRegion-${id}-${descriptor}` : `CompositeRegion-${id}`
        })

        logger.info('[DataPool.region] Created composite region: ', id, descriptor)
        self.regions.push(compositeRegion)
        self.plugins.forEach(plugin => {
          const region = plugin.region(id, descriptor) as Region

          if (region) {
            compositeRegion.addRegion(region)

            logger.info('[DataPool.region] registered new composite region: ', id, descriptor)
            region
              .load()
              .then(() => {
                self.regions.filter(r => {
                  // HACK: We don't want the map deleting the region that was loaded by the AR screen if it loaded after navigating
                  const parsedDescriptor = JSON.parse(r.descriptor ?? '{}')
                  if (parsedDescriptor.keepAlive) {
                    return false
                  }
                  return id === r.id && descriptor !== r.descriptor
                })
              })
              .catch((error: Error) => {
                logger.error('[DataPool.region] load', error)
                region.setIsLoading(false)
              })
          }
        })

        self.emit('region.added', !!compositeRegion)

        logger.info('[DataPool.region] Created composite region.load')

        return compositeRegion
      },

      removeRegion(region: CompositeRegion) {
        for (let i = 0; i < self.regions.length; i++) {
          if (self.regions[i] === region) {
            self.regions.splice(i, 1)
            return true
          }
        }
        return false
      }
    }))
    .actions(self => ({
      /** Clear cached items */
      clearCache() {
        logger.info('[DataPool].clearCache')
        // Remove local storage cached items
        // @TODO: remove legacy storage:
        // for (let key in localStorage)
        // 	if (key.startsWith("sync.")) localStorage.removeItem(key);
        self.payload = ''
        self.sessionStore.clear()
        Array.from<Plugin>(self.plugins.values()).map(p => p.clearCache())
        self.user.identities.clear()
      },
      // this function is to be called when we want to connect our datapool to a new provider, at the end of the day we want a list of objects from that provider and a suscription to the changes made to any of those objects
      async register(plugin: Plugin) {
        logger.info('[DataPool.register] plugin.name', plugin.name)
        const existingPlugin = Array.from<Plugin>(self.plugins.values()).find(
          p => p.name === plugin.className
        )

        if (existingPlugin) {
          try {
            logger.info(
              '[DataPool.register] plugin already exists, no need to re-register',
              plugin.name
            )
            // restore plugin and refresh region tokens
            const countTokens = existingPlugin.regions.reduce(
              (count, region) => count + region.tokens.length,
              0
            )
            logger.info('[DataPool.register] reloading...', plugin.name, countTokens)
            // DEPRECATED: do not block UI
            // await existingPlugin.init()
            // logger.info(
            //   '[DataPool.register] plugin already exists, count regions',
            //   existingPlugin.regions.length
            // )
            // await Promise.all(existingPlugin.regions.map((region: any) => region.load())).catch(
            //   error => logger.error('[DataPool.register] region.load', error)
            // )

            existingPlugin
              .init()
              // NOTE: The region will load itself on init or whenever the sessions are updated
              // .then(async () => {
              //   logger.info(
              //     '[DataPool.register] plugin already exists, count regions',
              //     existingPlugin.regions.length
              //   )
              //   Promise.all(
              //     existingPlugin.regions.map((region: any) => {
              //       const existingCompositeRegion = self.region(region.id, region.descriptor)
              //       existingCompositeRegion.addRegion(region)
              //       return region.load()
              //     })
              //   )
              //     .catch(error => logger.error('[DataPool.register] region.load', error))
              //     .then(async () => {
              //       const countTokens = existingPlugin.regions.reduce(
              //         (count, region) => count + region.tokens.length,
              //         0
              //       )
              //       logger.info('[DataPool.register] reloaded', plugin.name, countTokens)
              //     })
              // })
              .catch(error => {
                logger.error('[DataPool.register] existingPlugin.init', error)
              })
          } catch (error) {
            logger.error('[DataPool.register] existingPlugin.init', error)
          }

          return
        }

        self.addPlugin(plugin)
        // @TODO: remove .init (since we already have the DataPool on the root)
        await plugin.init()
        // if (plugin.webSockets) {
        //   plugin.webSockets.on('message', self.onWebSocketMessage)
        //   // plugin.webSockets.trigger = self.trigger.bind(this);
        //   // plugin.webSockets.emit = self.emit.bind(this);
        // }
      }
    }))
    .views(self => ({
      get isLoading() {
        const plugins = Array.from<Plugin>(self.plugins.values())
        return plugins.reduce((sumPluginsFlag, plugin) => {
          const isRegionsLoading = plugin.regions.reduce((sumRegionFlags, region) => {
            logger.info(
              '[DataPool.isLoading]',
              plugin.className,
              plugin.name,
              region.className,
              region.id,
              region.isLoading
            )
            return sumRegionFlags || region.isLoading
          }, false)
          return sumPluginsFlag || isRegionsLoading
        }, false)
      },
      get countTokens() {
        const plugins = Array.from<Plugin>(self.plugins.values())
        const allRegions = plugins.reduce(
          (concatenated: any, plugin) => concatenated.concat(plugin.regions),
          []
        )
        return allRegions.reduce((sum: number, region: any) => sum + region.tokens.length, 0)
      }
    }))
  // .actions(self => ({
  //   afterCreate() {
  //     self.regions.map(r => r.clear())
  //   }
  // }))
}
