import { useEffect } from 'react'
import { useLinkTo } from '@react-navigation/native'
import { useIsAuthed } from '@vatom/sdk/react'
import { create } from 'zustand'

export const navigationPendingStore = create<{
  navigationPath: string | null
  setPendingNavigationPath: (path: string | null) => void
  navigationIsready: boolean
  setNavigationIsReady: (isReady: boolean) => void
}>(set => ({
  navigationPath: null,
  navigationIsready: false,
  setPendingNavigationPath: (navigationPath: string | null) => {
    set({ navigationPath })
  },
  setNavigationIsReady: (isReady: boolean) => {
    set({ navigationIsready: isReady })
  }
}))

export const useNavigationPending = () => {
  const link = useLinkTo()
  const navigationStore = navigationPendingStore()
  const isAuthed = useIsAuthed()

  useEffect(() => {
    console.log('LOG > useNavigationPending > state', {
      navigationPath: navigationStore.navigationPath,
      navigationIsready: navigationStore.navigationIsready,
      isAuthed
    })
    if (navigationStore.navigationPath && navigationStore.navigationIsready && isAuthed) {
      link(navigationStore.navigationPath)

      setTimeout(() => {
        navigationStore.setPendingNavigationPath(null)
      }, 100)
    }
  }, [isAuthed, link, navigationStore])
}
