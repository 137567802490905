import type { IAnyModelType, IAnyType } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import logger from '../../../logger'
import type { BasePluginStoreType, Plugin, PluginableProps } from '../BasePluginStore'
import { BasePluginStore } from '../BasePluginStore'

export const composePluginableStore = (props: PluginableProps) => {
  const plugins = props.classes ?? ([] as IAnyModelType[])
  const anyTypes = plugins.map(plugin => plugin) as IAnyType[]
  return types
    .model({
      plugins: types.optional(
        types.map(
          types.union(
            {
              eager: false,
              dispatcher: (snapshot: any): IAnyType => {
                // logger.info(
                //   '[createPluginableStore] snapshot',
                //   snapshot,
                //   'plugin names',
                //   plugins.map(plugin => plugin.name)
                // )
                if (!plugins.length) {
                  return BasePluginStore
                }
                const discriminatedPlugin = plugins.filter(
                  plugin => plugin.name === snapshot.name || plugin.name === snapshot.className
                )
                if (!discriminatedPlugin || discriminatedPlugin.length === 0) {
                  throw new Error('[createPluginableStore] Unable to discrimate Plugin')
                }
                // logger.info('[createPluginableStore] snapshot', typeof discriminatedPlugin)
                return discriminatedPlugin[0] as IAnyType
              }
            },
            ...anyTypes
          )
        ),
        {}
      )
    })
    .actions(self => ({
      addPlugin(plugin: Plugin) {
        self.plugins.set(plugin.name, plugin)
      },
      // Close all the regions
      close(id: string) {
        Array.from<BasePluginStoreType>(self.plugins.values()).map(plugin => plugin.close(id))
      }
    }))
    .views(self => ({
      isLoading() {
        Array.from<BasePluginStoreType>(self.plugins.values()).map(plugin => {
          Array.from(plugin.regions.values()).map(region => {
            if (region.id === 'inventory') {
              // eslint-disable-next-line no-extra-boolean-cast
              if (!!region._syncPromise) return true
            }
          })
        })
        return false
      },
      getPlugin<PluginType>(pluginName: string): PluginType | null {
        if (self.plugins.has(pluginName)) {
          const plugin = self.plugins.get(pluginName)
          return plugin as PluginType
        }
        return null
      }
    }))
}
