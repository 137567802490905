import type { PropsWithChildren } from 'react'
import { createContext, useState } from 'react'

import { useMessage } from '../hooks/useMessage'
import type {
  BusinessProviderTypes,
  BusinessType,
  ClaimTokenOptions,
  FindTokenOptions,
  PublicTokenType
} from '../types'

import { useExperience } from './ExperienceProvider'

export const BusinessContext = createContext<BusinessProviderTypes>({} as BusinessProviderTypes)

export const BusinessProvider = (props: PropsWithChildren) => {
  const [business, setBusiness] = useState<BusinessType>()
  const [tokens, setTokens] = useState<PublicTokenType[]>()
  const [isLoadingTokens, setIsLoadingTokens] = useState(true)
  const { sendToParent } = useMessage()
  const { navigateToNFTDetail } = useExperience()

  useMessage('GET_BUSINESS', (_, payload) => {
    if (payload) {
      setBusiness(payload)
    }
  })

  useMessage('GET_BUSINESS_TOKENS', (_, payload) => {
    if (payload) {
      setIsLoadingTokens(false)
      setTokens(payload)
    }
  })

  useMessage('SITE_GO_BACK', () => {
    window.history.back()
  })

  const findToken = (options: FindTokenOptions) => {
    const { autoClaim, claimLink, sync } = options

    if (!business) {
      console.error('Business is not ready')
      return null
    }

    const objectDefinitionIds = options.objectDefinitionIds?.length
      ? options.objectDefinitionIds
      : []

    if (options.objectDefinitionId) {
      objectDefinitionIds.push(options.objectDefinitionId)
    }

    const token = tokens?.find(token =>
      objectDefinitionIds.includes(token.studioInfo.objectDefinitionId)
    )

    if (token) {
      return navigateToNFTDetail(token.tokenId, business.id)
    }

    if (autoClaim || claimLink) {
      return claimToken({
        objectDefinitionId: objectDefinitionIds[0],
        campaignId: options.campaignId,
        claimLink,
        sync
      })
    }
  }

  const claimToken = (options: ClaimTokenOptions) => {
    if (!business) {
      console.error('Business is not ready')
      return null
    }

    return sendToParent({
      type: 'CLAIM_TOKEN',
      payload: {
        businessId: business.id,
        campaignId: options?.campaignId ?? business.defaultCampaignId,
        objectDefinitionId: options.objectDefinitionId,
        claimLink: options.claimLink,
        sync: options.sync
      }
    })
  }

  return (
    <BusinessContext.Provider value={{ business, tokens, isLoadingTokens, findToken, claimToken }}>
      {props.children}
    </BusinessContext.Provider>
  )
}
