import type { IAnyModelType, IAnyType, IMapType, Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import { RegionStore } from '../Region'

export interface PluginableProps {
  classes?: IAnyModelType[]
  plugins?: Plugin[]
}

const className = 'BasePluginStore'

export const BasePluginStore = types
  .model(className)
  .props({
    className,
    name: types.identifier,
    regions: types.optional(types.array(RegionStore), [])
  })
  .actions(self => ({
    close(id: string) {
      return true
    },
    // DEPRECATED: we can grab this from the root/parent store
    // async init(dp: DataPoolStoreType) {
    async init() {
      throw new Error('Plugins must override init')
    },
    clearCache() {
      self.regions.map(r => r.clear())
    }
  }))
  .views(self => ({
    get availableRegions(): IAnyType[] {
      throw new Error(`Subclasses must override Plugin.availableRegions()`)
    },
    get availableTokens(): IAnyType[] {
      throw new Error(`Subclasses must override Plugin.availableTokens()`)
    }
  }))

export type BasePluginStoreType = Instance<typeof BasePluginStore>
export type BasePluginStoreSnapshot = SnapshotOut<typeof BasePluginStore>
export type Plugin = BasePluginStoreType

export interface PluginableStore extends BasePluginStoreType {
  plugins: IMapType<IAnyModelType>
}

export const isPlugin = (b: any): b is Plugin => {
  return (b as Plugin).availableRegions !== undefined
}
