// import Map3D from "../Map3D"
import P2PAvatar from './P2PAvatar'

/**
 * This class contains Sweb-specific code for the P2P avatar connection.
 */
export default class SWebP2PAvatar extends P2PAvatar {
  /** @type {AvatarMapItem} Avatar map item */
  avatarMapItem = null

  /** Create fast moving avatar */
  createAvatar() {
    // Stop if already created
    // if (this.avatarMapItem)
    return

    // Stop if in data-only mode
    // if (this.manager.dataOnly)
    //     return

    // Prepare avatar
    // let id = `p2puser:${this.userID}:${this.connectionID}`
    // this.avatarMapItem = {}
    // this.avatarMapItem.firebaseUserID = this.userID
    // this.avatarMapItem.p2pAvatar = this

    // // Refresh all other avatars with the same user ID, in case they were hidden since ours took priority
    // for (let otherAvatar of Map3D.main.source.avatars)
    //     if (otherAvatar.firebaseUserID == this.userID)
    //         otherAvatar.refreshUserInfo()
  }

  /** Called on close */
  close() {
    super.close()

    // Stop if already removed
    // if (!this.avatarMapItem)
    //     return

    // // Remove avatar
    // Map3D.main.source.removeItem(this.avatarMapItem.id)
    // this.avatarMapItem = null

    // // Refresh all other avatars with the same user ID, in case they were hidden since ours took priority
    // for (let otherAvatar of Map3D.main.source.avatars)
    //     if (otherAvatar.firebaseUserID == this.userID)
    //         otherAvatar.refreshUserInfo()
  }

  /** Called when metadata is updated */
  onMetadataUpdate() {
    // Create avatar
    this.createAvatar()
    if (!this.avatarMapItem) return

    // Update position
    // this.avatarMapItem.networkPositionUpdate(this.metadata.x, this.metadata.y, this.metadata.z)

    // // Update volume
    // this.avatarMapItem.volume = this.metadata.volume
    // this.avatarMapItem.orientation = this.metadata.orientation
  }

  /** Get debug stats */
  getDebugStats() {
    // Audio flags
    let audioFlags = []
    if (this.metadata.muted) audioFlags.push('muted')
    if (this.hasSentAudioStream) audioFlags.push('out')
    if (this.hasReceivedAudioStream) audioFlags.push('in')
    audioFlags.push(Math.round(this.audioRadius) + 'm')

    // Create text for this peer
    return `#${String(this.index).padStart(4, '0')} user=${this.userID} distance=${
      Math.round(this.distance) || '?'
    }m audio=${audioFlags.join(',') || 'none'} ping=${
      this.stats.ping < 0 ? '?' : this.stats.ping
    }ms data=${(this.stats.bytesWrittenPerSecond / 1024).toFixed(1)}kb/s▲ ${(
      this.stats.bytesReadPerSecond / 1024
    ).toFixed(1)}kb/s▼`
  }

  /** Returns the audio broadcasting range of this remote peer, in meters */
  // get audioRadius() {

  //     // Cache this value since it's heavy to process
  //     let now = Date.now()
  //     if (this._lastAudioRadiusFetch && now - this._lastAudioRadiusFetch < 250) return this._lastAudioRadius
  //     this._lastAudioRadiusFetch = now

  //     // Check the zone we are in
  //     let ourZone = Map3D.main.zoneManager.activeZone
  //     let peerZone = Map3D.main.zoneManager.zonesForPosition(this.metadata.x, this.metadata.y, this.metadata.z)[0]

  //     // If peer is inside a zone with outbound audio disabled, and we are not in that same zone, disable audio from this peer
  //     if (ourZone != peerZone && peerZone?.properties?.zone_audio_disable_outbound) {
  //         this._lastAudioRadius = 0
  //         return this._lastAudioRadius
  //     }

  //     // If peer is outside our zone and our zone has disabled incoming audio from outside, then disable audio from this peer
  //     if (ourZone != peerZone && ourZone?.properties?.zone_audio_disable_inbound) {
  //         this._lastAudioRadius = 0
  //         return this._lastAudioRadius
  //     }

  //     // We should be safe to hear this user. Return the attenuation zone for the current zone if any.
  //     // NOTE: Attenuation values are negative to indicate linear attenuation, this is a leftover from the mixers...
  //     if (ourZone?.properties?.zone_audio_attenuation < 0) {
  //         this._lastAudioRadius = -ourZone.properties.zone_audio_attenuation
  //         return this._lastAudioRadius
  //     }

  //     // Not in a zone or no attenuation set, just use the default attenuation distance
  //     this._lastAudioRadius = 10
  //     return this._lastAudioRadius

  // }

  /** True if this peer should be able to hear us. Note that this is the inverse situation to the `audioRadius` field, which controls wether we can hear them. */
  // get shouldSendAudio() {

  //     // Check the zone we are in
  //     let ourZone = Map3D.main.zoneManager.activeZone
  //     let peerZone = Map3D.main.zoneManager.zonesForPosition(this.metadata.x, this.metadata.y, this.metadata.z)[0]

  //     // If peer is outside our zone with outbound audio disabled, they cannot hear us
  //     if (ourZone != peerZone && ourZone?.properties?.zone_audio_disable_outbound)
  //         return false

  //     // If peer is outside our zone with inbound audio disabled, they cannot hear us
  //     if (ourZone != peerZone && peerZone?.properties?.zone_audio_disable_inbound)
  //         return false

  //     // We are in the same zone, or audio is allowed to pass between our zones... Stop audio if peer's attenuation means they can't hear us
  //     let attenuationDistance = peerZone?.properties?.zone_audio_attenuation < 0 ? -peerZone.properties.zone_audio_attenuation : 10
  //     if (!this.distance || this.distance <= 0 || this.distance > attenuationDistance + 5)
  //         return false

  //     // Don't send audio if we are muted, but if we're already sending audio, then keep sending it... This is to prevent delays when unmuting
  //     if (this.manager.sharedMetadata.muted && !this.hasSentAudioStream)
  //         return false

  //     // Peer can hear us!
  //     return true

  // }
}
