import 'event-target-polyfill'

type CustomEventOptions = Readonly<{
  bubbles?: boolean
  cancelable?: boolean
  composed?: boolean
  detail?: Record<string, unknown>
}>

class CustomEventPolyfill extends Event {
  detail

  constructor(typeArg: string, options: CustomEventOptions) {
    const { bubbles, cancelable, composed } = options
    super(typeArg, { bubbles, cancelable, composed })

    this.detail = options.detail // this would correspond to `NativeEvent` in SyntheticEvent
  }
}
global.CustomEvent = window?.CustomEvent ?? CustomEventPolyfill

/**
 * Manages a WebSocket connection to the VatomInc websocket-gateway.
 */
export default class VatomIncWebSocketManager extends EventTarget {
  /**
   * The WebSocket server URL.
   */
  static url = 'wss://ws.api.vatominc.com'

  /**
   * A shared instance.
   */
  static shared = new VatomIncWebSocketManager()

  private _socket?: WebSocket
  private _accessToken?: string
  private _topicSubscriptions = new Set<string>()
  private _reconnectRetries = 0

  connect() {
    if (this.isConnected) {
      return
    }

    console.debug('VatomIncWebSocketManager connecting retriesCount=%d', this._reconnectRetries)

    this._socket = new WebSocket(VatomIncWebSocketManager.url)
    this._socket.onopen = this._handleOpen.bind(this)
    this._socket.onclose = this._handleClose.bind(this)
    this._socket.onmessage = this._handleMessage.bind(this)

    this._socket.onerror = function handleError(event) {
      console.error('VatomIncWebSocketManager error', event)
    }
  }

  private _handleOpen() {
    console.debug('VatomIncWebSocketManager connection opened')

    this._reconnectRetries = 0

    if (this._accessToken) {
      this.authenticate(this._accessToken)
    }

    for (const topic of Array.from(this._topicSubscriptions)) {
      this.subscribe(topic)
    }
  }

  private _handleClose() {
    console.debug('VatomIncWebSocketManager connection closed', this._reconnectRetries)

    this._reconnectRetries++

    if (this._reconnectRetries < 5) {
      const delay = 1_000 * (this._reconnectRetries * 10)
      setTimeout(this.connect.bind(this), delay)
    }
  }

  private _handleMessage(event: unknown) {
    console.log('VatomIncWebSocketManager received message', event)
    // @ts-ignore
    const message = JSON.parse(event.data)

    if (message.type === 'event' && message.eventType === 'connected') {
      console.debug(
        'VatomIncWebSocketManager connected serverHostname=%s clientId=%s',
        message.eventData.serverHostname,
        message.eventData.clientId
      )
    }

    if (message.type === 'event') {
      const newEvent = new CustomEvent('receivedEvent', {
        detail: { eventType: message.eventType, eventData: message.eventData }
      })
      this.dispatchEvent(newEvent)
    }
  }

  private _generateRequestId() {
    return Math.floor(Math.random() * 1_000)
  }

  authenticate(accessToken: string) {
    this._accessToken = accessToken

    this._sendJson({
      type: 'request',
      requestId: this._generateRequestId(),
      requestType: 'authenticate',
      requestData: { accessToken }
    })
  }

  subscribe(topic: string) {
    this._topicSubscriptions.add(topic)

    this._sendJson({
      type: 'request',
      requestId: this._generateRequestId(),
      requestType: 'subscribe',
      requestData: { topic }
    })
  }

  unsubscribe(topic: string) {
    this._topicSubscriptions.delete(topic)

    this._sendJson({
      type: 'request',
      requestId: this._generateRequestId(),
      requestType: 'unsubscribe',
      requestData: { topic }
    })
  }

  get isConnected() {
    return this._socket && this._socket.readyState === WebSocket.OPEN
  }

  private _sendJson(message: unknown) {
    if (!this.isConnected) {
      return
    }

    console.debug('VatomIncWebSocketManager sending json', message)
    this._socket!.send(JSON.stringify(message))
  }
}
