import { type IEvent } from '@vatom/models'

import type { MatrixData, PresenceEvent, RoomInfo, Rooms } from '../matrix'

export function presenceByUserIds(matrixData: MatrixData, userIds: string[]) {
  if (!matrixData || userIds.length === 0) {
    return null
  }

  if (matrixData.presence.events) {
    const usersPresence: Record<string, PresenceEvent['content']> = {}
    matrixData.presence.events.forEach(event => {
      if (userIds.includes(event.sender)) {
        usersPresence[event.sender] = event.content
      }
    })
    return usersPresence
  }
  return null
}

function directMessages(roomsData?: Rooms, accumulator: Record<string, IEvent> = {}) {
  if (!roomsData) {
    return accumulator
  }
  try {
    const objKeys = Object.keys(roomsData)
    for (let i = 0; i < objKeys.length; i++) {
      const roomId = objKeys[i] as string
      if (accumulator[roomId]) {
        continue
      }
      const value = roomsData[roomId]
      const roomTimeline = value?.timeline?.events ?? []
      const roomState = value?.state?.events ?? []
      const roomInviteState = value?.invite_state?.events ?? []

      const roomStateSize = roomState.length
      const roomInviteStateSize = roomInviteState.length
      const roomTimelineSize = roomTimeline.length

      // Room State
      if (roomStateSize > 0) {
        for (let i = 0; i < roomStateSize; i++) {
          if (roomState[i].type === 'm.room.member' && roomState[i].content.is_direct) {
            accumulator[roomId] = roomState[i]
          }
        }
      }
      // Room Invite State
      if (roomInviteStateSize > 0) {
        for (let i = 0; i < roomInviteStateSize; i++) {
          if (roomInviteState[i].type === 'm.room.member' && roomInviteState[i].content.is_direct) {
            accumulator[roomId] = roomInviteState[i]
          }
        }
      }
      // Room Timeline
      if (roomTimelineSize > 0) {
        if (!accumulator[roomId]) {
          for (let i = 0; i < roomTimelineSize; i++) {
            if (roomTimeline[i].type === 'm.room.member' && roomTimeline[i].content.is_direct) {
              accumulator[roomId] = roomTimeline[i]
            }
          }
        }
      }
    }
  } catch (error) {
    console.warn('directMessages error:', error)
  }
  return accumulator
}

type RoomKeys = keyof MatrixData['rooms']

export function directMessageEventsSelector(matrixData: MatrixData) {
  let directMessageEvents: Record<string, IEvent> = {}
  if (matrixData.rooms) {
    const keysToCheck = ['join', 'invite']

    directMessageEvents = keysToCheck.reduce((acc, roomKey) => {
      if (matrixData.rooms?.[roomKey as RoomKeys]) {
        return directMessages(matrixData.rooms[roomKey as RoomKeys], acc)
      }
      return acc
    }, {})
  }
  const messages = Object.keys(directMessageEvents).map(roomId => ({
    roomId,
    event: directMessageEvents[roomId]
  }))
  if (messages.length > 0) {
    messages.sort((a, b) => a.event.origin_server_ts - b.event.origin_server_ts)
  }
  return messages
}

export function roomsByIdsSelector(matrixData: MatrixData, roomIds: string[]) {
  if (!matrixData.rooms) {
    return {}
  }
  return roomIds.reduce((acc, roomId) => {
    if (!matrixData.rooms['join'][roomId]) {
      return acc
    }
    return {
      ...acc,
      [roomId]: matrixData.rooms['join'][roomId]
    }
  }, {}) as Record<string, RoomInfo>
}

export function roomDataSelector<T = RoomInfo | undefined>(matrixData: MatrixData, roomId: string) {
  let room
  if (matrixData.rooms) {
    // const keysToCheck = ['join', 'invite']
    const keysToCheck = ['join']

    keysToCheck.forEach(roomKey => {
      if (matrixData.rooms?.[roomKey as RoomKeys]) {
        room = matrixData.rooms[roomKey as RoomKeys]?.[roomId]
      }
    })
  }
  return room as T
}

type RoomId = string
type UserId = string
export function dmUsersOnRoomByIdsSelector(directEvents: Record<UserId, RoomId[]>) {
  const dmRoomByIds: Record<RoomId, UserId[]> = {}
  if (directEvents) {
    // userId: [roomId]
    // TO > roomId: [userId]
    Object.entries(directEvents).forEach(([userId, roomIds]) => {
      roomIds.forEach(roomId => {
        if (!dmRoomByIds[roomId]) {
          dmRoomByIds[roomId] = []
        }
        if (!dmRoomByIds[roomId].includes(userId)) {
          dmRoomByIds[roomId] = dmRoomByIds[roomId].concat(userId)
        }
      })
    })
  }
  return dmRoomByIds
}
