import { extendTheme } from 'native-base'

import { colors } from './colors'
import shadows from './shadows'
import typography from './typography'

export const theme = extendTheme({
  colors,
  shadows,
  ...typography
})

/**
 * @param alpha {number} between 0 and 1
 */
export function convertToHexAlpha(color: string, alpha: number) {
  const hexAlpha = Math.round(alpha * 255)
    .toString(16)
    .toUpperCase()
    .padStart(2, '0')
  return `${color}${hexAlpha}`
}

export default theme
