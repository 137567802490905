import type {
  ActivitySnaphot,
  BlockchainInfoSnapshot,
  CommerceInfoSnapshot,
  crateVesrsionTypes,
  EditionInfoSnapshot,
  ERC721MetadataSnapshot,
  RegionStoreType,
  ResourceSnapshot,
  StudioInfoSnapshot,
  TokenType,
  ViewMode
} from '@vatom/sdk/core'
import {
  AnimationRule,
  CommerceInfo,
  ERC721Metadata,
  FaceStore,
  ListingStatus,
  Position,
  Resource,
  SellChannel,
  StudioInfo,
  Token,
  withRootSDKStore
} from '@vatom/sdk/core'
import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { getParent, getParentOfType, getSnapshot, getType, hasParent, types } from 'mobx-state-tree'

import logger from '../logger'
import type { Change } from '../modules/Vatom/model/BVatom'
import type Vatom from '../modules/Vatom/model/BVatom'
import type { VatomApiType } from '../modules/VatomApiStore'

// import { VatomInventoryRegionStoreType } from '../regions'
import type { ARFilterConfig } from './ArFilterv2'
import { BVatomPlugin } from './BVatomPlugin'

export const isVatom = (token: TokenType): token is BVatomTokenType => token.type === 'vatom'

export const ARGameTypes = {
  SimpleGame: 'SimpleGame',
  GeoGame: 'GeoGame',
  MultiLevelGeoGame: 'MultiLevelGeoGame',
  CollectionGameV1: 'CollectionGameV1'
  // Once we want games that are not comming from a legacy webface we can just use this as a normal enum like object
  // SomeOtherGame: 'other-game',
} as const

export type ARGameType = keyof typeof ARGameTypes

export const ARGameIds = {
  [ARGameTypes.SimpleGame]: 'BvLBiEhb4iXzma5B3I_8K',
  [ARGameTypes.GeoGame]: 'fddWbHNEL8fNaCA5xWxlJ',
  [ARGameTypes.MultiLevelGeoGame]: 'Fdqrg4xdfIra4BjPsUvE6',
  [ARGameTypes.CollectionGameV1]: 'SGUQUPtEYS'
} as const

export type ARGameIds = (typeof ARGameIds)[ARGameType]

const ImagePolicy = types.model('ImagePolicy', {
  count_max: types.maybe(types.number),
  field: types.maybe(types.string),
  value: types.maybe(types.union(types.string, types.number, types.boolean)),
  resource: types.maybe(types.string)
})

const Color = types.model('Color', {
  r: types.number,
  g: types.number,
  b: types.number,
  a: types.number
})

// const FaceConfig = types.model('FaceConfig', {
//   image: types.maybe(types.string),
//   scale: types.maybe(types.string),
//   image_mode: types.maybe(types.string),
//   image_policy: types.maybe(types.array(ImagePolicy)),
//   layerImage: types.maybe(types.string),
//   empty_image: types.maybe(types.string),
//   full_image: types.maybe(types.string),
//   padding_start: types.maybe(types.number),
//   padding_end: types.maybe(types.number),
//   direction: types.maybe(types.string),

//   // For video
//   cover: types.maybe(types.string),
//   video: types.maybe(types.string),

//   // For crate
//   unlock_key: types.maybe(types.string),
//   activate_action: types.maybe(types.string),

//   // For 3d
//   scene: types.maybe(types.string),
//   animation_rules: types.maybe(types.array(AnimationRule)),
//   ar_transform: types.maybe(ARTransform),
//   auto_rotate: types.maybe(types.boolean),

//   bgColor: types.maybe(Color)
// })

// const FacePropertiesConfig = types.custom<string, any>({
//   name: 'FacePropertiesConfig',
//   fromSnapshot(value: string) {
//     logger.info('FacePropertiesConfig.fromSnapshot')
//     return JSON.parse(value)
//   },
//   toSnapshot(value: any) {
//     logger.info('FacePropertiesConfig.toSnapshot', value)
//     return typeof value === 'undefined' ? '{}' : JSON.stringify(value)
//   },
//   isTargetType(value: string | any): boolean {
//     return true
//   },
//   getValidationMessage(value: string): string {
//     logger.info('getValidationMessage')
//     // if (/^-?\d+\.\d+$/.test(value)) return '' // OK
//     // return `'${value}' doesn't look like a valid decimal number`
//     return ''
//   }
// })

const ActionConfig = types.model('ActionConfig', {
  unlock_key: types.maybe(types.string),
  trash_key: types.maybe(types.boolean)
})

const Policy = types.model('Policy', {
  action_handler: types.maybe(types.string),
  reactor: types.maybe(types.string)
})

const ActionPolicy = types.model('ActionPolicy', {
  pre: types.maybe(types.union(types.array(Policy), types.null)),
  post: types.maybe(types.union(types.array(Policy), types.null))
})

const ActionProperties = types.model('ActionProperties', {
  policy: ActionPolicy,
  config: types.maybe(ActionConfig)
})

export type ActionStoreType = Instance<typeof ActionStore>
export type ActionStoreSnapshot = SnapshotOut<typeof ActionStore>
export const ActionStore = types.model('ActionStore', {
  name: types.string,
  properties: ActionProperties
  // meta: {};
  // template: types.string
})

const CreationPolicy = types.model('CreationPolicy', {
  policy_count_max: types.number,
  enforce_policy_count_max: types.number
})

const ChildPolicy = types.model('ChildPolicy', {
  template_variation: types.string
  // creation_policy: types.maybe(CreationPolicy)
})

const VatomResourceValue = types.model('VatomResourceValue', {
  value: types.string
})

const VatomResource = types.model('VatomResource', {
  name: types.string,
  resourceType: types.string,
  value: VatomResourceValue
})

const Numbered = types.model('Numbered', {
  name: types.maybe(types.string),
  number: types.number
})

const Scarcity = types.model('Scarcity', {
  maxObjects: types.number
})

const Lifecycle = types.model('Lifecycle', {
  initialized: types.optional(types.boolean, false),
  supportsRefresh: types.optional(types.boolean, false)
})

const Scores = types.model('Scores', {
  clicks: types.number,
  engagements: types.number,
  influencers: types.number,
  networkSpend: types.number,
  shares: types.number,
  spend: types.number
})

const Share = types.model('Share', {
  scores: Scores,
  shareTargetUri: types.optional(types.string, 'varius.object-definition:varius.io:this'),
  shareTargetUrl: types.maybe(types.string),
  shareUrl: types.maybe(types.string)
})

const Cloneable = types.model('Cloneable', {
  cloningScore: types.optional(types.number, 0),
  numDirectClones: types.optional(types.number, 0)
})

export const MutableResource = types.model('MutableResource', {
  name: types.string,
  url: types.string,
  type: types.maybe(types.string)
})

const Mutable = types.model('Mutable', {
  title: types.maybe(types.string),
  description: types.maybe(types.string),
  category: types.maybe(types.string),
  resources: types.maybe(types.array(MutableResource))
})

const SimpleResource = types.model('SimpleResource', {
  name: types.string,
  value: types.string
})

const Royalties = types.model('Royalties', {
  artist: types.number
})

const Merchants = types.model('Merchants', {
  id: types.string,
  provider: types.string
})

const Redeemable = types.model('Redeemable', {
  count: types.maybe(types.number),
  totalQuantity: types.maybe(types.number),
  merchants: types.maybe(types.maybeNull(types.array(Merchants)))
})

const PrizeSet = types.model('PrizeSet', {
  prizeDescription: types.maybe(types.string),
  prizeIcon: types.maybe(types.string),
  prizeName: types.maybe(types.string)
})

const GameOfChance = types.model('GameOfChance', {
  lastSentPrizeAt: types.maybe(types.string),
  maxPlaysPerPeriod: types.maybe(types.number),
  playPeriod: types.maybe(types.string),
  playPeriodTimezone: types.maybe(types.string),
  prizeDescription: types.maybe(types.string),
  prizeIcon: types.maybe(types.string),
  prizeName: types.maybe(types.string),
  prizeToken: types.maybe(types.string),
  singleUse: types.maybe(types.boolean),
  prizeExchange: types.maybe(types.boolean),
  playCount: types.maybe(types.number),
  prizeSet: types.maybe(types.array(PrizeSet))
})

const FormV1Data = types.model('FormV1Data', {
  Name: types.maybe(types.string)
})

const FormV1 = types.model('FormV1', {
  data: types.maybe(FormV1Data),
  dataAsString: types.maybe(types.string),
  globalResponseLimit: types.maybe(types.number),
  responseCount: types.maybe(types.number),
  submitted: types.maybe(types.boolean),
  submitted_at: types.maybe(types.string)
})

// type PrivateDataType = Instance<typeof PrivateData>
type PrivateDataSnapshot = SnapshotOut<typeof PrivateData>

const QuizV2 = types.model('QuizV2', {
  answerOptions: types.optional(types.array(types.string), []),
  clue: types.maybe(types.string),
  lastPlayed: types.maybe(types.string),
  question: types.maybe(types.string),
  status: types.maybe(types.string)
})

type QuizV2Snapshot = SnapshotOut<typeof QuizV2>

type PointsV2 = {
  actionOnMaxPoints: string
  channel: string | keyof PointsV2
  maxPoints: number
  targetObjectDefinitionId: string
  total: number
} & {
  // any channel
  [key: string]: string
}

type CouponV1 = {
  availableToGuestUser: boolean
  code: string
  couponUrl: string
  enabled: boolean
  expiry: string | null
  id: string
  pin: string | null
  rule: string
  value: number | null
}

export type SimpleGameV1 = {
  maxItems: number
  maxPlaysPerPeriod: number
  playPeriod: string
  playPeriodTimezone: string
  singleUse: boolean
  waiting: boolean
}

type AnalyticsData = {
  objectDefinitionId?: string
}

export const PrivateData = types.model('PrivateData', {
  behaviors: types.maybe(types.array(types.string)),
  'studio-info-v1': types.maybe(StudioInfo),
  'commerce-v1': types.maybe(CommerceInfo),
  'numbered-v1': types.maybe(Numbered),
  'scarcity-v1': types.maybe(Scarcity),
  'lifecycle-v1': types.maybe(Lifecycle),
  'share-v1': types.maybe(Share),
  'cloneable-v1': types.maybe(Cloneable),
  'mutable-v1': types.maybe(Mutable),
  'royalties-v1': types.maybe(Royalties),
  'redeemable-v1': types.maybe(Redeemable),
  'game-of-chance-v1': types.maybe(GameOfChance),
  'user-points-v2': types.maybe(types.frozen<PointsV2>()),
  'simple-ar-game-v1': types.maybe(types.frozen<SimpleGameV1>()),
  ar_scale: types.maybe(types.number),
  ar_anchor_id: types.maybe(types.string),
  'meta-v1': types.maybe(types.frozen()),
  silent_redeem: types.maybe(types.boolean),
  'ar-filter-v2': types.maybe(types.frozen<ARFilterConfig>()),
  // TODO?
  // state?: any

  // deprecated
  image_policy: types.maybe(types.array(ImagePolicy)),
  padding_start: types.maybe(types.number),
  padding_end: types.maybe(types.number),
  direction: types.maybe(types.string),
  unlock_key: types.maybe(types.string),

  resources: types.maybe(types.array(SimpleResource)),
  'form-v1': types.maybe(FormV1),
  'quiz-v2': types.maybe(types.frozen<QuizV2Snapshot>()),
  'coupon-v1': types.maybe(types.frozen<CouponV1>()),

  analytics_data: types.maybe(types.frozen<AnalyticsData>()),
  'honda-reward-v1': types.maybe(
    types.model({
      redeemed: types.maybe(types.boolean),
      redeemedAt: types.maybe(types.string)
    })
  )
})

type VatomEthSnapshot = SnapshotOut<typeof VatomEth>
const VatomEth = types.model('VatomEth', {
  network: types.maybe(types.string),
  contract: types.maybe(types.string),
  emitted: types.maybe(types.boolean)
})

type VatomChainSnapshot = SnapshotOut<typeof VatomChain>
const VatomChain = types.model('VatomChain', {
  network: types.maybe(types.string),
  contract: types.maybe(types.string),
  minted: types.maybe(types.boolean),
  tx_id: types.maybe(types.string),
  token_id: types.maybe(types.string)
})

export type VatomPropertiesType = Instance<typeof VatomProperties>
type VatomPropertiesSnapshot = SnapshotOut<typeof VatomProperties>

export const VatomProperties = types.model('VatomProperties', {
  analytics_data: types.maybe(types.frozen()),
  title: types.string,
  parent_id: types.string,
  owner: types.string,
  author: types.string,
  transferred_by: types.string,
  description: types.string,
  category: types.string,
  publisher_fqdn: types.string,
  root_type: types.string,
  child_policy: types.maybeNull(types.array(ChildPolicy)),
  template: types.string,
  template_variation: types.string,
  resources: types.array(VatomResource),
  cloning_score: types.maybe(types.number),
  padding_start: types.maybe(types.number),

  // deprecated
  icon_stages: types.maybe(types.array(ImagePolicy)),
  image_mode: types.maybe(types.string),
  activate_action: types.maybe(types.string),
  num_direct_clones: types.maybe(types.number),
  dropped: types.boolean,
  geo_pos: types.maybe(Position),
  animation_rules: types.array(AnimationRule),
  is_map_dispenser: types.optional(types.boolean, false)
})

function getPolicies(face: Instance<typeof FaceStore>, self: BVatomTokenType) {
  return (
    face?.properties?.parsedConfig?.image_policy ||
    self?.private?.image_policy ||
    self?.properties?.icon_stages ||
    []
  )
}

function policyMatches(policy: any, children: BVatomTokenType[], self: BVatomTokenType) {
  if (typeof policy.count_max !== 'undefined') {
    return children.length <= policy.count_max
  } else if (policy.field) {
    const keyPath = getKeyPath(policy)
    const keyValue = getKeyValue(keyPath, self)
    return policy.value === keyValue
  }
  return false
}

function getKeyPath(policy: any) {
  return (
    policy.field
      // eslint-disable-next-line no-useless-escape
      .split(/\.(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/)
      .map((k: string) => k.replace(/"/g, ''))
  )
}

function getKeyValue(keyPath: string[], self: BVatomTokenType) {
  let keyValue: any = self.payload
  while (keyPath.length > 0) {
    keyValue = keyValue[keyPath[0]]
    keyPath.splice(0, 1)
    if (!keyValue) {
      break
    }
  }
  return keyValue
}

function getPolicyResource(policy: any, self: BVatomTokenType): string | undefined {
  return self.properties.resources.find(r => r.name === policy.resource)?.value.value
}

export const BVatomToken = Token.named('BVatomToken')
  .props({
    tokenId: types.string,
    properties: VatomProperties,
    private: types.frozen<PrivateDataSnapshot>(),
    eth: types.maybe(VatomEth),
    chain: types.maybe(VatomChain),
    vatomActions: types.optional(types.array(ActionStore), []),
    vatomFaces: types.optional(types.array(FaceStore), []),
    // payload: types.optional(types.frozen(), {}),
    sync: types.maybe(types.number),
    unpublished: types.optional(types.boolean, false)
  })

  .extend(withRootSDKStore)
  .actions(self => ({
    afterCreate() {
      if (self.properties.category && self.rootStore.nftFilter) {
        self.rootStore.nftFilter.addAllowedCategory(self.properties.category)
      }
    }
  }))
  .actions(self => ({
    setPrivate(data: PrivateDataSnapshot) {
      self.private = data
    },
    setProperties(properties: VatomPropertiesType) {
      self.properties = properties
    },
    setEth(eth: VatomEthSnapshot) {
      self.eth = VatomEth.create(eth)
    },
    setChain(chain: VatomChainSnapshot) {
      self.chain = VatomChain.create(chain)
    }
  }))
  .views(self => ({
    get isBusinessFolder() {
      return self.private['studio-info-v1']?.blueprintId === 'business-folder-v1'
    },
    get isCrate() {
      const isFolder = self.vatomFaces.find(n => n.properties.display_url === 'native://folder')
      const isCrateV2 = !!self.private.behaviors?.includes('crate-v2')
      const isCrateV3 = !!self.private.behaviors?.includes('crate-v3')

      return isFolder || isCrateV2 || isCrateV3
    },
    get getCrateVersion(): crateVesrsionTypes | undefined {
      const crateVersion = self.private.behaviors?.find(
        b => b === 'crate-v3' || b === 'crate-v2'
      ) as crateVesrsionTypes | undefined
      return crateVersion
    },
    get region(): RegionStoreType | void {
      let currentNode = self
      while (hasParent(currentNode)) {
        currentNode = getParent(currentNode)
        const type = getType(currentNode)
        if (
          type.name === 'VatomGeoPosRegionStore' ||
          type.name === 'VatomGeoMapRegionStore' ||
          type.name === 'BVatomInventoryRegionStore' ||
          type.name === 'InventoryRegionStore'
        ) {
          return currentNode as unknown as RegionStoreType
        }
      }
      // logger.info('Region not found', self.id, getParent(self))
      console.error('Region not found for vatom: ', self.id)
    },
    get api(): VatomApiType {
      return getParentOfType(self, BVatomPlugin).api
    },
    get editionInfo() {
      const numberedBehavior = self.private['numbered-v1']

      if (!numberedBehavior) {
        return undefined
      }

      const numbered = self.private['lifecycle-v1']?.initialized ? numberedBehavior.number : 0
      const scarcity = self.private['scarcity-v1']?.maxObjects || Infinity

      return {
        numbered,
        scarcity
      }
    },
    get royalties() {
      return self.private['royalties-v1']?.artist ?? 0
    },
    get hasEngaged() {
      // If the vatom has an engaged view that is NOT just an image
      // OR the vatom has a legacy video that is incorrectly classified as an icon
      // OR the vatom has a legacy 3d object that is incorrectly classified as an icon

      const hasEngaged =
        self.vatomFaces.find(
          f =>
            f.properties.constraints.view_mode === 'engaged' &&
            f.properties.display_url !== 'native://image'
        ) !== undefined
      const hasLegacyVideoIcon =
        self.vatomFaces.find(
          f =>
            f.properties.constraints.view_mode === 'icon' &&
            f.properties.display_url === 'native://video'
        ) !== undefined
      const hasLegacy3DIcon =
        self.vatomFaces.find(
          f =>
            f.properties.constraints.view_mode === 'icon' &&
            f.properties.display_url === 'native://generic-3d'
        ) !== undefined
      return hasEngaged || hasLegacyVideoIcon || hasLegacy3DIcon
    },
    get hasCardView() {
      // If there is NO engaged view, then there can be no card view
      // In the original wallet we displayed the card in a 'fullscreen' mode which is now deprecated
      // Instead we are returning the card in the engaged mode

      if (!this.hasEngaged) return false

      return (
        self.vatomFaces.find(face => {
          return face.properties.constraints.view_mode === 'card'
        }) || false
      )
    },
    get payload() {
      // Construct a vatom payload for backward compatibility with ImagePolicy and Bridge
      const priv = {
        id: self.id,
        private: self.private,
        unpublished: self.unpublished,
        when_created: self.created.toISOString(),
        when_modified: self.modified?.toISOString(),
        'vAtom::vAtomType': getSnapshot(self.properties)
      }

      return priv
    }
  }))
  .volatile(self => ({
    createTransferPayload(user: any) {
      // Check if user is a VatomUser
      const payload: any = {}
      if (typeof user === 'string') {
        // Check if string is email or phone number
        if (/^0x[a-fA-F0-9]{40}$/.test(user)) {
          payload['new.owner.eth_address'] = user
        } else if (user.indexOf('@') !== -1) {
          payload['new.owner.email'] = user
        } else if (user.indexOf('+') === 0) {
          payload['new.owner.phone_number'] = user
        } else {
          payload['new.owner.id'] = user
        }
      } else {
        // This must be a VatomUser, fetch the identifying property
        if (user.userID) {
          payload['new.owner.id'] = user.userID
        } else if (user.phoneNumber) {
          payload['new.owner.phone_number'] = user.phoneNumber
        } else if (user.email) {
          payload['new.owner.email'] = user.email
        } else {
          return Promise.reject({
            code: 'INVALID_PARAMETER',
            message: `The user object supplied didn't have any identifying fields. It must have either a userID, an email, or a phoneNumber.`
          })
        }
      }

      // Send request
      return payload
    }
  }))
  .views(self => ({
    getFace(view: ViewMode) {
      // Check to see if the vatom has a card face
      const cardFace =
        self.vatomFaces.find(
          v =>
            v.properties.constraints.view_mode === 'card' &&
            v.properties.constraints.platform === 'web'
        ) ||
        self.vatomFaces.find(
          v =>
            v.properties.constraints.view_mode === 'card' &&
            v.properties.constraints.platform === 'generic'
        )

      const engagedFace =
        self.vatomFaces.find(
          v =>
            v.properties.constraints.view_mode === 'engaged' &&
            v.properties.constraints.platform === 'web'
        ) ||
        self.vatomFaces.find(
          v =>
            v.properties.constraints.view_mode === 'engaged' &&
            v.properties.constraints.platform === 'generic'
        )
      const iconFace =
        self.vatomFaces.find(
          v =>
            v.properties.constraints.view_mode === 'icon' &&
            v.properties.constraints.platform === 'web'
        ) ||
        self.vatomFaces.find(
          v =>
            v.properties.constraints.view_mode === 'icon' &&
            v.properties.constraints.platform === 'generic'
        )

      const fullscreenFace =
        self.vatomFaces.find(
          v =>
            v.properties.constraints.view_mode === 'fullscreen' &&
            v.properties.constraints.platform === 'web'
        ) ||
        self.vatomFaces.find(
          v =>
            v.properties.constraints.view_mode === 'fullscreen' &&
            v.properties.constraints.platform === 'generic'
        )

      // Check to see if the vatom has legacy video or 3d icons that should be returned in the engaged view
      const legacyVideoFace = self.vatomFaces.find(
        f =>
          f.properties.constraints.view_mode === 'icon' &&
          f.properties.display_url === 'native://video'
      )
      const legacy3DIconFace = self.vatomFaces.find(
        f =>
          f.properties.constraints.view_mode === 'icon' &&
          f.properties.display_url === 'native://generic-3d'
      )

      // const gameType = Object.keys(ARGames).find(
      //   k =>
      //     ARGames[k] === self.vatomFaces.find(f => f.properties.display_url)?.properties.display_url
      // )

      switch (view) {
        // This display mode is used when a user opens the vatom. It falls back to icon if no engaged face is found.
        case 'engaged':
          // eslint-disable-next-line no-case-declarations
          return engagedFace || legacyVideoFace || legacy3DIconFace || cardFace || iconFace
        case 'icon':
          return iconFace
        case 'fullscreen':
          return fullscreenFace
        case 'card':
          // Only return a Card Face if an EngagedFace exists
          if (engagedFace || legacyVideoFace || legacy3DIconFace) return cardFace
          else return null
        default:
          throw new Error(`Unknown View: ${view}`)
      }
    },
    get isARGame() {
      const isGame = self.vatomFaces.some(f => {
        return Object.values(ARGameIds).some(id => f.properties.display_url.includes(id))
      })
      return isGame
    },
    get userPoints() {
      return self.private['user-points-v2']
    },
    get gameType() {
      const face = self.vatomFaces.find(f => {
        return Object.values(ARGameIds).find(id => f.properties.display_url.includes(id))
      })

      const entry = Object.entries(ARGameIds).find(([_, id]) =>
        face?.properties.display_url.includes(id)
      )
      if (!entry) {
        return null
      }
      const [gameType] = entry
      return ARGameTypes[gameType as keyof typeof ARGameTypes]
    },
    get gameConfig() {
      const face = self.vatomFaces.find(f => {
        return Object.values(ARGameIds).find(id => f.properties.display_url.includes(id))
      })
      const config = face?.properties.config
      return config ? JSON.parse(config) : null
    },

    getFaceId(view: ViewMode) {
      const face = this.getFace(view)

      if (!face) return undefined
      if (face.properties.display_url.startsWith('http')) return 'WebFace' as const

      switch (face.properties.display_url) {
        case 'native://image':
          return 'ImageFace' as const
        case 'native://generic-3d':
          return 'ThreeDFace' as const
        case 'native://video':
          return 'VideoFace' as const
        case 'native://image-policy':
          return 'ImagePolicyFace' as const
        default:
          return 'ImageFace' as const
        // throw Error(`Face display url not supported: ${face.properties.display_url}`)
      }
    },
    unlockKey(view: ViewMode): string | undefined {
      const activateAction = self.vatomActions.find(a => a.name.endsWith('Activate'))?.properties
        ?.config?.unlock_key
      const face = this.getFace(view)
      const configFace = JSON.parse(face?.properties.config || '{}')
      const unlock_key_face = configFace?.unlock_key
      const privateUnlock_key = self.private.unlock_key
      return activateAction || unlock_key_face || privateUnlock_key
    }
  }))
  .views(self => ({
    get actions(): string[] {
      const actions = []
      for (const vatomAction of self.vatomActions) {
        let name = vatomAction.name.split('::').slice(-1)[0]
        // Normalize names
        if (name.includes('list-for-sale-v1')) name = 'List'
        if (name.includes('remove-from-sale-v1')) name = 'Delist'
        if (name.includes('share-link-v1')) name = 'ShareLink'
        if (name.includes('varius.action:varius.io:initialize-v1')) name = 'Initialize'
        if (name.includes('initialize-v1')) name = 'Initialize'
        if (name.includes('Clone')) name = 'Share'

        // Filter out invalid actions
        if (name.includes('Drop') && self.properties.dropped) continue
        if (name.includes('Pickup') && !self.properties.dropped) continue
        if (name.includes('List') && this.isForSale()) continue
        if (name.includes('Transfer') && self.properties.dropped) continue
        if (name.includes('Mint') && self.unpublished) continue
        if (name.includes('Delist') && !(this.isForSale() || this.isPendingList())) continue

        actions.push(name)
      }
      return actions
    },
    // Return an ERC compliant presentation
    get metadata(): ERC721MetadataSnapshot {
      const activatedImage = self.resources.find((r: any) => r?.name === 'ActivatedImage')

      const resource = self.resources.find((r: any) => r?.name !== 'ActivatedImage')

      const attributes: [{ trait_type: string; value: string | number }] = [
        {
          trait_type: 'category',
          value: self.properties.category || 'none'
        }
      ]

      const editionInfo = self.editionInfo as EditionInfoSnapshot
      /* Edition */
      if (editionInfo && editionInfo.numbered) {
        const editionInfoAsString = `${editionInfo.numbered}/${
          editionInfo.scarcity === Infinity ? '--' : editionInfo.scarcity
        }`
        attributes.push({
          trait_type: 'edition',
          value: editionInfoAsString
        })
      }

      /* Sharing */
      if (this.canShare()) {
        attributes.push({
          trait_type: 'shares',
          value: (self.properties['num_direct_clones'] || '0').toString()
        })
      }

      attributes.push({
        trait_type: 'template',
        value: self.properties.template
      })

      attributes.push({
        trait_type: 'templateVariation',
        value: self.properties.template_variation
      })

      attributes.push({
        trait_type: 'actions',
        value: self.vatomActions.map(a => a.name.split('::Action::').pop()).join(', ') || '--'
      })

      attributes.push({
        trait_type: 'faces',
        value:
          self.vatomFaces
            .map(a => `${a.properties.constraints.view_mode}: ${a.properties.display_url}`)
            .join(', ') || '--'
      })

      if (self.studioInfo) {
        Object.keys(self.studioInfo).map(title =>
          attributes.push({
            trait_type: title.toLowerCase(),
            value: (self.studioInfo as any)[title]
          })
        )
      }

      const mutable = self.private['mutable-v1']

      return ERC721Metadata.create({
        name: mutable?.title || self.properties.title,
        description: mutable?.description || self.properties.description,
        image: self.api.vatomApi.encodeAssetProvider(activatedImage?.url || ''),
        animation_url: resource ? self.api.vatomApi.encodeAssetProvider(resource?.url || '') : '',
        attributes
      })
    },
    get blockchainInfo(): BlockchainInfoSnapshot | undefined {
      if (self.eth) {
        const { emitted, network, contract } = self.eth
        return {
          tokenId: self.tokenId,
          network,
          networkName: self.getNetworkName(network),
          networkIcon: self.getNetworkIcon(network),
          contractAddress: self.eth.contract,
          owner: 'unknown', // Need to perform a reverse lookup?
          tokenLink:
            emitted && network && contract ? self.getTokenLink(network, contract) : undefined
        }
      } else if (self.chain) {
        const { minted, network, contract, tx_id, token_id } = self.chain
        return {
          tokenId: token_id || self.tokenId,
          network,
          networkName: self.getNetworkName(network),
          networkIcon: self.getNetworkIcon(network),
          contractAddress: contract,
          owner: 'unknown', // Need to perform a reverse lookup?
          tokenLink:
            minted && network && contract ? self.getTokenLink(network, contract, tx_id) : undefined
        }
      }
      return undefined
    },

    get isMinted() {
      return !!(self.eth?.emitted || self.chain?.minted)
    },
    get commerceInfo(): CommerceInfoSnapshot | undefined {
      const commerceState = self?.private?.['commerce-v1']
      if (!commerceState) return undefined
      return {
        ...commerceState,
        status:
          commerceState.channel === SellChannel.None ? ListingStatus.Unlisted : commerceState.status
      }
    },
    get provenance(): ActivitySnaphot[] {
      const soldDate = this.commerceInfo?.saleDate ? new Date(this.commerceInfo?.saleDate) : null
      return [
        {
          date: soldDate ? soldDate.toLocaleString() : '',
          name: 'Sold',
          price: `$${this.commerceInfo?.price}`,
          initiatorId: self.author,
          recipientId: self.owner
        }
      ]
    },
    get position() {
      return self.properties.dropped ? self.properties.geo_pos : undefined
    },
    get studioInfo(): StudioInfoSnapshot | undefined {
      return self.private?.['studio-info-v1']
    },
    get shareInfo(): string | undefined {
      return self.private?.['share-v1']?.shareUrl
    },
    get resources(): ResourceSnapshot[] {
      try {
        const mutable = self.private['mutable-v1']

        return self.properties.resources.map(r => {
          const mutableResource = mutable?.resources?.find(mr => mr.name === r.name)
          const resourceType = mutableResource?.type || r.resourceType
          const url = mutableResource?.url || r.value.value

          const resource: any = {
            name: r.name,
            url: self.api.vatomApi.encodeAssetProvider(url),
            type: resourceType.split('::').splice(1).join('/').toLowerCase()
          }

          if (r.resourceType.includes('Scene')) {
            // Hack for Nathan Hochman
            const autoRotate = this.studioInfo?.businessId !== 'aUp5ILiw5I'

            // Find the 3D face
            const face = self.vatomFaces.find(
              f => f.properties.display_url === 'native://generic-3d'
            )
            if (face) {
              const animationRules =
                face.properties.parsedConfig?.animation_rules ||
                getSnapshot(self.properties.animation_rules) ||
                []
              // resource.animation_rules = animationRules.map(AnimationRule.create)

              resource.animation_rules = animationRules

              if (face.properties.parsedConfig?.ar_transform) {
                resource.ar_transform = face.properties.parsedConfig?.ar_transform
              }

              resource.auto_rotate =
                face.properties.parsedConfig?.auto_rotate === undefined
                  ? autoRotate
                  : face.properties.parsedConfig?.auto_rotate
            }
          }
          try {
            return Resource.create(resource)
          } catch (error) {
            console.error('Error creating resource', error, resource)
            return Resource.create({ name: r.name, url: '', type: 'unknown' })
          }
        })
      } catch (error) {
        console.error('Error getting resources', error)
        return []
      }
    },
    get threeDInfo(): {
      url?: string
      autoRotate?: boolean
    } | null {
      const face = self.vatomFaces.find(f => f.properties.display_url === 'native://generic-3d')
      if (!face) {
        return null
      }

      const autoRotate = this.studioInfo?.businessId !== 'aUp5ILiw5I'

      // const animationRules =
      //   face.properties.parsedConfig?.animation_rules ||
      //   getSnapshot(self.properties.animation_rules) ||
      //   []

      return {
        url: self.metadata.animation_url,
        autoRotate: face.properties.parsedConfig?.auto_rotate === undefined ? autoRotate : false
        // animationRules
      }
    },
    get supportedAddresses() {
      return ['identities.type:email', 'identities.type:eth', 'identities.type: phoneNumber']
    },
    canPerformAction(action: string): boolean {
      return !!this.actions.find(a => a === action)
    },
    canShare() {
      return this.canPerformAction('Clone') || this.canPerformAction('share-link-v1')
    },
    isForSale() {
      const commerceState = self.commerceInfo
      return (
        commerceState?.channel === SellChannel.Direct ||
        commerceState?.channel === SellChannel.Marketplace
      )
    },
    isPendingList() {
      const commerceState = self.commerceInfo
      return (
        commerceState?.channel &&
        commerceState?.channel !== SellChannel.None &&
        commerceState.status === 'pending'
      )
      // return !!commerceState?.productId;
    },
    updateAction(change: Change) {
      const action = self.vatomActions.find(a => a.name.includes(change.template))
      if (action) {
        if (change.operation === 'delete') {
          self.vatomActions.remove(action)
        } else {
          self.vatomActions.replace(
            self.vatomActions.map(a => (a.name.includes(change.template) ? action : a))
          )
        }
      }
    },
    updateFace(change: Change) {
      const face = self.vatomFaces.find(f => f.template === change.template)
      if (face) {
        if (change.operation === 'delete') {
          self.vatomFaces.remove(face)
        } else {
          self.vatomFaces.replace(
            self.vatomFaces.map(f => (f.template === change.template ? face : f))
          )
        }
      }
    }
  }))
  .views(self => ({
    getChildren(): TokenType[] {
      // const children = await self.api.vatomApi.getVatomChildren(self.id)
      // const region = self.region as VatomInventoryRegionStoreType
      // return children.map(c => region.get(c.id)).filter((c): c is TokenType => !!c)
      const inventoryRegion = self.rootStore.dataPool.regions.find(r => r.id === 'inventory')

      if (!inventoryRegion) {
        return []
      }

      const tokens = inventoryRegion?.tokens
      const children = tokens.filter(
        c => c.parentId === self.id || (isVatom(c) && c.properties.parent_id === self.id)
      )
      return children
    },
    getResource(name: string) {
      if (!name) return

      const resource = self.resources.find(r => r.name === name)
      return resource
    }
  }))
  .views(self => ({
    get displayImage(): string {
      if (self.getFaceId('icon') === 'ImagePolicyFace') {
        const children = self.getChildren() as BVatomTokenType[]
        const face = self.getFace('icon') as Instance<typeof FaceStore>
        const policies = getPolicies(face, self as BVatomTokenType)

        for (const policy of policies) {
          if (policyMatches(policy, children, self as BVatomTokenType)) {
            const res = getPolicyResource(policy, self as BVatomTokenType)

            if (res) return res
          }
        }

        logger.warn('Image policy face: No policy matched, resorting to the ActivatedImage.')
        const resource = self.properties.resources.find(r => r.name === 'ActivatedImage')
        return resource?.value.value ?? self.metadata.image
      }
      return self.metadata.image
    }
  }))
  .actions(self => ({
    async performAction(action: string, payload?: any): Promise<any> {
      logger.info('Performing Action', action, payload, self.region?.id)

      // Create pre-emptive action in DataPool for known actions
      const undos: any[] = []
      switch (action) {
        case 'Transfer':
          payload = self.createTransferPayload(payload)
          undos.push(self.region?.preemptiveChange(self.id, '/owner', '.'))
          undos.push(self.region?.preemptiveChange(self.id, '/properties/owner', '.'))
          break
        case 'Clone':
          payload = self.createTransferPayload(payload)
          // We don't need this because we are not transfering, we're just cloning
          // undos.push(self.region?.preemptiveChange(self.id, '/properties/owner', '.'))
          break

        case 'Drop':
          undos.push(self.region?.preemptiveChange(self.id, '/properties/geo_pos', payload))
          undos.push(self.region?.preemptiveChange(self.id, '/properties/dropped', true))
          break

        case 'Pickup':
          undos.push(self.region?.preemptiveChange(self.id, '/properties/dropped', false))
          break

        case 'Redeem':
          undos.push(self.region?.preemptiveChange(self.id, '/owner', '.'))
          undos.push(self.region?.preemptiveChange(self.id, '/properties/owner', '.'))

          break

        case 'Delete':
          undos.push(self.region?.preemptiveChange(self.id, '/owner', '.'))
          undos.push(self.region?.preemptiveChange(self.id, '/properties/owner', '.'))

          return self.api.vatomApi.trashVatom(self.id).catch((err: Error) => {
            undos.map(u => u())
            throw err
          })

        case 'Combine':
          return this.combineWith(payload)

        case 'Split':
          return this.split()

        default:
          break
      }

      if (action === 'Initialize') {
        const hasLegacyLifecycle = self.vatomActions.find(a =>
          a.name.includes('varius.action:varius.io:initialize-v1')
        )
        action = hasLegacyLifecycle ? 'varius.action:varius.io:initialize-v1' : 'initialize-v1'
      }

      const fullPayload = Object.assign(
        {
          'this.id': self.id,
          userRef: {
            id: self.rootStore.dataPool.user.userInfo?.sub,
            provider: 'vatominc'
          }
        },
        payload
      )

      // Perform the action
      return self.api.vatomApi
        .performAction(action, fullPayload)
        .then(res => {
          // if (res) {
          // await self.region.load()
          // }
          return res
        })
        .catch((err: any) => {
          console.log('Error performing action: UNDOING...', err, '')
          // An error occurred, undo preemptive actions
          undos.map(u => u())

          // Workaround: If error was an attempt to pick up a vatom but the vatom is already picked up, it's possible
          // that the GeoPos region missed an update. Notify all GeoPos regions that this vatom is no longer available.
          if (err.code == 1645)
            self.rootStore
              .dataPool!.regions.find(r => r.id === 'geopos')
              ?.regions.forEach(r => r.preemptiveChange(self.id, '/properties/dropped', false))

          // Pass on the error
          throw err
        })
    },
    combineWith(otherVatom: Vatom) {
      this.setParentId(otherVatom.id)
    },
    setParentId(id: string) {
      // Pre-emptively set the parent ID
      const undo = self.region?.preemptiveChange(id, '/properties/parent_id', self.id)
      // Set parent
      return self.api.vatomApi.client
        .request('PATCH', '/v1/vatoms', { ids: [id], parent_id: self.id }, true)
        .catch((err: Error) => {
          // Failed, reset vatom reference
          undo?.()
          throw err
        })
    },
    /** Called to remove all child vatoms from this vatom */
    split() {
      // Get vatom's parent ID
      const newParentID = self.properties.parent_id || '.'
      // Get all children
      const children = self.getChildren()
      return Promise.all(
        children.map(child => {
          // Pre-emptively update parent ID
          const undo = self.region?.preemptiveChange(child.id, '/properties/parent_id', newParentID)
          // Do patch
          return self.api.vatomApi.client
            .request('PATCH', '/v1/vatoms', { ids: [child.id], parent_id: newParentID }, true)
            .catch((err: Error) => {
              // Failed, reset vatom reference
              undo?.()
              throw err
            })
        })
      )
    }
  }))

export type BVatomTokenType = Instance<typeof BVatomToken>
export type BVatomSnapshot = SnapshotOut<typeof BVatomToken>
