import * as Font from 'expo-font'

export const initFonts = async () => {
  // Refer to ./assets/fonts/custom-fonts.md for instructions.
  // ...
  // Welcome back! Just uncomment this and replace/append with your font file names!
  // ⬇
  // await Font.loadAsync({
  //   Montserrat: require("./Montserrat-Regular.ttf"),
  //   "Montserrat-Regular": require("./Montserrat-Regular.ttf"),
  // })
  await Font.loadAsync({
    Inter: require('./Inter-VariableFont_slnt,wght.ttf')

    // AudioWide
    // Audiowide: require('./audiowide/Regular.ttf'),
    // Outfit
    // Outfit: require('./outfit/Outfit-VariableFont_wght.ttf')
  })
}
