// import AsyncStorage from '@react-native-async-storage/async-storage'
import { MMKV } from 'react-native-mmkv'
// @ts-ignore
import parse from 'fast-json-parse'

import logger from '../../logger'

export const storage = new MMKV()
/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export async function loadString(key: string): Promise<string | null> {
  try {
    const str = storage.getString(key)
    return str === undefined ? null : Promise.resolve(str)
  } catch {
    // not sure why this would fail... even reading the RN docs I'm unclear
    return null
  }
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export async function saveString(key: string, value: string): Promise<boolean> {
  try {
    // await AsyncStorage.setItem(key, value)
    storage.set(key, value)
    return true
  } catch {
    // return false
    throw new Error('Unable to save to local storage')
  }
}

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export async function load(key: string): Promise<any | null> {
  try {
    // const almostThere = await AsyncStorage.getItem(key)
    const almostThere = storage.getString(key)
    if (almostThere) {
      // return JSON.parse(almostThere)
      // return Promise.resolve(parse(almostThere))
      const result = parse(almostThere)
      if (result.err) {
        logger.error('unable to parse json', result.err.message)
        throw result.err.message
      } else {
        // logger.info('json parsed successfully', result.value)
        return result.value
      }
    }
    return null
  } catch {
    return null
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export async function save(key: string, value: any): Promise<boolean> {
  try {
    // await AsyncStorage.setItem(key, JSON.stringify(value))
    storage.set(key, JSON.stringify(value))
    return true
  } catch (error) {
    // return false
    logger.debug(`Unable to save to local storage`, key, value)
    if (__DEV__) {
      logger.error(`Unable to save to local storage`, error, key, value)
    }
    throw error
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export async function remove(key: string): Promise<void> {
  try {
    // await AsyncStorage.removeItem(key)
    storage.delete(key)
  } catch {
    return
  }
}

/**
 * Burn it all to the ground.
 */
export async function clear(): Promise<void> {
  try {
    // await AsyncStorage.clear()
    storage.clearAll()
  } catch {
    return
  }
}
