import { useState } from 'react'
import { Alert, Linking, Platform } from 'react-native'
import deviceInfo from 'react-native-device-info'
import { STAGE } from 'react-native-dotenv'
import { useAppConfig, useRefreshOnFocus } from '@vatom/sdk/react'
import semver from 'semver'

import { version } from '../../../package.json'

export function useForceUpdate() {
  const [didAsk, setDidAsk] = useState(false)

  const { refetch } = useAppConfig({
    select: data => data.versions,
    onSuccess: async versions => {
      if (versions && (Platform.OS === 'ios' || Platform.OS === 'android')) {
        const { recommended, minimum, link } = versions[Platform.OS]
        // Lower environments aren't setting the version natively so we use whatever is greater between the package.json version and the device app version
        const currentVersion =
          semver.maxSatisfying([version, deviceInfo.getVersion()], '*') ?? version
        const isGreaterThanRecommended = semver.gte(currentVersion, recommended)
        const isGreaterThanMinimun = semver.gte(currentVersion, minimum)

        if (!isGreaterThanMinimun) {
          handleMinimun(link)
        } else if (!isGreaterThanRecommended && !didAsk) {
          setDidAsk(true)
          handleRecommended(link)
        }
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    enabled: (Platform.OS === 'ios' || Platform.OS === 'android') && !!STAGE && STAGE !== 'local'
  })

  useRefreshOnFocus(refetch)
}

async function openStore(storeUrl: string) {
  if (!storeUrl) {
    console.error('No store url found for this platform')
    return
  }
  const canOpen = await Linking.canOpenURL(storeUrl)
  if (!canOpen) {
    console.error('Can not open store url')
    return
  }
  return await Linking.openURL(storeUrl)
}

function handleMinimun(storeUrl: string) {
  return Alert.alert(
    'Version Update',
    'This version of the App must be upgraded to continue.',
    [
      {
        text: 'Update',
        onPress: () => openStore(storeUrl),
        isPreferred: true
      }
    ],
    { cancelable: false }
  )
}

function handleRecommended(storeUrl: string) {
  return Alert.alert('Version Update', 'There is a newer version of this App available.', [
    {
      text: 'Update',
      onPress: () => openStore(storeUrl),
      isPreferred: true
    },
    {
      text: 'Ignore',
      style: 'cancel'
    }
  ])
}
