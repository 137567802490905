import type { EmitterSubscription } from 'react-native'
import { Platform } from 'react-native'
import * as Linking from 'expo-linking'
import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import logger from '../../logger'
/**
 * A LinkingStore model.
 */

export const LinkingModel = types
  .model('LinkingModel')
  .props({
    linkingUrl: types.maybe(types.string),
    didParseDeferredInstallLink: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setLinkingUrl(url: string | undefined) {
      logger.info('[Linking.setLinkingUrl] incoming url', url)

      if (
        !url?.includes('/claim') &&
        !url?.includes('/connect') &&
        !url?.includes('/callback') &&
        !url?.includes('logout-callback') &&
        !url?.includes('com.vatom://auth') &&
        !url?.includes('com.vatom.viewer://expo-development-client/?') &&
        url !== 'https://wallet.vatominc.com/' &&
        url !== 'https://wow.vatom.com/' &&
        url !== 'https://wallet.localhost:3000/'
      ) {
        self.linkingUrl = url
      }
      logger.info('[Linking.setLinkingUrl] set url', self.linkingUrl)
    },
    setParsedDefferredLink(didSet: boolean) {
      self.didParseDeferredInstallLink = didSet
    },
    clearLinkingUrl() {
      logger.info('[Linking.clearLinkingUrl]')
      self.linkingUrl = undefined
    }
  }))
  .actions(self => {
    const onUrl = (options: { url: string }) => {
      logger.info('[Linking.onUrl] options', options)
      self.setLinkingUrl(options.url)
    }

    let unsubscribeLinking: EmitterSubscription
    const afterCreate = async () => {
      self.setParsedDefferredLink(false)
      if (Platform.OS !== 'web') {
        unsubscribeLinking = Linking.addEventListener('url', onUrl)
      }
      let url = await Linking.getInitialURL()
      if (url) {
        self.setLinkingUrl(url)
      }
      logger.info('[Linking.afterCreate] linkingUrl', self.linkingUrl)
      url = self.linkingUrl ?? ''
      logger.info('[Linking.afterCreate] linkingUrl', url.includes('/connect'))

      if (
        url.includes('/claim') ||
        url.includes('/connect') ||
        url.includes('/callback') ||
        url.includes('logout-callback') ||
        url.includes('com.vatom://auth') ||
        url.includes('com.vatom.viewer://expo-development-client/?') ||
        url === 'https://wallet.vatominc.com/' ||
        url === 'https://wow.vatom.com/' ||
        url === 'https://wallet.localhost:3000/'
      ) {
        logger.info('[Linking.afterCreate] clearLinkingUrl', url)
        self.clearLinkingUrl()
      }
    }

    const beforeDestroy = () => {
      unsubscribeLinking?.remove()
    }
    return { afterCreate, beforeDestroy }
  })
  .views(self => ({
    get joinCode(): string | null {
      // parse code from url
      if (self.linkingUrl) {
        logger.info('[Linking.joinCode] got the linking url >> ', self.linkingUrl)
        try {
          const urlData = self.linkingUrl.split('//')
          logger.info('URL DATA : ', urlData)
          const [a, b] = urlData
          return b
        } catch (error) {
          logger.info('[Linking.joinCode] got the linking url error >> ', error)
        }
      }
      return null
    }
  }))

/**
 * The LinkingStore instance.
 */
export type LinkingStore = Instance<typeof LinkingModel>

/**
 * The data of a LinkingStore.
 */
export type LinkingStoreSnapshot = SnapshotOut<typeof LinkingModel>
