// import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { create } from 'zustand'

export type CrateConfigFace = {
  showConfetti?: boolean
  toastAppearanceTime: number
  video: {
    ref: string
    type: string
  }
  activate_action?: string
  loop?: boolean
}

type VatomOutput<T> = {
  error: null
  output: T
}

export type PreCreatedVatom = {
  icon: string
  name: string
}

export type PreCreatedVatoms = PreCreatedVatom[]

export type CrateResponse = VatomOutput<
  VatomOutput<{
    vatoms: PreCreatedVatom[]
  }>
>[]

type RewardCard = {
  tokens: PreCreatedVatoms
  config: CrateConfigFace
  token: any
}

type RewardCardCrateStore = {
  isRewardsActive: boolean
  crateNotification: RewardCard | undefined
  shouldShowCrateRewards: boolean
  didCrateClose: boolean
  shouldShowConfetti: boolean

  didVideoReproductionEnded: boolean

  storeCrateNotification: (notification: RewardCard) => void

  setisRewardsActive: () => void

  openRewardCard: () => void
  setDidVideoReproductionEnded: (didVideoReproductionEnded: boolean) => void

  setShouldShowConfetti: (shouldShowConfetti: boolean) => void
}

let timeoutId: NodeJS.Timeout | null = null

export const useCrateStore = create<RewardCardCrateStore>((set, get) => ({
  // Stops all Notifications for 15 seconds
  isRewardsActive: false,
  // Store the crate notification
  crateNotification: undefined,

  // Shows the Crate Notification
  shouldShowCrateRewards: false,

  // Crate has been closed
  didCrateClose: false,

  // Video has finished
  didVideoReproductionEnded: false,

  // Show confetti
  shouldShowConfetti: false,

  // Stops all Notifications for 15 seconds
  setisRewardsActive: () => {
    set(state => ({ ...state, isRewardsActive: true }))
    if (timeoutId) clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      set(state => ({ ...state, isRewardsActive: false }))
    }, 20000)
  },

  // Store the crate notification
  storeCrateNotification: (crateNotification: RewardCard) => {
    set(state => ({ ...state, crateNotification: crateNotification }))
  },

  // Shows the Crate Notification
  openRewardCard: () => {
    if (!get().crateNotification) return

    if (get().shouldShowCrateRewards === false) {
      set(state => ({
        ...state,
        shouldShowCrateRewards: true,
        didCrateClose: true,
        shouldShowConfetti: true
      }))
    }

    setTimeout(() => {
      set(state => ({
        ...state,
        shouldShowCrateRewards: false,
        crateNotification: undefined,
        didCrateClose: false,
        shouldShowConfetti: false
      }))
    }, 2000)
  },
  setDidVideoReproductionEnded: (didVideoReproductionEnded: boolean) => {
    set(state => ({ ...state, didVideoReproductionEnded: didVideoReproductionEnded }))
    setTimeout(() => {
      set(state => ({ ...state, didVideoReproductionEnded: false }))
    }, 2000)
  },

  // Show confetti
  setShouldShowConfetti: (shouldShowConfetti: boolean) => {
    set(state => ({ ...state, shouldShowConfetti: shouldShowConfetti }))
  }
}))

// const s = {
//   "main":  {
//     "error": null,
//   },
//   "post": null,
//   "pre":  [
//      {
//       "error": null,
//       "output":  {
//         "error": null,
//         "output":  {
//           "vatoms":  [
//              {
//               "icon": "https://resources.vatominc.com/rUBK9GTeKK/286dc6c0-e928-11ed-985e-6d7b9b780f7c.png?1683059424953",
//               "name": "Lakeside",
//             },
//           ],
//         },
//       },
//     },
//   ],
// }
