const typography = {
  fontConfig: {
    Inter: {
      100: {
        normal: 'Inter'
      },
      200: {
        normal: 'Inter'
      },
      300: {
        normal: 'Inter'
      },
      400: {
        normal: 'Inter'
      },
      500: {
        normal: 'Inter'
      },
      600: {
        normal: 'Inter'
      },
      700: {
        normal: 'Inter'
      },
      800: {
        normal: 'Inter'
      },
      900: {
        normal: 'Inter'
      },
      950: {
        normal: 'Inter'
      }
    }
  },
  letterSpacing: {
    xs: '-0.05em',
    sm: '-0.025em',
    md: '0',
    lg: '0.025em',
    xl: '0.05em',
    '2xl': '0.1em'
  },
  lineHeights: {
    '2xs': '.75rem',
    xs: '1rem',
    sm: '1.25rem',
    md: '1.5rem',
    xl: '1.75rem',
    '2xl': '2rem',
    '3xl': '2.5em',
    '4xl': '3em',
    '5xl': '4em'
  },
  fontWeights: {
    thin: 100,
    hairline: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
    variable: 950
  },
  fonts: {
    heading: 'Inter',
    body: 'Inter',
    mono: 'Inter'
  },
  fontSizes: {
    '2xs': 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    '2xl': 24,
    '3xl': 30,
    '4xl': 36,
    '5xl': 48,
    '6xl': 60,
    '7xl': 72,
    '8xl': 96,
    '9xl': 128
  }
}

export default typography
