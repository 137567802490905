/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import './utils/ignore-warnings'
import './redirect'

import React, { useEffect, useState } from 'react'
import type { AppStateStatus } from 'react-native'
import { AppState, Linking, Platform, Text } from 'react-native'
// import { LogBox, Platform } from 'react-native' //Ignore all log notifications
// import OneSignal from 'react-native-onesignal'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { enableFreeze, enableScreens } from 'react-native-screens'
import { focusManager } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { BVatomPlugin } from '@vatom/BVatom/plugin'
// import { VatomFaces } from '@vatom/BVatom/react'
// import { ErcPlugin } from '@vatom/erc/plugin'
import { Sentry } from '@vatom/sdk/core'
import {
  // CoreFaces,
  // CreateWeb3Modal,
  // FaceProvider,
  prefetchConfig,
  SDKPersistOptions,
  SDKProvider,
  SDKQueryClient,
  VatomRefreshTokenProvider
} from '@vatom/sdk/react'
// import { SolanaPlugin } from '@vatom/solana/plugin'
import { NewVatomPlugin } from '@vatom/vatomNew/plugin'
import { AppRoutes } from '@vatom/wallet-routes'
import { LoaderView, theme } from '@vatom/wombo' // expo
// import WalletConnectProvider from '@walletconnect/react-native-dapp'
import { LinearGradient } from 'expo-linear-gradient'
import * as SplashScreen from 'expo-splash-screen'
import { NativeBaseProvider } from 'native-base'

import { OidcSession } from './components/OidcSession'
// import { TestModalWeb3, W3mButton } from './components/Web3Modal'
import { useAuthLogout } from './hooks/useAuthLogout'
import { AppStack } from './navigators/AppNavigator/AppStack'
import { ErrorBoundary } from './screens/ErrorBoundary'
import { initializeNotificationService } from './services/notificationService'
import { initFonts } from './theme/fonts'
import logger from './logger'
// import { DataPoolProvider } from './_todo/react-sdk'
// import { VatomNetDataPoolAuther } from './_todo/VatomNetDataPoolAuther'
import type { RootStore } from './models'
import { RootStoreProvider, setupRootStore } from './models'
import { AppNavigator, navigationRef } from './navigators'
// This puts screens in a native ViewController or Activity. If you want fully native
// stack navigation, use `createNativeStackNavigator` in place of `createStackNavigator`:
// https://github.com/kmagiera/react-native-screens#using-native-stack-navigator
export const NAVIGATION_PERSISTENCE_KEY = 'NAVIGATION_STATE'

enableScreens(true)
enableFreeze(true)

const config = {
  dependencies: {
    'linear-gradient': LinearGradient
  }
}

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync()

export interface AppProps {}

function onAppStateChange(status: AppStateStatus) {
  if (Platform.OS !== 'web') {
    focusManager.setFocused(status === 'active')
  }
}

const prefetchConfigPromise = prefetchConfig()

declare global {
  interface Window {
    load8thwall: () => void
  }
}

/**
 * This is the root component of our app.
 */
export const App = (props: AppProps) => {
  const { clearAllSessions } = useAuthLogout()
  const [root, setRoot] = useState<{
    rootStore: RootStore | undefined
    initialUrl: string | undefined
  }>({ rootStore: undefined, initialUrl: undefined })

  useEffect(() => {
    const subscription = AppState.addEventListener('change', onAppStateChange)

    return () => subscription.remove()
  }, [])

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    const bootstrapApp = async () => {
      try {
        Sentry.init()
        try {
          await initFonts()
          await initializeNotificationService()
        } catch (error) {
          logger.error('[App.render] useEffect initFonts', error)
        }

        const initialUrl = await Linking.getInitialURL()
        await prefetchConfigPromise

        const rootStore = await setupRootStore()
        setRoot({ rootStore, initialUrl: initialUrl === null ? undefined : initialUrl })
        setTimeout(async () => {
          await SplashScreen.hideAsync()
        }, 250)
        // DEBUG ONLY: Test crashes
        // Sentry.instance.nativeCrash()
      } catch (error) {
        logger.error('[App.render] useEffect:', error)
      }
    }
    bootstrapApp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!root.rootStore) {
    return (
      <NativeBaseProvider config={config} theme={theme}>
        <LoaderView />
      </NativeBaseProvider>
    )
  }

  // otherwise, we're ready to render the app
  return (
    <PersistQueryClientProvider client={SDKQueryClient} persistOptions={SDKPersistOptions}>
      {/* <QueryClientProvider client={SDKQueryClient}> */}
      {Platform.OS === 'web' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
      <SDKProvider plugins={[BVatomPlugin, NewVatomPlugin]}>
        {/* <FaceProvider faces={[]}> */}
        <RootStoreProvider value={root.rootStore}>
          <SafeAreaProvider>
            <NativeBaseProvider config={config} theme={theme}>
              <AppNavigator initialUrl={root.initialUrl}>
                <NativeBaseProvider config={config} theme={theme}>
                  {/* <ToggleStorybook> */}
                  <ErrorBoundary catchErrors={'never'}>
                    <OidcSession />
                    <VatomRefreshTokenProvider
                      onRefreshFailed={async () => {
                        logger.info(
                          '[AppStack] RefreshTokenProvider.onRefreshFailed',
                          navigationRef
                        )
                        // root.rootStore?.linking.clearLinkingUrl()
                        clearAllSessions()
                        navigationRef?.current?.navigate(AppRoutes.connect)
                      }}
                    />
                    {/* <TestModalWeb3 /> */}

                    {/* <CreateWeb3Modal> */}
                    <AppStack />
                    {/* </CreateWeb3Modal> */}
                  </ErrorBoundary>
                  {/* </ToggleStorybook> */}
                </NativeBaseProvider>
              </AppNavigator>
            </NativeBaseProvider>
          </SafeAreaProvider>
        </RootStoreProvider>
        {/* </FaceProvider> */}
      </SDKProvider>
      {/* </QueryClientProvider> */}
    </PersistQueryClientProvider>
  )
}
