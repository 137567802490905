import uniq from 'lodash/uniq'

/**
 * This class contains utility functions for dealing with Octrees.
 */
export default class Octree {
  /** Maximum size of the octree */
  static MaxSize = 1000000

  /** Minimum size of a single octree cell */
  static MinSize = 1

  /** Calculate the octree location string of a coordinate */
  static calculateLocationString(x, y, z) {
    // Starting vars
    let path = ''
    let minX = -Octree.MaxSize
    let minY = -Octree.MaxSize
    let minZ = -Octree.MaxSize
    let maxX = Octree.MaxSize
    let maxY = Octree.MaxSize
    let maxZ = Octree.MaxSize
    let centerX = 0
    let centerY = 0
    let centerZ = 0

    // Loop through cells
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // Check which segment we are in
      if (x < centerX && y < centerY && z < centerZ) {
        // First segment
        path += '0'
        maxX = centerX
        maxY = centerY
        maxZ = centerZ
      } else if (x >= centerX && y < centerY && z < centerZ) {
        // Second segment
        path += '1'
        minX = centerX
        maxY = centerY
        maxZ = centerZ
      } else if (x < centerX && y >= centerY && z < centerZ) {
        // Third segment
        path += '2'
        maxX = centerX
        minY = centerY
        maxZ = centerZ
      } else if (x < centerX && y < centerY && z >= centerZ) {
        // Fourth segment
        path += '3'
        maxX = centerX
        maxY = centerY
        minZ = centerZ
      } else if (x >= centerX && y >= centerY && z < centerZ) {
        // Fifth segment
        path += '4'
        minX = centerX
        minY = centerY
        maxZ = centerZ
      } else if (x < centerX && y >= centerY && z >= centerZ) {
        // Sixth segment
        path += '5'
        maxX = centerX
        minY = centerY
        minZ = centerZ
      } else if (x >= centerX && y < centerY && z >= centerZ) {
        // Seventh segment
        path += '6'
        minX = centerX
        maxY = centerY
        minZ = centerZ
      } else if (x >= centerX && y >= centerY && z >= centerZ) {
        // Eighth segment
        path += '7'
        minX = centerX
        minY = centerY
        minZ = centerZ
      } else {
        throw new Error("Can't be here!")
      }

      // Stop if box is too small
      let size = maxX - minX
      if (size < Octree.MinSize) break

      // Calculate noew center position
      centerX = (maxX + minX) / 2
      centerY = (maxY + minY) / 2
      centerZ = (maxZ + minZ) / 2
    }

    // Done
    return path
  }

  /**
   * Calculate the minimum octree location strings to use when querying a region.
   *
   * @returns {string[]} A list of shortened location strings to search on.
   */
  static calculateRegionQueryStrings(x, y, z, radius) {
    // Calculate path length to account for this radius
    let pathLen = 0
    let size = Octree.MaxSize
    while (size >= radius) {
      pathLen += 1
      size /= 2
    }

    // Get center point for each quadrant
    let points = []
    for (let offsetX = -1; offsetX <= 1; offsetX++) {
      for (let offsetY = -1; offsetY <= 1; offsetY++) {
        for (let offsetZ = -1; offsetZ <= 1; offsetZ++) {
          // Calculate point
          let point = Octree.calculateLocationString(
            x + offsetX * radius,
            y + offsetY * radius,
            z + offsetZ * radius
          )
          point = point.substring(0, pathLen)
          points.push(point)
        }
      }
    }

    // Strip out duplicates
    return uniq(points)
  }
}
