// import OpusEncoder from 'opus-encdec/dist/libopus-encoder.js'
// import OpusDecoder from 'opus-encdec/dist/libopus-decoder.js'
// import { OggOpusEncoder } from 'opus-encdec/src/oggOpusEncoder'
// import { OggOpusDecoder } from 'opus-encdec/src/oggOpusDecoder'
// import { self } from 'react-native-workers'
import 'text-encoding-polyfill'

import P2PConstants from './P2PConstants'

/** Shared UTF8 text encoder/decoders */
const utf8Encoder = new TextEncoder()
const utf8Decoder = new TextDecoder()

/**
 * This class represents the P2P audio worker thread.
 */
export default class P2PAvatarWorker {
  /** @type {MessagePort} The P2PAudioProcessor's message port */
  audioMessagePort = null

  /** Opus instances */
  codecSessions = {}

  /** Our metadata that we send to remote peers */
  sharedMetadata = {}
  sharedMetadataBinary = new Uint8Array(0)

  /** The sample rate of the AudioContext */
  audioContextSampleRate = 44100

  /** Ping details */
  pingNonce = 1
  pingTime = Date.now()

  /** @type {MessagePort} Communication channel with the Worker thread */
  audioWorkletPort = null

  /** Constructor */
  constructor(customSelf) {
    // Attach message listeners
    // eslint-disable-next-line no-restricted-globals
    this.self = customSelf || self
    this.self.addEventListener('message', this.onMessage.bind(this))

    // Fast and slow packet timer
    this.fastTimer = setInterval(this.onFastTimer.bind(this), 200)
    this.slowTimer = setInterval(this.onSlowTimer.bind(this), 1000)

    // Send loaded event
    setTimeout(() => {
      this.self.postMessage({ action: 'worker-loaded' })
    }, 0)
  }

  /** Called by RN when the worker is terminated ... browser doesn't need this */
  terminate() {
    // Cancel timers
    clearInterval(this.fastTimer)
    clearInterval(this.slowTimer)
  }

  /** Called on message from the host */
  onMessage(e) {
    // Check message type
    if (e.data.action == 'worker-audio-init') {
      // Create opus encoder for the current user's audio stream
      this.audioContextSampleRate = e.data.sampleRate || 44100
      // eslint-disable-next-line no-undef
      this.opusEncoder = new OggOpusEncoder(
        {
          encoderApplication: 2048, // <-- VoIP application profile
          //encoderFrameSize: 50,                         // <-- Frame size, in milliseconds
          encoderSampleRate: 48000, // <-- Audio quality basically, 8000,12000,16000,24000,48000
          originalSampleRate: this.audioContextSampleRate, // <-- Input sample rate
          numberOfChannels: 1, // <-- Input channel count, interleaved if more than 1
          rawOpus: true // <-- We want raw Opus packets, not wrapped in an .ogg container
        },
        // eslint-disable-next-line no-undef
        OpusEncoder
      )
    } else if (e.data.action == 'mic-audio-raw') {
      // Process raw input from microphone
      this.onIncomingMicAudio(e.data.buffer)
    } else if (e.data.action == 'metadata-update') {
      // New metadata from the host app
      this.sharedMetadata = e.data.metadata || {}
      this.sharedMetadataBinary = utf8Encoder.encode(JSON.stringify(this.sharedMetadata))
    } else if (e.data.action == 'packet-in') {
      // Received an incoming packet from a remote peer
      this.onIncomingPacket(e.data)
    } else if (e.data.action == 'audio-worklet-port-in') {
      // Received communication channel to the AudioWorklet
      this.audioWorkletPort = e.data.port
      this.audioWorkletPort.onmessage = this.onMessage.bind(this)
      console.debug(`[P2PAvatars] Worker received audio worklet's message port`)
    }
  }

  /** Called when we receive microphone audio from P2PAudioProcessor. The buffer is a Float32 stereo interlaced array. */
  onIncomingMicAudio(buffer) {
    // Stop if no encoder
    if (!this.opusEncoder) return

    // Encode audio
    let floatArray = new Float32Array(buffer)
    this.opusEncoder.encode([floatArray])

    // Check for error
    if (this.opusEncoder.encodedDataLength < 0) {
      // Show error and reset state
      this.opusEncoder.encodedDataLength = 0
      this.opusEncoder.encodedData.length = 0
      return console.warn('[P2PAvatar] Opus encoding error!')
    }
  }

  /** Build a packet to send. @returns {ArrayBuffer} */
  buildPacket(withAudio = true) {
    // Build audio subpacket
    let audioData = null
    if (withAudio && this.opusEncoder?.encodedData) {
      // Create buffer with the right size
      audioData = new Uint8Array(
        this.opusEncoder.encodedDataLength + 4 * this.opusEncoder.encodedData.length
      )
      let audioDataView = new DataView(audioData.buffer)

      // Add buffers
      let offset = 0
      for (let buffer of this.opusEncoder.encodedData) {
        // Set size
        audioDataView.setUint32(offset, buffer.length)
        audioData.set(buffer, offset + 4)
        offset += 4 + buffer.length
      }

      // Reset counters
      this.opusEncoder.encodedDataLength = 0
      this.opusEncoder.encodedData.length = 0
    }

    // Build new fast data packet
    let audioSize = audioData?.length || 0
    let metadataSize = this.sharedMetadataBinary?.length || 0
    let packet = new ArrayBuffer(
      1 + // <-- Packet type flag (uint8)
        8 + // <-- Our date (float64)
        4 + // <-- Metadata size (uint32)
        metadataSize + // <-- Metadata (utf8 string)
        4 + // <-- Audio buffer length
        audioSize // <-- Audio buffer
    )
    let packetUint8 = new Uint8Array(packet)
    let packetDataView = new DataView(packet)

    // Set packet header
    packetDataView.setUint8(0, P2PConstants.Opcodes.BundledUpdatePacket)
    packetDataView.setFloat64(1, Date.now())

    // Set packet metadata
    packetDataView.setUint32(9, metadataSize)
    if (metadataSize > 0) packetUint8.set(this.sharedMetadataBinary, 13)

    // Set audio buffer
    packetDataView.setUint32(13 + metadataSize, audioSize)
    if (audioSize > 0) packetUint8.set(audioData, 13 + metadataSize + 4)

    // Done
    return packet
  }

  /** Called to send out the "fast" packets */
  onFastTimer() {
    // Get full packet
    let packet = this.buildPacket(true)

    // Done, post the compressed packet
    this.self.postMessage({ action: 'broadcast-packet', sendTo: 'full', packet }, [packet])
  }

  /** Called to send out the "slow" packets without audio */
  onSlowTimer() {
    // Get slow packet
    let packet = this.buildPacket(false)

    // Done, post the compressed packet
    this.self.postMessage({ action: 'broadcast-packet', sendTo: 'slow', packet }, [packet])

    // Remove all unused decoders
    let now = Date.now()
    for (let key in this.codecSessions) {
      // Check date
      let decoder = this.codecSessions[key]
      if (now - decoder.lastAccess <= 60000) continue

      // Remove it
      decoder.destroy()
      delete this.codecSessions[key]
    }
  }

  /** Called when a remote peer has sent us a data packet */
  onIncomingPacket(msg) {
    // Check packet type
    let uint8 = new Uint8Array(msg.packet)
    if (uint8[0] != P2PConstants.Opcodes.BundledUpdatePacket) return

    // Extract values
    let dataView = new DataView(msg.packet)
    // let packetType = dataView.getUint8(0)
    let packetDate = dataView.getFloat64(1)
    let metadataSize = dataView.getUint32(9)
    let metadataBinary = uint8.subarray(13, 13 + metadataSize)
    let metadataStr = utf8Decoder.decode(metadataBinary)
    let metadata = {}
    try {
      metadata = JSON.parse(metadataStr)
    } catch (error) {
      metadata = {}
    }

    // Update metadata with packet delay time
    // metadata._delay = Date.now() - packetDate

    // Notify decoded metadata change
    this.self.postMessage({ action: 'metadata-in', connectionID: msg.connectionID, metadata })

    // Decode audio packet if possible
    let audioSize = dataView.getUint32(13 + metadataSize)
    let audioData = uint8.subarray(13 + metadataSize + 4, 13 + metadataSize + 4 + audioSize)
    if (audioData.length > 0) {
      // Get decoder
      this.decodeAudioFor(audioData, msg.connectionID, metadata, packetDate)
    }
  }

  /** On incoming encoded audio packet from a remote user */
  decodeAudioFor(audioData, connectionID, metadata, packetDate) {
    // Get codec or create it
    let decoder = this.codecSessions[connectionID]
    if (!decoder) {
      // Create decoder
      // eslint-disable-next-line no-undef
      decoder = new OggOpusDecoder(
        {
          outputBufferSampleRate: this.audioContextSampleRate, // <-- Output sample rate
          numberOfChannels: 1, // <-- Input channel count, interleaved if more than 1
          rawOpus: true, // <-- We want raw Opus packets, not wrapped in an .ogg container
          decoderSampleRate: 48000 // <-- Audio quality basically, 8000,12000,16000,24000,48000
        },
        // eslint-disable-next-line no-undef
        OpusDecoder
      )

      // Store it
      this.codecSessions[connectionID] = decoder
    }

    // Update access date
    decoder.lastAccess = Date.now()

    // Get each subpacket
    let audioDataView = new DataView(audioData.buffer, audioData.byteOffset, audioData.byteLength)
    let offset = 0
    let rawAudioPackets = []
    while (offset < audioData.length) {
      // Get packet
      let size = audioDataView.getUint32(offset)
      let packet = audioData.subarray(offset + 4, offset + 4 + size)

      // Decode it
      decoder.decodeRaw(packet, bfr => rawAudioPackets.push(bfr.slice()), null)

      // Increase offset
      offset += 4 + size
    }

    // Stop if no output from libopus was received
    if (rawAudioPackets.length == 0) return

    // Create a combined audio buffer with all the processed packets
    let numSamples = rawAudioPackets.reduce((prev, buffer) => prev + buffer.length, 0)
    let float32AudioBuffer = new Float32Array(numSamples)
    offset = 0
    for (let buffer of rawAudioPackets) {
      float32AudioBuffer.set(buffer, offset)
      offset += buffer.length
    }

    // Calculate distance
    let x = metadata.x || 0
    let y = metadata.y || 0
    let z = metadata.z || 0
    let ourX = this.sharedMetadata.x || 0
    let ourY = this.sharedMetadata.y || 0
    let ourZ = this.sharedMetadata.z || 0
    let distance = Math.sqrt((ourX - x) ** 2 + (ourY - y) ** 2 + (ourZ - z) ** 2)

    // Pass to the audio processor
    this.audioWorkletPort?.postMessage(
      {
        action: 'raw-audio-in',
        buffer: float32AudioBuffer.buffer,
        x,
        y,
        z,
        ourX,
        ourY,
        ourZ,
        distance,
        connectionID,
        packetDate
      },
      [float32AudioBuffer.buffer]
    )
  }
}
