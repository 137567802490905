import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  Campaign,
  ObjectDefinition as ODef,
  ObjectDefinitionSearchParams
} from '@vatom/experience-sdk'

// import { BVatomTokenType } from '@vatom/BVatom/plugin'
import { useAccessToken, useUser } from '../../../../hooks/useUser'
import { useSDK } from '../../../SDKProvider'

import type { CampaignOverview } from './types/campaign-overview'
import { getPickUpPointsForObjectDefinitionId } from './selectors'
import type { CampaignPoints, CampaignRules, ObjectDefinition } from './types'

const pointsHost = 'https://points.api.vatominc.com/'

export const vatomQueryKeys = {
  // Designs
  objectDefinition: [{ scope: 'vatom-object-definition' }] as const,
  campaignGroupRules: [{ scope: 'campaign-group-rules' }] as const,
  campaignGroupOverview: [{ scope: 'campaign-group-overview' }] as const,
  campaignPoints: [{ scope: 'campaign-points' }] as const,
  listBusinessCampaignGroups: [{ scope: 'list-business-campaign-groups' }] as const,
  objectDefinitionSearch: [{ scope: 'object-definition-search' }] as const,
  getVatomObjectDefinition: (businessId: string, objectDefinitionId: string) =>
    [{ ...vatomQueryKeys.objectDefinition[0], businessId, objectDefinitionId }] as const,
  getCampaignGroupRules: (businessId: string, campaignId: string) =>
    [{ ...vatomQueryKeys.campaignGroupRules[0], businessId, campaignId }] as const,
  getCampaignGroupOverview: (businessId: string, campaignId: string) =>
    [{ ...vatomQueryKeys.campaignGroupOverview[0], businessId, campaignId }] as const,
  getCampaignPoints: (campaignId?: string, userSub?: string) =>
    [{ ...vatomQueryKeys.campaignPoints[0], campaignId, userSub }] as const,
  getListBusinessCampaignGroups: (businessId: string) =>
    [{ ...vatomQueryKeys.listBusinessCampaignGroups[0], businessId }] as const,
  getObjectDefinitionsSearch: (
    businessId: string,
    campaignId: string,
    searchParams?: ObjectDefinitionSearchParams
  ) =>
    [{ ...vatomQueryKeys.objectDefinitionSearch[0], businessId, campaignId, searchParams }] as const
}

const fetchObjectDefinition = async (
  sdk: ReturnType<typeof useSDK>,
  businessId: string,
  objectDefinitionId: string
) => {
  const vatom = await sdk.service.studio.get<ObjectDefinition>(
    `b/${businessId}/object-definitions/${objectDefinitionId}`
  )
  return vatom.data
}

export const useVatomObjectDefinition = (businessId: string, objectDefinitionId: string) => {
  const sdk = useSDK()
  const vatom = useQuery({
    queryKey: vatomQueryKeys.getVatomObjectDefinition(businessId, objectDefinitionId),
    queryFn: ({ queryKey: [{ businessId, objectDefinitionId }] }) =>
      fetchObjectDefinition(sdk, businessId, objectDefinitionId)
  })
  return vatom
}

export const useCampaignGroupsRules = <SelectResult = CampaignRules>(
  businessId: string,
  campaignId: string,
  select?: (data: CampaignRules) => SelectResult
) => {
  const sdk = useSDK()
  const rules = useQuery({
    queryKey: vatomQueryKeys.getCampaignGroupRules(businessId, campaignId),
    queryFn: async ({ queryKey: [{ businessId, campaignId }] }) => {
      const response = await sdk.service.studio.get<CampaignRules>(
        `/b/${businessId}/campaign-groups/${campaignId}/rules`
      )
      return response.data
    },
    refetchOnMount: true,
    select
  })

  return rules
}

export const useCampaignGroupOverview = <SelectResult = CampaignOverview>(
  businessId: string,
  campaignId: string,
  select?: (data: CampaignOverview) => SelectResult
) => {
  const sdk = useSDK()
  const rules = useQuery({
    queryKey: vatomQueryKeys.getCampaignGroupOverview(businessId, campaignId),
    queryFn: async ({ queryKey: [{ businessId, campaignId }] }) => {
      const response = await sdk.service.studio.get<CampaignOverview>(
        `/b/${businessId}/campaign-groups/${campaignId}/overview`
      )
      return response.data
    },
    refetchOnMount: true,
    select
  })

  return rules
}

// Points
export type UseCampaignPointsOptions = Omit<
  UseQueryOptions<
    CampaignPoints,
    unknown,
    CampaignPoints,
    ReturnType<typeof vatomQueryKeys.getCampaignPoints>
  >,
  'queryKey' | 'queryFn' | 'enabled' | 'refetchOnMount'
>

export const useCampaignPoints = (campaignId: string, options?: UseCampaignPointsOptions) => {
  const user = useUser()
  const accessToken = useAccessToken()
  const points = useQuery({
    queryKey: vatomQueryKeys.getCampaignPoints(campaignId, user?.sub),
    queryFn: async ({ queryKey: [{ campaignId, userSub }] }) => {
      const response = await fetch(`${pointsHost}u/${userSub}/campaign/${campaignId}/points`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        })
      })
      const data = (await response.json()) as CampaignPoints
      return data
    },
    enabled: !!user?.sub && !!accessToken,
    refetchOnMount: true,
    ...options
  })

  return points
}

export const useImperativeCampaignPoints = () => {
  const user = useUser()
  const sdk = useSDK()
  const queryClient = useQueryClient()

  return (campaignId: string) =>
    queryClient.fetchQuery({
      queryKey: vatomQueryKeys.getCampaignPoints(campaignId, user?.sub),
      queryFn: async ({ queryKey: [{ campaignId, userSub }] }) => {
        const response = await sdk.service.points.get<CampaignPoints>(
          `u/${userSub}/campaign/${campaignId}/points`
        )
        return response.data
      }
    })
}

export const usePointsOptimisticUpdate = (campaignId?: string, rules?: CampaignRules) => {
  const queryClient = useQueryClient()
  const user = useUser()

  const handleOptimisticPickup = (token: any) => {
    if (!token.studioInfo?.objectDefinitionId || !rules) {
      console.warn(
        'usePointsOptimisticUpdate: missing objectDefinitionId or rules, skipping optimistic update'
      )
      throw new Error('usePointsOptimisticUpdate: missing objectDefinitionId or rules')
    }

    const pointsToAdd = getPickUpPointsForObjectDefinitionId(
      token.studioInfo?.objectDefinitionId,
      rules
    )

    if (pointsToAdd.points) {
      queryClient.setQueryData<CampaignPoints>(
        vatomQueryKeys.getCampaignPoints(campaignId, user?.sub),
        currentPoints => {
          if (!currentPoints) {
            return {
              total: pointsToAdd.points,
              [pointsToAdd.channel]: pointsToAdd.points
            }
          }
          return {
            ...currentPoints,
            total: (currentPoints.total ?? 0) + pointsToAdd.points,
            [pointsToAdd.channel]: currentPoints[pointsToAdd.channel] + pointsToAdd.points
          }
        }
      )
    }
  }
  return handleOptimisticPickup
}

export type ItemsResponse = {
  items: Campaign[]
}

export const fetchListBusinessCampaignGroups = async (
  sdk: ReturnType<typeof useSDK>,
  businessId: string
): Promise<ItemsResponse> => {
  const vatom = await sdk.service.studio.get(
    `b/${businessId}/campaign-groups?includeObjectDefinitions=true`
  )

  return vatom.data
}

export const useListBusinessCampaignGroups = () => {
  const sdk = useSDK()
  const queryClient = useQueryClient()

  return (businessId: string) =>
    queryClient.fetchQuery({
      queryKey: vatomQueryKeys.getListBusinessCampaignGroups(businessId),
      queryFn: ({ queryKey: [{ businessId }] }) => fetchListBusinessCampaignGroups(sdk, businessId)
    })
}

export type SearchResponse = {
  items: ODef[]
}

export const fetchObjectDefinitionsSearch = async (
  sdk: ReturnType<typeof useSDK>,
  businessId: string,
  campaignId: string,
  searchParams?: ObjectDefinitionSearchParams
): Promise<SearchResponse> => {
  const sp = searchParams ?? {}
  const vatom = await sdk.service.studio.post(
    `/b/${businessId}/campaigns/${campaignId}/object-definitions/search`,
    sp
  )

  return vatom.data
}

export const useObjectDefinitionsSearch = () => {
  const sdk = useSDK()
  const queryClient = useQueryClient()

  return (businessId: string, campaignId: string, searchParams?: ObjectDefinitionSearchParams) =>
    queryClient.fetchQuery({
      queryKey: vatomQueryKeys.getObjectDefinitionsSearch(businessId, campaignId, searchParams),
      queryFn: () => fetchObjectDefinitionsSearch(sdk, businessId, campaignId, searchParams)
    })
}
