import type { FirebaseApp } from '@firebase/app'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  where
} from '@firebase/firestore'

export const getUsersCollection = (
  spaceId: string,
  then: number,
  firebaseApp: FirebaseApp | null
) => {
  if (!firebaseApp) {
    return null
  }

  const db = getFirestore(firebaseApp)

  const serversRef = collection(db, 'servers')
  const spaceDoc = doc(serversRef, spaceId)
  const usersRef = collection(spaceDoc, 'users')

  return getDocs(query(usersRef, where('presence_date', '>', then)))
}

export const getDimension: any = async (spaceId: string, firebaseApp: FirebaseApp | null) => {
  if (!firebaseApp) {
    return Promise.resolve({})
  }

  const db = getFirestore(firebaseApp)

  const serversRef = collection(db, 'servers')
  const spaceDoc = doc(serversRef, spaceId)
  const dimensionsRef = collection(spaceDoc, 'dimensions')
  const defaultDoc = doc(dimensionsRef, 'default')

  return getDoc(defaultDoc)
}

export const getMixerCollection = (spaceId: string, firebaseApp: FirebaseApp | null) => {
  if (!firebaseApp) {
    return null
  }

  const db = getFirestore(firebaseApp)

  const serversRef = collection(db, 'servers')
  const spaceDoc = doc(serversRef, spaceId)

  return collection(spaceDoc, 'hf_mixers')
}

export const getMaxListenerCancel = (
  serverId: string,
  dimensionId: string,
  onMixer: any,
  firebaseApp: FirebaseApp | null
) => {
  if (!firebaseApp) {
    return null
  }

  const db = getFirestore(firebaseApp)

  const serversRef = collection(db, 'servers')
  const serverDoc = doc(serversRef, serverId)
  const hfMixerRef = collection(serverDoc, 'hf_mixers')
  const result = query(hfMixerRef, where('dimension', '==', dimensionId))

  return onSnapshot(result, onMixer)
}

export const setVatomIncUsers = async (
  spaceId: string,
  userId: string,
  props: any,
  firebaseApp: FirebaseApp | null
) => {
  if (!firebaseApp) {
    return null
  }

  const db = getFirestore(firebaseApp)

  const serversRef = collection(db, 'servers')
  const spaceDoc = doc(serversRef, spaceId)
  const usersRef = collection(spaceDoc, 'users')
  const vatomincUserDoc = doc(usersRef, 'vatominc:' + userId)

  return setDoc(vatomincUserDoc, props, { merge: true })
}

export const setUsersPublic = async (
  userId: string,
  props: any,
  firebaseApp: FirebaseApp | null
) => {
  if (!firebaseApp) {
    return null
  }

  const db = getFirestore(firebaseApp)

  const usersPublicRef = collection(db, 'users_public')
  const vatomincUserDoc = doc(usersPublicRef, 'vatominc:' + userId)

  return setDoc(vatomincUserDoc, props, { merge: true })
}

export const getAllOnlineUsers = async (spaceId: string, firebaseApp: FirebaseApp | null) => {
  if (!firebaseApp) {
    return Promise.resolve({
      docs: []
    })
  }

  const db = getFirestore(firebaseApp)

  const serversRef = collection(db, 'servers')
  const spaceDoc = doc(serversRef, spaceId)
  const usersRef = collection(spaceDoc, 'users')
  const result = getDocs(query(usersRef, where('presence_date', '>=', Date.now() - 60000)))

  return result
}
