/* eslint-disable unicorn/filename-case */
import { useBreakpointValue } from 'native-base'

function useIsDesktop() {
  const deviceBreakpoint = useBreakpointValue({
    base: 'mobile',
    md: 'mobile',
    lg: 'desktop'
  })
  return deviceBreakpoint === 'desktop'
}

export { useIsDesktop }
