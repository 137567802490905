import InfoIcon from './info.svg'
import SuccessIcon from './success.svg'
import WarningIcon from './warning.svg'

export const icons = {
  info: require('./info.png'),
  error: require('./error.png'),
  warning: require('./warning.png'),
  success: require('./success.png'),
  closeBlack: require('./close-black.png'),
  closeWhite: require('./close-white.png')
}
export const IconsSVG = {
  Info: InfoIcon,
  Error: WarningIcon,
  Warning: WarningIcon,
  Success: SuccessIcon
}

export type IconTypes = keyof typeof icons
export type IconSVGTypes = keyof typeof IconsSVG
