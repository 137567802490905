import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { applySnapshot, types } from 'mobx-state-tree'

import { LinkingModel } from '../linking/linking.store'
import { NetworkStore } from '../network'

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model('RootStoreModel')
  .props({
    linking: types.optional(LinkingModel, {}),
    network: types.optional(NetworkStore, {})
  })
  .actions(self => ({
    clear() {
      applySnapshot(self, {})
    }
  }))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
