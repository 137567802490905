import type { Instance, SnapshotOut } from 'mobx-state-tree'

import { withEventEmitter } from '../EventEmitter/withEventEmitter'

import { RegionStore } from './RegionStore'

export const CompositeRegionStore =
  RegionStore.named('CompositeRegionStore').extend(withEventEmitter)

export type CompositeRegionStoreType = Instance<typeof CompositeRegionStore>
export type CompositeRegionStoreSnapshot = SnapshotOut<typeof CompositeRegionStore>
