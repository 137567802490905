export interface IEventRelation {
  rel_type?: RelationType | string
  event_id?: string
  is_falling_back?: boolean
  'm.in_reply_to'?: {
    event_id?: string
  }
  key?: string
}

export const TEMP_EVENT_PREFIX = 'tempEventId_' as const

/**
 * Well-known values (from the spec or MSCs) that are allowed in the
 * {@link Membership} type.
 */
export enum KnownMembership {
  /**
   * The user has been banned from the room, and is no longer allowed to join
   * it until they are un-banned from the room (by having their membership
   * state set to a value other than ban).
   */
  Ban = 'ban',
  /**
   * The user has been invited to join a room, but has not yet joined it.
   * They may not participate in the room until they join.
   * */
  Invite = 'invite',
  /**
   * The user has joined the room (possibly after accepting an invite), and
   * may participate in it.
   */
  Join = 'join',
  /**
   * The user has knocked on the room, requesting permission to participate.
   * They may not participate in the room until they join.
   */
  Knock = 'knock',
  /**
   * The user was once joined to the room, but has since left (possibly by
   * choice, or possibly by being kicked).
   */
  Leave = 'leave'
}

/**
 * The membership state for a user in a room [1]. A value from
 * {@link KnownMembership} should be used where available, but all string values
 * are allowed to provide flexibility for upcoming spec changes or proposals.
 *
 * [1] https://spec.matrix.org/latest/client-server-api/#mroommember
 */
export type Membership = KnownMembership | string

/* eslint-disable camelcase */
export interface IContent {
  [key: string]: any
  msgtype?: MsgType | string
  membership?: Membership
  avatar_url?: string
  displayname?: string
  'm.relates_to'?: IEventRelation

  'm.mentions'?: IMentions
}

type StrippedState = Required<Pick<IEvent, 'content' | 'state_key' | 'type' | 'sender'>>

const UNSIGNED_THREAD_ID_FIELD = {
  name: 'org.matrix.msc4023.thread_id'
} as const

export interface IUnsigned {
  [key: string]: any
  age?: number
  prev_sender?: string
  prev_content?: IContent
  redacted_because?: IEvent
  replaces_state?: string
  transaction_id?: string
  invite_room_state?: StrippedState[]
  'm.relations'?: Record<RelationType | string, any> // No common pattern for aggregated relations
  [UNSIGNED_THREAD_ID_FIELD.name]?: string
}

export interface IThreadBundledRelationship {
  latest_event: IEvent
  count: number
  current_user_participated?: boolean
}

export interface IEvent {
  event_id: string
  type: string
  content: IContent
  sender: string
  room_id?: string
  origin_server_ts: number
  txn_id?: string
  state_key?: string
  membership?: Membership
  unsigned: IUnsigned
  redacts?: string
}

export enum EventType {
  // Room state events
  RoomCanonicalAlias = 'm.room.canonical_alias',
  RoomCreate = 'm.room.create',
  RoomJoinRules = 'm.room.join_rules',
  RoomMember = 'm.room.member',
  RoomThirdPartyInvite = 'm.room.third_party_invite',
  RoomPowerLevels = 'm.room.power_levels',
  RoomName = 'm.room.name',
  RoomTopic = 'm.room.topic',
  RoomAvatar = 'm.room.avatar',
  RoomPinnedEvents = 'm.room.pinned_events',
  RoomEncryption = 'm.room.encryption',
  RoomHistoryVisibility = 'm.room.history_visibility',
  RoomGuestAccess = 'm.room.guest_access',
  RoomServerAcl = 'm.room.server_acl',
  RoomTombstone = 'm.room.tombstone',
  RoomPredecessor = 'org.matrix.msc3946.room_predecessor',

  SpaceChild = 'm.space.child',
  SpaceParent = 'm.space.parent',

  // Room timeline events
  RoomRedaction = 'm.room.redaction',
  RoomMessage = 'm.room.message',
  RoomMessageEncrypted = 'm.room.encrypted',
  Sticker = 'm.sticker',
  CallInvite = 'm.call.invite',
  CallCandidates = 'm.call.candidates',
  CallAnswer = 'm.call.answer',
  CallHangup = 'm.call.hangup',
  CallReject = 'm.call.reject',
  CallSelectAnswer = 'm.call.select_answer',
  CallNegotiate = 'm.call.negotiate',
  CallSDPStreamMetadataChanged = 'm.call.sdp_stream_metadata_changed',
  CallSDPStreamMetadataChangedPrefix = 'org.matrix.call.sdp_stream_metadata_changed',
  CallReplaces = 'm.call.replaces',
  CallAssertedIdentity = 'm.call.asserted_identity',
  CallAssertedIdentityPrefix = 'org.matrix.call.asserted_identity',
  KeyVerificationRequest = 'm.key.verification.request',
  KeyVerificationStart = 'm.key.verification.start',
  KeyVerificationCancel = 'm.key.verification.cancel',
  KeyVerificationMac = 'm.key.verification.mac',
  KeyVerificationDone = 'm.key.verification.done',
  KeyVerificationKey = 'm.key.verification.key',
  KeyVerificationAccept = 'm.key.verification.accept',
  // Not used directly - see READY_TYPE in VerificationRequest.
  KeyVerificationReady = 'm.key.verification.ready',
  // use of this is discouraged https://matrix.org/docs/spec/client_server/r0.6.1#m-room-message-feedback
  RoomMessageFeedback = 'm.room.message.feedback',
  Reaction = 'm.reaction',
  PollStart = 'org.matrix.msc3381.poll.start',

  // Room ephemeral events
  Typing = 'm.typing',
  Receipt = 'm.receipt',
  Presence = 'm.presence',

  // Room account_data events
  FullyRead = 'm.fully_read',
  Tag = 'm.tag',
  SpaceOrder = 'org.matrix.msc3230.space_order', // MSC3230

  // User account_data events
  PushRules = 'm.push_rules',
  Direct = 'm.direct',
  IgnoredUserList = 'm.ignored_user_list',

  // to_device events
  RoomKey = 'm.room_key',
  RoomKeyRequest = 'm.room_key_request',
  ForwardedRoomKey = 'm.forwarded_room_key',
  Dummy = 'm.dummy',

  // Group call events
  GroupCallPrefix = 'org.matrix.msc3401.call',
  GroupCallMemberPrefix = 'org.matrix.msc3401.call.member'
}

export enum MsgType {
  Text = 'm.text',
  Emote = 'm.emote',
  Notice = 'm.notice',
  Image = 'm.image',
  File = 'm.file',
  Audio = 'm.audio',
  Location = 'm.location',
  Video = 'm.video',
  KeyVerificationRequest = 'm.key.verification.request'
}

export enum RelationType {
  Annotation = 'm.annotation',
  Replace = 'm.replace',
  Reference = 'm.reference',
  Thread = 'm.thread'
}

export interface IUnsigned {
  age?: number
  prev_sender?: string
  prev_content?: IContent
  redacted_because?: IEvent
  transaction_id?: string
  invite_room_state?: StrippedState[]
  'm.relations'?: Record<RelationType | string, any> // No common pattern for aggregated relations
}

export interface IMentions {
  user_ids?: string[]
  room?: boolean
}
interface PushRuleAction {
  notify?: boolean
  set_tweak?: string
  value?: string | boolean
}

interface PushRuleCondition {
  kind: string
  key?: string
  pattern?: string | number | boolean
  is?: string
}

interface PushRule {
  conditions: PushRuleCondition[]
  actions: PushRuleAction[]
  rule_id: string
  default: boolean
  enabled: boolean
}

interface GlobalPushRules {
  underride: PushRule[]
  sender: unknown[]
  room: unknown[]
  content: PushRule[]
  override: PushRule[]
}

interface AccountData {
  events: {
    type: string
    content:
      | {
          global: GlobalPushRules
        }
      | Record<string, string[]>
  }[]
}

export type MessageReaction = {
  sender: string
  eventId: string
  key: string
}
export interface PresenceEvent {
  type: string
  sender: string
  content: {
    presence: string
    last_active_ago?: number
    currently_active?: boolean
  }
}

interface DeviceLists {
  changed: string[]
}

interface DeviceOneTimeKeysCount {
  signed_curve25519: number
}

export interface RoomEvent {
  type: string
  sender: string
  content: {
    [key: string]: any
  }
  state_key: string
  origin_server_ts: number
  unsigned: {
    age: number
    [key: string]: any
  }
  event_id: string
  redacts?: string
}

interface RoomState {
  events: RoomEvent[]
}

export interface RoomInfo {
  timeline: {
    events: RoomEvent[]
    prev_batch: string
    limited: boolean
  }
  state: RoomState
  account_data: {
    events: unknown[]
  }
  ephemeral: {
    events: IEvent[]
  }
  unread_notifications: {
    notification_count: number
    highlight_count: number
  }
  summary: unknown
  invite_state?: { events: RoomEvent[] }
}

export interface Rooms {
  [key: string]: RoomInfo
}

export type MatrixData = {
  next_batch: string
  account_data: AccountData
  presence: {
    events: PresenceEvent[]
  }
  device_lists: DeviceLists
  device_one_time_keys_count: DeviceOneTimeKeysCount
  rooms: {
    join: Rooms
    leave?: Rooms
    invite?: Rooms
  }
}

export interface MemberDetails {
  powerLevel?: number
  // Used to determine last visited rooms
  lastActivityTs?: number
  name?: string
}

export interface SimplifiedRoom {
  businessId: string
  spaceId: string
  visibility?: 'public' | 'private'
  cover?: string
  displayName?: string
  matrixRoomId: string
  membersDetails?: Record<string, MemberDetails>
  //  Used to determine the sort for the rooms
  lastUserActivityTs: number
}

export type BusinessRooms = {
  businessId: string
  rooms: SimplifiedRoom[]
}

interface WellKnownHomeserver {
  base_url: string
}

interface WellKnown {
  'm.homeserver': WellKnownHomeserver
}

export type MatrixUserData = {
  user_id: string
  access_token: string
  home_server: string
  device_id: string
  well_known: WellKnown
}

export const KNOWN_SAFE_ROOM_VERSION = '9'
