import { z } from 'zod'

const pageThemeHeaderSchema = z.object({
  logo: z.string()
})

const pageThemeIconTitleSchema = z.object({})

const pageThemeIconSchema = z.object({})

const pageThemeMainSchema = z.object({})

const pageThemeEmptyStateSchema = z.object({})

const pointsHeaderSchema = z.object({
  color: z.string().optional(),
  enabled: z.boolean().optional(),
  position: z.string().optional(),
  title: z.string().optional(),
  channel: z.string().optional()
})

const coinsHeaderSchema = z.object({
  enabled: z.boolean().optional()
})

const pageThemeSchema = z.object({
  header: pageThemeHeaderSchema,
  iconTitle: pageThemeIconTitleSchema,
  icon: pageThemeIconSchema,
  main: pageThemeMainSchema,
  emptyState: pageThemeEmptyStateSchema,
  mode: z.string(),
  pageTheme: z.string().optional(),
  pointsHeader: pointsHeaderSchema
})

const pageTextSchema = z.object({
  emptyState: z.string()
})

const pageFeaturesNotificationsSchema = z.object({})

const pageFeaturesCardSchema = z.object({})

const pageFeaturesFooterIconSchema = z.object({
  id: z.string(),
  src: z.string(),
  title: z.string(),
  link: z.string().optional()
})

const pageFeaturesFooterSchema = z.object({
  enabled: z.boolean(),
  visible: z.boolean().optional(),
  icons: z.array(pageFeaturesFooterIconSchema)
})

const pageFeaturesVatomSchema = z.object({
  pinned: z.array(z.string()).optional(),
  autoOpen: z.array(z.string()).optional(),
  disableNewTokenToast: z.boolean().optional(),
  ignoreNewTokenToast: z.array(z.string()).optional()
})

const pageIconFeaturesSchema = z.object({
  badges: z.boolean(),
  editions: z.boolean(),
  titles: z.boolean()
})

const pageFeaturesSchema = z.object({
  notifications: pageFeaturesNotificationsSchema,
  card: pageFeaturesCardSchema,
  footer: pageFeaturesFooterSchema,
  vatom: pageFeaturesVatomSchema,
  pointsHeader: pointsHeaderSchema,
  coinsHeader: coinsHeaderSchema,
  icon: pageIconFeaturesSchema.optional(),
  eth: z
    .object({
      addressSelectorIcon: z.boolean().optional()
    })
    .optional(),
  header: z
    .object({
      brandedBackground: z.boolean().optional(),
      image: z
        .object({
          src: z.string(),
          width: z.number(),
          height: z.number()
        })
        .optional()
    })
    .optional()
})

export const pageConfigSchema = z.object({
  theme: pageThemeSchema,
  text: pageTextSchema,
  features: pageFeaturesSchema
})

const colorShema = z.object({
  light: z.string(),
  dark: z.string()
})

export const brandConfigSchema = z.object({
  icon: z.string(),
  colors: z.object({
    background: colorShema,
    primary: colorShema,
    text: colorShema,
    primaryText: colorShema
  }),
  mode: z.string()
})

export const businessDataSchema = z.object({
  id: z.string(),
  name: z.string(),
  displayName: z.string(),
  viewerId: z.string(),
  logoSrc: z.string(),
  url: z.string().nullable(),
  location: z.string(),
  createdAt: z.string(),
  defaultCampaignId: z.string().optional(),
  pageConfig: pageConfigSchema,
  brandConfig: brandConfigSchema,
  terms: z.array(z.string()),
  ownerId: z.string()
})

export const businessReferenceSchema = z.object({
  business: z.string().optional(),
  businessId: z.string().optional(),
  join: z.boolean().optional(),
  context: z.string().optional()
})

export const businessCommunitySchema = z.object({
  id: z.string(),
  businessId: z.string(),
  name: z.string(),
  isArchived: z.boolean(),
  icon: z.string(),
  isDefault: z.boolean()
})
