import { getConfig, refreshToken, SDKQueryClient } from '@vatom/sdk/react'
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { getRoot, types } from 'mobx-state-tree'

import logger from '../../../logger'
import { withEventEmitter } from '../../EventEmitter'
import type { RootSDKStoreType } from '../../RootSdk'

import { AxiosFactory } from './AxiosFactory'

export enum Resources {
  auth = 'auth',
  vatom = 'vatom',
  vatoms = 'vatoms',
  oidc = 'oidc',
  geo = 'geo',
  billing = 'billing',
  businesses = 'businesses',
  network = 'network',
  studio = 'studio',
  points = 'points',
  events = 'events',
  loyalty = 'loyalty',
  userVatom = 'userVatom',
  users = 'users'
}

export type ResourcesParamList = Record<keyof typeof Resources, undefined>
export type ResourcesArray = Array<keyof typeof Resources>

export const LogoutEvent = 'LOGOUT'

const COMMON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

/**
 * ApiAxiosStore model.
 */

export const ApiAxiosStore = types
  .model('ApiAxiosStore')
  .props({})
  .extend(withEventEmitter)
  .volatile(() => {
    // @ts-ignore
    const services: Record<Resources, AxiosInstance> = {}
    return { services }
  })
  .views(self => ({
    get baseUrls(): Record<Resources, string> {
      const config = getConfig()
      return {
        [Resources.auth]: config.authentication.discoveryUrl,
        [Resources.vatom]: config.api.vatom,
        [Resources.vatoms]: config.api.vatoms,
        [Resources.oidc]: config.api.oidc,
        [Resources.geo]: config.api.geo,
        [Resources.billing]: config.api.billing,
        [Resources.businesses]: config.api.businesses,
        [Resources.network]: config.api.network,
        [Resources.studio]: config.api.studio,
        [Resources.points]: config.api.points,
        [Resources.events]: config.api.events,
        [Resources.loyalty]: config.api.loyalty,
        [Resources.userVatom]: config.api.userVatom,
        [Resources.users]: config.api.users
      } as const
    },

    get names(): ResourcesArray {
      const names: ResourcesArray = Object.values(Resources)
      return names
    }
  }))
  .actions(self => {
    const getAxiosConfig = () => {
      // const env = getEnv<Env>(self)
      // const { vatomUrl, farmURL } = env
      const config = getConfig()
      const vatomUrl = config.authentication.discoveryUrl
      const axiosConfig: AxiosRequestConfig = {
        baseURL: vatomUrl,
        withCredentials: true
      }
      logger.info('ApiAxiosStore getAxiosConfig', axiosConfig)
      return axiosConfig
    }

    let sessionPromise: ReturnType<typeof refreshToken> | null

    const refreshSession = async () => {
      if (sessionPromise) {
        return await sessionPromise
      }
      try {
        const root = getRoot(self) as RootSDKStoreType
        const promise = SDKQueryClient.fetchQuery(['refresh-token'], () => {
          return refreshToken(root)
        })
        sessionPromise = promise
        const session = await sessionPromise
        return session
      } catch (e) {
        return null
      } finally {
        sessionPromise = null
      }
    }

    const initServices = (token?: string) => {
      const axiosConfig = getAxiosConfig()
      if (token) {
        axiosConfig.headers = {
          ...COMMON_HEADERS,
          Authorization: `Bearer ${token}`
          // NOTE: Browser will throw error Refused to set unsafe header "Cookie"
          // so we use withCredentials above^^
          // https://stackoverflow.com/questions/52549079/does-axios-support-set-cookie-is-it-possible-to-authenticate-through-axios-http?rq=1
          // Cookie: `token=${token}`
        }
      } else {
        axiosConfig.headers = { ...axiosConfig.headers, ...COMMON_HEADERS }
      }

      self.names.forEach(name => {
        const baseURL = self.baseUrls[name]
        const config = baseURL
          ? {
              axiosConfig: {
                ...axiosConfig,
                baseURL
              }
            }
          : {
              axiosConfig
            }

        if (Resources.studio || Resources.userVatom) {
          self.services[name] = AxiosFactory.create({
            ...config,
            axiosConfig: {
              ...config.axiosConfig,
              withCredentials: false
            }
          })
        } else {
          self.services[name] = AxiosFactory.create(config)
        }

        if (token) {
          self.services[name].interceptors.request.use(
            async requestConfig => {
              const session = await refreshSession()

              const accessToken = session?.accessToken
              if (!accessToken) {
                return requestConfig
              } else {
                requestConfig.headers.Authorization = `Bearer ${accessToken}`
                return requestConfig
              }
            },
            (error: AxiosError) => Promise.reject(error)
          )

          self.services[name].interceptors.response.use(
            async (response: AxiosResponse) => response,
            async (error: AxiosError) => {
              const currentRequestConfig = error.config ?? ({} as AxiosRequestConfig)
              // @ts-ignore
              if (error?.response?.status === 401 && !currentRequestConfig?._retry) {
                const session = await refreshSession()

                const accessToken = session?.accessToken
                if (!accessToken) {
                  return Promise.reject(error)
                }
                // @ts-ignore
                currentRequestConfig._retry = true
                // Set new access token
                currentRequestConfig.headers = {
                  ...(currentRequestConfig?.headers ?? {}),
                  Authorization: `Bearer ${accessToken}`
                }
                self.services[name] = AxiosFactory.create({
                  axiosConfig: currentRequestConfig
                })
                return self.services[name].request(currentRequestConfig)
              }
              return Promise.reject(error)
            }
          )
        }
      })
    }

    const setToken = (token?: string) => {
      initServices(token)
    }

    const afterCreate = () => {
      const root = getRoot(self) as RootSDKStoreType
      const axiosConfig = getAxiosConfig()

      const accessToken = root.dataPool.sessionStore.vatomIncSessionToken?.accessToken

      self.names.forEach(name => {
        self.services[name] = AxiosFactory.create({
          axiosConfig,
          emitterConfig: {
            emitter: self.emitter,
            logoutEvent: LogoutEvent
          }
        })
      })

      initServices(accessToken)
    }

    return {
      afterCreate,
      setToken
    }
  })
  .views(self => ({
    get auth() {
      return self.services.auth
    },
    get vatom() {
      return self.services.vatom
    },
    get vatoms() {
      return self.services.vatoms
    },
    get oidc() {
      return self.services.oidc
    },
    get geo() {
      return self.services.geo
    },
    get billing() {
      return self.services.billing
    },
    get businesses() {
      return self.services.businesses
    },
    get studio() {
      return self.services.studio
    },
    get points() {
      return self.services.points
    },
    get loyalty() {
      return self.services.loyalty
    },
    get network() {
      return self.services.network
    },
    get events() {
      return self.services.events
    },
    get userVatom() {
      return self.services.userVatom
    },
    get users() {
      return self.services.users
    }
  }))

export type ApiAxiosStoreType = Instance<typeof ApiAxiosStore>
export type ApiAxiosStoreSnapshot = SnapshotOut<typeof ApiAxiosStore>
