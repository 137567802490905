import { useCallback, useMemo } from 'react'
import type {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseQueryOptions
} from '@tanstack/react-query'
import { useInfiniteQuery, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import type { IEvent, MatrixData, MessageReaction, RoomInfo } from '@vatom/models'
import { EventType, TEMP_EVENT_PREFIX } from '@vatom/models'
import { useGetMatrixFullStateSync, useMatrixUser } from '@vatom/sdk/react'
import axios from 'axios'

import { areMatrixUsers, getMatrixUsers, isLocalRoom } from './helpers'
import {
  dmUsersOnRoomByIdsSelector,
  presenceByUserIds,
  roomDataSelector,
  roomsByIdsSelector
} from './selectors'

export const matrixServerUrl = 'https://matrix.api.vatominc.com'

export const dmQueryKeys = {
  dmMessages: [{ scope: 'dm-messages' }] as const,
  getDmMessages: (roomId: string) => [{ ...dmQueryKeys.dmMessages[0], roomId }] as const,
  dmUserProfile: [{ scope: 'dm-user-profile' }] as const,
  getDmUserProfile: (userId: string) => [{ ...dmQueryKeys.dmUserProfile[0], userId }] as const,
  dmRoomState: [{ scope: 'dm-room-state' }] as const,
  getDmRoomState: (roomId: string) => [{ ...dmQueryKeys.dmRoomState[0], roomId }] as const,
  dmRoomMembers: [{ scope: 'dm-room-members' }] as const,
  getDmRoomMembers: (roomId: string) => [{ ...dmQueryKeys.dmRoomMembers[0], roomId }] as const,
  dmUserAccountData: [{ scope: 'dm-user-account-data' }] as const,
  getDmUserAccountData: (userId: string, eventType: EventType) =>
    [{ ...dmQueryKeys.dmUserAccountData[0], userId, eventType }] as const,
  dmMessageReactions: [{ scope: 'dm-message-reactions' }] as const,
  getDmMessageReactions: (roomId: string, eventId: string) =>
    [{ ...dmQueryKeys.dmMessageReactions[0], roomId, eventId }] as const,
  dmRoomEvent: [{ scope: 'dm-room-event' }] as const,
  getDmRoomEvent: (roomId: string, eventId: string) =>
    [{ ...dmQueryKeys.dmRoomEvent[0], roomId, eventId }] as const
}

// ************************
//      DM ROOM DATA
// *************************

export const useRoom = (roomId: string) => {
  return useGetMatrixFullStateSync({
    select: data => roomDataSelector(data, roomId)
  })
}

// ************************
//      USER ACCOUNT DATA
// *************************

export const useAccountDataEvents = () => {
  return useGetMatrixFullStateSync({
    select: data => {
      return (data?.account_data?.events as Partial<IEvent>[]) ?? []
    }
  })
}

// ************************
//      MESSAGES
// *************************

export type MessageData = {
  chunk: IEvent[]
  start: string
  end: string
}

type FetchMessagesParams = {
  pageParam: string | null
  roomId: string
  accessToken?: string
  filters: string
  prevBatch?: string
  nextBatch?: string
}

const fetchMessages = async <T = MessageData>({
  pageParam,
  roomId,
  accessToken,
  filters = '',
  prevBatch,
  nextBatch
}: FetchMessagesParams) => {
  const prevBatchParams = prevBatch ? `&start=${prevBatch}` : ''
  const nextBatchParams = '' //nextBatch ? `&since=${nextBatch}&timeout=30000` : ''
  const url = `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/messages?access_token=${accessToken}&dir=b&limit=20&from=${
    pageParam ?? ''
  }${filters ?? ''}${prevBatchParams}${nextBatchParams}`
  const res = (await axios.get(url).then(({ data }) => data)) as T
  return res
}

type MessagesQueryOptions<T> = Omit<
  UseInfiniteQueryOptions<
    MessageData,
    unknown,
    T,
    MessageData,
    ReturnType<typeof dmQueryKeys.getDmMessages>
  >,
  'queryKey' | 'queryFn'
>

export const useDirectMessages = <T = IEvent>(
  { roomId, senderId }: { roomId: string; senderId?: string },
  options: MessagesQueryOptions<T> = {}
) => {
  const { data: matrixUser } = useMatrixUser()

  const { data: roomData } = useRoom(roomId)

  const prevBatch = roomData?.timeline?.prev_batch

  const roomMessageFilter = {
    types: ['m.room.message']
  }
  // TODO: use senderId?
  // ${sender ? `,"senders":["${sender}"]` : ''}
  const filter = JSON.stringify(roomMessageFilter)

  // Store prev_batch?
  const filterParam = filter ? `&filter=${filter}` : ''
  return useInfiniteQuery(
    dmQueryKeys.getDmMessages(roomId),
    async ({ pageParam = '' }) =>
      await fetchMessages({
        pageParam,
        roomId,
        accessToken: matrixUser?.access_token,
        filters: filterParam,
        prevBatch
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.end ?? pages[0]?.end ?? undefined
      },
      // getPreviousPageParam: (firstPage, allPages) => firstPage?.start ?? undefined,
      enabled: !!matrixUser?.access_token && options?.enabled,
      // keepPreviousData: true,
      onError: error => {
        console.log('LOG: useDirectMessages > error:', error)
      },
      select: data => {
        const pages =
          data?.pages.flatMap(group => {
            return group?.chunk?.filter((event: IEvent) => !('redacted_because' in event)) ?? []
          }) ?? []

        return {
          pages: pages as T[],
          pageParams: data?.pageParams,
          nextPage: data?.pages[data.pages.length - 1]?.end
        }
      },
      ...options
    }
  )
}

// ************************
//      USER PROFILE
// *************************
const getUserProfile = async (accessToken?: string, userId?: string) => {
  const res = await axios
    .get(
      `${matrixServerUrl}/_matrix/client/v3/profile/${userId}/displayname?access_token=${accessToken}`
    )
    .then(({ data }) => data)
  return res
}

type UserProfile = {
  user_id: string
  displayname: string
  avatar_url?: string
}
type ProfileQueryOptions<T> = Omit<
  UseQueryOptions<UserProfile, unknown, T, ReturnType<typeof dmQueryKeys.getDmUserProfile>>,
  'queryKey' | 'queryFn'
>
export const useMatrixUserProfile = <T = UserProfile>(
  userId: string,
  options: ProfileQueryOptions<T> = {}
) => {
  const { data: matrixUser } = useMatrixUser()
  return useQuery({
    queryKey: dmQueryKeys.getDmUserProfile(userId),
    queryFn: async () => {
      const data = await getUserProfile(matrixUser?.access_token, userId)
      return { ...data, user_id: userId }
    },
    enabled: !!matrixUser?.access_token && options?.enabled,
    ...options
  })
}

export const useMatrixUserProfiles = <T = UserProfile[]>(
  userIds: string[],
  options: ProfileQueryOptions<T> = {}
) => {
  const { data: matrixUser } = useMatrixUser()

  const queries = useQueries({
    queries: userIds.map(userId => ({
      queryKey: dmQueryKeys.getDmUserProfile(userId),
      queryFn: async () => {
        const data = await getUserProfile(matrixUser?.access_token, userId)
        return { ...data, user_id: userId }
      },
      enabled: !!userId,
      ...options
    }))
  })

  return queries
}

// ************************
//      ROOM STATE
// *************************
const getRoomState = async (accessToken?: string, roomId?: string) => {
  const res = await axios
    .get(`${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/state?access_token=${accessToken}`)
    .then(({ data }) => data)
  return res
}

type RoomStateQueryOptions<T> = Omit<
  UseQueryOptions<IEvent[], unknown, T, ReturnType<typeof dmQueryKeys.getDmRoomState>>,
  'queryKey' | 'queryFn'
>

export const useRoomState = <T = IEvent>(
  roomId: string,
  options: RoomStateQueryOptions<T> = {}
) => {
  const { data: matrixUser } = useMatrixUser()
  return useQuery({
    queryKey: dmQueryKeys.getDmRoomState(roomId),
    queryFn: async () => await getRoomState(matrixUser?.access_token, roomId),
    enabled: !!matrixUser?.access_token && options?.enabled,
    ...options
  })
}

// ************************
//      ROOM MEMBERS
// *************************
const getRoomMembers = async (accessToken?: string, roomId?: string) => {
  try {
    const res = await axios
      .get(
        `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/members?access_token=${accessToken}`
      )
      .then(({ data }) => data)
    return res
  } catch (error) {
    console.warn('dm.queries: getRoomMembers error:', error)
  }
}

export type RoomMembers = {
  chunk: IEvent[]
}
type RoomMembersQueryOptions<T> = Omit<
  UseQueryOptions<RoomMembers, unknown, T, ReturnType<typeof dmQueryKeys.getDmRoomMembers>>,
  'queryKey' | 'queryFn'
>
export const useRoomMembers = <T = RoomMembers>(
  roomId: string,
  options: RoomMembersQueryOptions<T> = {}
) => {
  const { data: matrixUser } = useMatrixUser()
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: dmQueryKeys.getDmRoomMembers(roomId),
    queryFn: async () => {
      if (isLocalRoom(roomId)) {
        return queryClient.getQueryData(dmQueryKeys.getDmRoomMembers(roomId))
      }
      return await getRoomMembers(matrixUser?.access_token, roomId)
    },

    enabled: !!matrixUser?.access_token && options?.enabled,
    ...options
  })
}

// ************************
//      USER PRESENCE
// *************************

export const useUsersPresence = (userIds: string[]) => {
  return useGetMatrixFullStateSync({
    // TODO: Improve selector
    select: (data: MatrixData) => presenceByUserIds(data, userIds)
  })
}

export const useUserTyping = (roomId: string) => {
  return useGetMatrixFullStateSync({
    select: data => {
      const roomData = roomDataSelector(data, roomId)
      const typingEvent = roomData?.ephemeral.events.find(event => event.type === EventType.Typing)

      return typingEvent?.content?.user_ids ?? []
    }
  })
}

// ************************
//      DM ROOM WITH USERS
// *************************

export const useGetDmRoomsWithUsers = () => {
  return useGetMatrixFullStateSync({
    select: data => {
      const accountDataEvents = (data?.account_data?.events as Partial<IEvent>[]) ?? []
      const directEvent = accountDataEvents?.find(event => event.type === EventType.Direct)

      return dmUsersOnRoomByIdsSelector(directEvent?.content ?? {})
    }
  })
}

function roomHasDirectEvent(room: RoomInfo, userId: string) {
  const isDirectInTimeline = room.timeline.events.some(event => event.content?.is_direct === true)
  const isDirectInState = room.state.events.some(event => event.content?.is_direct === true)
  const isCreatedByUserInState = room.state.events.some(
    event => event.type === EventType.RoomCreate && event.content?.creator === userId
  )
  const isDirectAndHasChanged = room.state.events.some(
    event => event.unsigned?.prev_content?.is_direct === true
  )
  return [isDirectInTimeline, isDirectInState, isCreatedByUserInState, isDirectAndHasChanged].some(
    val => val === true
  )
}
/**
 * If a room has an alias it's from spaces
 * @param room
 * @returns
 */
function roomHasAlias(room: RoomInfo) {
  return room.state.events.some(event => event.type === 'm.room.canonical_alias')
}
/**
 * An array with DM rooms - roomId,  userIds, lastEvent.
 * Order by last event time sent
 *
 */
export const useGetDmRooms = () => {
  const { data: matrixUser } = useMatrixUser()
  // const userId = matrixUser?.user_id ?? ''
  return useGetMatrixFullStateSync({
    // enabled: userId !== '',
    select: data => {
      const accountDataEvents = (data?.account_data?.events as Partial<IEvent>[]) ?? []
      const directEvent = accountDataEvents?.find(event => event.type === EventType.Direct)
      const dmRooms = dmUsersOnRoomByIdsSelector(directEvent?.content ?? {})
      const rooms = roomsByIdsSelector(data, Object.keys(dmRooms))

      const roomsFiltered = Object.entries(rooms).reduce((acc, [roomId, room]) => {
        if (roomHasAlias(room)) {
          // the room is a space, ignore room
          return acc
        }
        return {
          ...acc,
          [roomId]: room
        }
      }, {} as Record<string, RoomInfo>)

      const roomsFormatted = Object.entries(roomsFiltered).map(([roomId, room]) => {
        const roomMessageEvents = room.timeline.events.filter(
          event => event.type === EventType.RoomMessage
        )
        const userIds = dmRooms[roomId] ?? []
        const lastEventMessage = roomMessageEvents[roomMessageEvents.length - 1]
        const lastEvent = room.timeline.events[room.timeline.events.length - 1]
        return {
          roomId,
          userIds,
          lastEvent: lastEventMessage ?? lastEvent
        }
      })
      // Sort by last event sent
      roomsFormatted.sort((a, b) => b.lastEvent.origin_server_ts - a.lastEvent.origin_server_ts)

      return roomsFormatted
    }
  })
}

export const useGetUsersWithDmRooms = () => {
  return useGetMatrixFullStateSync({
    select: data => {
      const accountDataEvents = (data?.account_data?.events as Partial<IEvent>[]) ?? []
      const directEvent = accountDataEvents?.find(event => event.type === EventType.Direct)

      const roomsWithUsers = dmUsersOnRoomByIdsSelector(directEvent?.content ?? {})

      const usersInSingleRoom: string[] = []
      Object.values(roomsWithUsers).forEach(userIds => {
        // single user
        if (userIds.length === 1) {
          if (!usersInSingleRoom.includes(userIds[0])) {
            usersInSingleRoom.push(userIds[0])
            return
          }
        }
        // TODO: implement multiple users
      })

      return usersInSingleRoom
    }
  })
}

export const useDmRoomBetweenUsers = (userIds: string[]) => {
  const { data: accountDataEvents, isLoading } = useAccountDataEvents()
  const directEvent = accountDataEvents?.find(event => event.type === EventType.Direct)
  const mDirectEvent = useMemo(() => directEvent?.content ?? {}, [directEvent])

  return useQuery({
    queryKey: ['dm-between-users', userIds],
    queryFn: () => {
      const roomByIdsWithUsers = dmUsersOnRoomByIdsSelector(mDirectEvent)

      // Example: user id or invitees //  g51hft6 - @g51hft6:vatom.com
      const userIdsToCheck = areMatrixUsers(userIds) ? userIds : getMatrixUsers(userIds)
      console.log('LOG: > useGetDmRoomBetweenUsers > userIdsToCheck:', userIdsToCheck)

      const [foundRoomForUser] = Object.keys(roomByIdsWithUsers)
        .filter(roomId => {
          if (roomByIdsWithUsers[roomId].length === 0) {
            return false
          }
          if (roomByIdsWithUsers[roomId].length === 1) {
            // If there is only 1 user id is a 1:1
            // Only 1 room for this userId without any other user on it
            return roomByIdsWithUsers[roomId][0] === userIdsToCheck[0]
          }
          if (roomByIdsWithUsers[roomId].length > 1) {
            // all entries on the room match all invitees
            return roomByIdsWithUsers[roomId].every(userId => userIdsToCheck.includes(userId))
          }
          return false
        })
        .flat()

      if (foundRoomForUser) {
        return foundRoomForUser
      }
      return null
    },
    enabled: !isLoading && userIds.length > 0
  })
}

export const useGetDmRoomBetweenUsers = () => {
  const { data: accountDataEvents, isLoading } = useAccountDataEvents()
  const directEvent = accountDataEvents?.find(event => event.type === EventType.Direct)
  const mDirectEvent = useMemo(() => directEvent?.content ?? {}, [directEvent])

  // Check for a private room for X users
  const getDmRoomBetweenUsers = useCallback(
    (userIds: string[]) => {
      // Check if can create room if it doesn't exist between users

      const roomByIdsWithUsers = dmUsersOnRoomByIdsSelector(mDirectEvent)
      console.log('LOG: > useGetDmRoomBetweenUsers > roomByIdsWithUsers:', roomByIdsWithUsers)

      // Example: user id or invitees //  g51hft6 - @g51hft6:vatom.com
      const userIdsToCheck = areMatrixUsers(userIds) ? userIds : getMatrixUsers(userIds)
      console.log('LOG: > useGetDmRoomBetweenUsers > userIdsToCheck:', userIdsToCheck)

      const [foundRoomForUser] = Object.keys(roomByIdsWithUsers)
        .filter(roomId => {
          if (roomByIdsWithUsers[roomId].length === 0) {
            return false
          }
          if (roomByIdsWithUsers[roomId].length === 1) {
            // If there is only 1 user id is a 1:1
            // Only 1 room for this userId without any other user on it
            return roomByIdsWithUsers[roomId][0] === userIdsToCheck[0]
          }
          if (roomByIdsWithUsers[roomId].length > 1) {
            // all entries on the room match all invitees
            return roomByIdsWithUsers[roomId].every(userId => userIdsToCheck.includes(userId))
          }
          return false
        })
        .flat()

      if (foundRoomForUser) {
        return foundRoomForUser
      }
      return null
    },
    [mDirectEvent]
  )

  return {
    getDmRoomBetweenUsers
  }
}

export type FetchReactions = {
  chunk: IEvent[]
}

type MessageReactionsQueryContext = QueryFunctionContext<
  ReturnType<typeof dmQueryKeys.getDmMessageReactions>
>

export const fetchMessageReactions = async ({
  queryKey: [{ roomId, eventId }],
  meta
}: MessageReactionsQueryContext) => {
  const { accessToken } = meta ?? {}
  return axios
    .get(
      `${matrixServerUrl}/_matrix/client/v1/rooms/${roomId}/relations/${eventId}/m.annotation?access_token=${accessToken}`
    )
    .then(({ data }) => data)
}

type DmReactionsQueryOptions<T> = Omit<
  UseQueryOptions<FetchReactions, unknown, T, ReturnType<typeof dmQueryKeys.getDmMessageReactions>>,
  'queryKey' | 'queryFn' | 'meta'
>

export const useDmReactions = <T = MessageReaction[]>(
  { roomId, eventId }: { roomId: string; eventId: string },
  options: DmReactionsQueryOptions<T> = {}
) => {
  const { data: matrixUser } = useMatrixUser()
  const isLocalEvent = eventId.startsWith(TEMP_EVENT_PREFIX)
  return useQuery({
    queryKey: dmQueryKeys.getDmMessageReactions(roomId, eventId),
    queryFn: fetchMessageReactions,
    enabled: !!matrixUser?.access_token && eventId !== '' && !!roomId && !isLocalEvent,
    select: data => {
      if (!data?.chunk) {
        return [] as T
      }

      const reactions = data.chunk
        .filter(event => !('redacted_because' in event))
        .map((reaction: IEvent) => ({
          sender: reaction.sender,
          eventId: reaction.event_id,
          key: reaction.content['m.relates_to']?.key ?? ''
        }))
      return reactions as T
    },
    meta: {
      accessToken: matrixUser?.access_token ?? ''
    } as const,
    ...options
  })
}

// ************************
//      ROOM EVENT
// *************************

type RoomEventQueryContext = QueryFunctionContext<ReturnType<typeof dmQueryKeys.getDmRoomEvent>>
const getRoomEvent = async ({ queryKey: [{ roomId, eventId }], meta }: RoomEventQueryContext) => {
  const { accessToken } = meta ?? {}
  const res = await axios
    .get(
      `${matrixServerUrl}/_matrix/client/v3/rooms/${roomId}/event/${eventId}?access_token=${accessToken}`
    )
    .then(({ data }) => data)
  return res
}

type RoomEventOptions<T> = Omit<
  UseQueryOptions<IEvent, unknown, T, ReturnType<typeof dmQueryKeys.getDmRoomEvent>>,
  'queryKey' | 'queryFn' | 'meta'
>
export const useRoomEvent = <T = IEvent>(
  { roomId, eventId }: { roomId: string; eventId: string },
  options: RoomEventOptions<T> = {}
) => {
  const { data: matrixUser } = useMatrixUser()
  return useQuery({
    queryKey: dmQueryKeys.getDmRoomEvent(roomId, eventId),
    queryFn: getRoomEvent,
    enabled: !!matrixUser?.access_token && options?.enabled,
    meta: {
      accessToken: matrixUser?.access_token ?? ''
    } as const,
    ...options
  })
}
