export const allSettled = <T>(promises: Array<Promise<T>>) =>
  Promise.all(
    promises.map(p =>
      p
        .then(value => ({
          status: 'fulfilled' as const,
          value
        }))
        .catch(reason => ({
          status: 'rejected' as const,
          reason
        }))
    )
  )

export const isFulfilled = <T>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> =>
  p.status === 'fulfilled'
export const isRejected = <T>(p: PromiseSettledResult<T>): p is PromiseRejectedResult =>
  p.status === 'rejected'
