import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Platform } from 'react-native'
import RNCallKeep from 'react-native-callkeep'
import DeviceInfo from 'react-native-device-info'
import InCallManager from 'react-native-incall-manager'
import type { ReactNativeFirebase } from '@react-native-firebase/app'
import { useNavigation } from '@react-navigation/native'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { PublicProfileSnapshot, SpaceType } from '@vatom/sdk/core'
import * as uuid from 'uuid'
import { create } from 'zustand'

import { getFirebaseApp, useAccessToken, useFirebase, useUser } from '../hooks'
import logger from '../logger'
import { useSDK } from '../store'

import { getUsersCollection } from './firestore'
import SpaceSessionManager, { ConnectionStatus } from './SpaceSessionManager'

export { ConnectionStatus } from './SpaceSessionManager'

const initialSpaceStoreState = {
  connectionStatus: 0,
  numberUsers: 0,
  space: null,
  activeSession: null
}

// TODO: state and actions can be separated
export const useSpaceStore = create<{
  connectionStatus: number
  numberUsers: number
  space: SpaceType | null
  setConnectionStatus: (status: number) => void
  setNumberUsers: (status: number) => void
  setSpace: (space: SpaceType) => void
  removeSpace: () => void
  activeSession: SpaceSessionManager | null
  setActiveSession: (session: SpaceSessionManager) => void
  removeActiveSession: () => void
}>(set => ({
  ...initialSpaceStoreState,
  setConnectionStatus: (status: number) => set(state => ({ ...state, connectionStatus: status })),
  setNumberUsers: (users: number) => set(state => ({ ...state, numberUsers: users })),
  setSpace: (space: SpaceType) => set(state => ({ ...state, space })),
  removeSpace: () => set(state => ({ ...state, space: null })),
  setActiveSession: (session: SpaceSessionManager) =>
    set(state => ({ ...state, activeSession: session })),
  removeActiveSession: () => set(state => ({ ...state, activeSession: null }))
}))

const initialCallStoreState = {
  callId: '',
  isMuted: true,
  inSpeaker: false
}

// TODO: state and actions can be separated
const useCallStore = create<{
  callId: string
  isMuted: boolean
  inSpeaker: boolean
  setCallId: (id: string) => void
  removeCallId: () => void
  setIsMuted: (mute: boolean) => void
  setInSpeaker: (speaker: boolean) => void
}>(set => ({
  ...initialCallStoreState,
  setCallId: (id: string) => set(state => ({ ...state, callId: id })),
  removeCallId: () => set(state => ({ ...state, callId: '' })),
  setIsMuted: (mute: boolean) => set(state => ({ ...state, isMuted: mute })),
  setInSpeaker: (speaker: boolean) => set(state => ({ ...state, inSpeaker: speaker }))
}))

const connectionText = {
  [ConnectionStatus.Connecting]: 'Connecting',
  [ConnectionStatus.Connected]: 'Connected',
  [ConnectionStatus.Error]: 'Connection failed',
  [ConnectionStatus.Ended]: 'Call ended'
}

export function getConnectStatusText(status: number) {
  return connectionText[status as ConnectionStatus]
}

const options = {
  ios: {
    imageName: 'IconMask',
    appName: 'Vatom',
    supportsVideo: false
  },
  android: {
    alertTitle: 'Permissions required',
    alertDescription: 'This application needs to access your phone accounts',
    cancelButton: 'Cancel',
    okButton: 'ok',
    imageName: 'phone_account_icon',
    // additionalPermissions: [PermissionsAndroid.PERMISSIONS.example],
    additionalPermissions: [],
    // Required to get audio in background when using Android 11
    foregroundService: {
      channelId: 'com.vatom', // TODO: change this
      channelName: 'Space Audio', // TODO: change this
      notificationTitle: 'Vatom is running on background' // TODO: change this
      // notificationIcon: '' // TODO: change this
    }
  }
}
if (Platform.OS !== 'web') {
  RNCallKeep.setup(options)
}
if (Platform.OS === 'android') {
  RNCallKeep.setAvailable(true) // for android
}

const setMuteCall = (muted: boolean) => {
  try {
    const session = useSpaceStore.getState().activeSession
    if (session) {
      session.muted = muted
      useSpaceStore.getState().setActiveSession(session)
      useCallStore.getState().setIsMuted(muted)
    }
  } catch (error) {
    console.error('setMuteCall.error:', error)
  }
}

const setSpeakerCall = async (speaker: boolean) => {
  try {
    if (speaker) {
      useCallStore.getState().setInSpeaker(true)
      InCallManager.setForceSpeakerphoneOn(true)
    } else {
      InCallManager.setForceSpeakerphoneOn(false)
      useCallStore.getState().setInSpeaker(false)
    }
  } catch (error) {
    console.error('setSpeakerCall.error:', error)
  }
}

const handleSpeakerChange = async (output: 'receiver' | 'speaker') => {
  if (output !== 'receiver' && output !== 'speaker') {
    return
  }
  const inSpeaker = useCallStore.getState().inSpeaker
  if (output === 'receiver' && inSpeaker === false) {
    return
  }
  if (output === 'receiver' && inSpeaker === true) {
    useCallStore.getState().setInSpeaker(false)
    return
  }
  if (output === 'speaker' && inSpeaker === true) {
    return
  }
  if (output === 'speaker' && inSpeaker === false) {
    useCallStore.getState().setInSpeaker(true)
    return
  }
}

const setEndCall = async () => {
  try {
    const session = useSpaceStore.getState().activeSession
    useSpaceStore.getState().removeSpace()
    useCallStore.setState(initialCallStoreState)
    if (session === null) {
      return
    }

    await session.end()
    useSpaceStore.getState().removeActiveSession()
  } catch (error) {
    console.error('setEndCall.error:', error)
    useSpaceStore.setState(initialSpaceStoreState)
  }
}

const useCallKeep = () => {
  const navigation = useNavigation()
  const didLoadWithEvents = useCallback((args?: any) => {
    // console.log('LOG: > didLoadWithEvents:', args)
  }, [])
  const didDisplayIncomingCall = useCallback((args?: any) => {
    // console.log('LOG: > didDisplayIncomingCall:', args)
  }, [])
  const answerCall = useCallback((args?: any) => {
    // console.log('LOG: > answerCall:', args)
  }, [])
  const didPerformDTMFAction = useCallback((args?: any) => {
    // console.log('LOG: > didPerformDTMFAction:', args)
  }, [])
  const didReceiveStartCallAction = useCallback((args?: any) => {
    // console.log('LOG: > didReceiveStartCallAction:', args)
  }, [])
  const didPerformSetMutedCallAction = useCallback(
    (args?: { callUUID: string; muted: boolean }) => {
      const { muted } = args || {}
      if (typeof muted === 'boolean') {
        setMuteCall(muted)
      }
    },
    []
  )
  const didToggleHoldCallAction = useCallback((args?: any) => {
    // console.log('LOG: > didToggleHoldCallAction:', args)
  }, [])

  const endCall = useCallback(
    async (args?: { callUUID: string }) => {
      // TODO: make dev only
      if (DeviceInfo.isEmulatorSync()) {
        return
      }
      if (Platform.OS === 'android') {
        RNCallKeep.backToForeground() // for android
      }

      // Reset states
      await setEndCall()
      // TODO: go back if screen is audio space
      if (navigation?.canGoBack()) {
        navigation?.goBack()
      }
    },
    [navigation]
  )

  const didChangeAudioRoute = useCallback(async (args?: { output?: string; reason?: number }) => {
    console.log('LOG: > didChangeAudioRoute:', args)
    const { output } = args || {}
    // {"output": "Receiver", "reason": 3}
    // {"output": "Speaker", "reason": 4}
    if (output) {
      handleSpeakerChange(output.toLowerCase() as 'receiver' | 'speaker')
    }
  }, [])
  const didActivateAudioSession = useCallback((args?: any) => {
    // console.log('LOG: > didActivateAudioSession:', args)
  }, [])

  useEffect(() => {
    RNCallKeep.addEventListener('didLoadWithEvents', didLoadWithEvents)
    RNCallKeep.addEventListener('didDisplayIncomingCall', didDisplayIncomingCall)
    RNCallKeep.addEventListener('answerCall', answerCall)
    RNCallKeep.addEventListener('didPerformDTMFAction', didPerformDTMFAction) //numpad
    RNCallKeep.addEventListener('didReceiveStartCallAction', didReceiveStartCallAction)
    RNCallKeep.addEventListener('didPerformSetMutedCallAction', didPerformSetMutedCallAction)
    RNCallKeep.addEventListener('didToggleHoldCallAction', didToggleHoldCallAction)
    RNCallKeep.addEventListener('didChangeAudioRoute', didChangeAudioRoute)
    RNCallKeep.addEventListener('endCall', endCall)
    RNCallKeep.addEventListener('didActivateAudioSession', didActivateAudioSession)
    return () => {
      RNCallKeep.removeEventListener('didLoadWithEvents')
      RNCallKeep.removeEventListener('didDisplayIncomingCall')
      RNCallKeep.removeEventListener('answerCall')
      RNCallKeep.removeEventListener('didPerformDTMFAction')
      RNCallKeep.removeEventListener('didReceiveStartCallAction')
      RNCallKeep.removeEventListener('didPerformSetMutedCallAction')
      RNCallKeep.removeEventListener('didToggleHoldCallAction')
      RNCallKeep.removeEventListener('didChangeAudioRoute')
      RNCallKeep.removeEventListener('endCall')
      RNCallKeep.removeEventListener('didActivateAudioSession')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const getNewUuid = () => uuid.v4().toLowerCase()

const callKeep = Platform.OS !== 'web' ? useCallKeep : null

export const SpaceMonitor = () => {
  useFirebase()
  callKeep?.()
  const sessionRef = useRef(useSpaceStore.getState().activeSession)

  useEffect(() => {
    useSpaceStore.subscribe(state => (sessionRef.current = state.activeSession))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      monitorSession(sessionRef.current)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return null
}

const monitorSession = (session: SpaceSessionManager | null) => {
  if (session === null) {
    return
  }

  session.update()

  if (session.status !== useSpaceStore.getState().connectionStatus) {
    useSpaceStore.getState().setConnectionStatus(session.status)
  }
  if (session.listUsers().length !== useSpaceStore.getState().numberUsers) {
    useSpaceStore.getState().setNumberUsers(session.listUsers().length)
  }
}

const initializeCallKeep = (space: SpaceType) => {
  try {
    const callId = getNewUuid()
    const contactNumber = space.displayName.toLowerCase()
    const contactDisplay = `@${space.alias}`

    // Save callId
    useCallStore.getState().setCallId(callId)
    RNCallKeep.startCall(callId, contactNumber, contactDisplay)
    RNCallKeep.setCurrentCallActive(callId)
    RNCallKeep.answerIncomingCall(callId)
    // Initialize call muted
    setOnMute(true, callId)

    return callId
  } catch (error) {
    console.error('initializeCallKeep.error:', error)
    return
  }
}

export async function finishSpaceAudioCall(_callId?: string) {
  try {
    if (Platform.OS !== 'web') {
      InCallManager.stop()

      const callId = _callId || useCallStore.getState().callId
      if (callId) {
        RNCallKeep.endCall(callId)
        if (DeviceInfo.isEmulatorSync()) {
          await setEndCall()
        }
        return
      }
    }
    await setEndCall()
  } catch (error) {
    logger.warn(`[Space] Call failed to END: ${(error as Error).message}`)
  }
}

const setOnMute = (muted: boolean, _callId?: string) => {
  if (Platform.OS === 'web') {
    setMuteCall(muted)
    return
  }
  const callId = _callId ?? useCallStore.getState().callId
  RNCallKeep.setMutedCall(callId, muted)
}

function toggleMute() {
  try {
    const { isMuted } = useCallStore.getState()
    setOnMute(!isMuted)
  } catch (error) {
    console.error('SpaceAudio.toggleMute.error:', error)
  }
}

async function toggleSpeaker() {
  if (Platform.OS === 'web') return

  const speaker = useCallStore.getState().inSpeaker
  await setSpeakerCall(!speaker)
}

export const SpaceAudioCallActions = {
  toggleMute,
  toggleSpeaker,
  endCall: finishSpaceAudioCall
}

async function startSpaceAudioCall(
  space: SpaceType,
  deps: {
    firebaseApp: ReactNativeFirebase.FirebaseApp
    userId: string
    accessToken: string
  }
) {
  try {
    if (!space) {
      throw new Error(`Space not provided.`)
    }
    if (!deps.firebaseApp) {
      throw new Error(`Firebase app not initialized.`)
    }
    if (!deps.userId) {
      throw new Error(`User not provided.`)
    }
    if (!deps.accessToken) {
      throw new Error(`Access token not found, please try login again.`)
    }
    logger.info(`[Space] Starting call to space: id=${space.id} user=${deps.userId}`)
    // Set Space
    useSpaceStore.getState().setSpace(space)

    const session = new SpaceSessionManager(space, deps.userId, deps.accessToken, deps.firebaseApp)
    useSpaceStore.getState().setActiveSession(session)
    await session.start()

    if (Platform.OS !== 'web') {
      InCallManager.start({ media: 'audio', auto: false })
      InCallManager.setKeepScreenOn(false)
      initializeCallKeep(space)
    }

    return session
  } catch (error) {
    console.error('startSpaceAudioCall.error:', error)
    useSpaceStore.getState().setConnectionStatus(ConnectionStatus.Error)
    logger.warn(`[Space] Call failed to START: ${(error as Error).message}`)
    await finishSpaceAudioCall()
    return
  }
}

export function getSpaceAudioCallBackgroundColor(status: number) {
  if (status === ConnectionStatus.Connecting) return '#2a4357'
  if (status === ConnectionStatus.Connected) return '#365e31'
  if (status === ConnectionStatus.Error) return '#522522'
  if (status === ConnectionStatus.Ended) return '#3b3b3b'
  return 'rgba(0,0,0,0.5)'
}

export const useSpaceAudioCallColor = () => {
  const { connectionStatus } = useSpaceAudioCallState()
  const callBackgroundColor = useMemo(
    () => getSpaceAudioCallBackgroundColor(connectionStatus),
    [connectionStatus]
  )
  return callBackgroundColor
}

export const useSpaceAudioCallState = () => {
  const { isMuted, inSpeaker, callId } = useCallStore()
  const { space, connectionStatus, activeSession } = useSpaceStore()

  const isInCall = useMemo(() => {
    return connectionStatus === ConnectionStatus.Connected
  }, [connectionStatus])

  const hasCurrentSession = useMemo(() => Boolean(activeSession !== null), [activeSession])

  return {
    isMuted,
    inSpeaker,
    callId,
    space,
    connectionStatus,
    hasCurrentSession,
    isInCall
  }
}

export const useSpaceAudioCall = () => {
  const user = useUser()
  const sdk = useSDK()
  const accessToken = useAccessToken()

  const startCallMutation = useMutation({
    mutationFn: async (space: SpaceType) => {
      try {
        const firebaseApp = await getFirebaseApp(sdk)
        if (!user || !accessToken || !firebaseApp) {
          throw new Error('Space.StartCall error, missing deps')
        }

        return await startSpaceAudioCall(space, { userId: user.sub, accessToken, firebaseApp })
      } catch (error) {
        console.log('LOG: > mutationFn: > error:', error)
        return
      }
    }
  })

  return startCallMutation
}

export const useSpaceAttendees = () => {
  const { space } = useSpaceStore()
  const sdk = useSDK()
  // useSpacePresenceWebsocketSubscription(space?.id)

  //  const enabled = !!firebase.data && isEnabled && getFireBaseIsReady(firebase)

  const fetchPresence = useCallback(async () => {
    if (!space) return null
    const now = new Date()
    const presenceDate = new Date(now.setMinutes(now.getMinutes() - 1)).getTime()
    const usersCollection = getUsersCollection(space.id, presenceDate)

    const users = Platform.OS === 'web' ? await usersCollection : await usersCollection.get()

    const attendees: PublicProfileSnapshot[] = []
    if (users) {
      /* @ts-ignore */
      for (const userDoc of users.docs) {
        const userId = userDoc.id.split(':')[1]

        const user = await sdk.vatomIncApi.getPublicProfile(userId)

        if (user) {
          attendees.push(user)
        }
      }
    }
    return attendees
  }, [sdk.vatomIncApi, space])

  return useQuery({
    queryKey: ['space-presence'],
    queryFn: fetchPresence,
    refetchInterval: 5000
  })
}
