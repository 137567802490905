import { useCallback, useRef } from 'react'
import { Platform } from 'react-native'
import type { NavigationState } from '@react-navigation/native'
import { getPathFromState } from '@react-navigation/native'
import { useQueryClient } from '@tanstack/react-query'
import { businessQueryKeys, fetchBusiness, useAnalytics as useAnalyticsSDK } from '@vatom/sdk/react'

import { getActiveRoute, getAllParamsFromRoutes } from '../navigators'
import { AnalyticsEvents } from '../utils/AnalyticsEvents'

function getExtraDataWeb() {
  if (Platform.OS === 'web' && document && window) {
    return {
      title: document.title ?? '',
      location: window.location?.href ?? ''
    }
  }
  return {}
}

function getNavigationStateData(state: NavigationState) {
  const pathFromState = getPathFromState(state)
  const allParams = getAllParamsFromRoutes(state)
  const activeRoute = getActiveRoute(state)

  return {
    activeRoute,
    path: activeRoute?.path ?? pathFromState,
    params: allParams
  }
}

const useAnalytics = () => {
  const queryClient = useQueryClient()

  const analytics = useAnalyticsSDK()
  const lastRoute = useRef<Record<string, object | string | undefined> | null>(null)

  const getBusinessInfo = useCallback(async (businessId: string) => {
    return await queryClient.ensureQueryData({
      queryKey: businessQueryKeys.getBusiness(businessId, true),
      queryFn: fetchBusiness
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigationStateSubscriber = useCallback(async (state: NavigationState) => {
    const { activeRoute, path, params } = getNavigationStateData(state)

    const currentBusinessId = params?.business ?? null
    const businessInfo = currentBusinessId ? await getBusinessInfo(currentBusinessId) : null

    if (lastRoute.current?.key !== activeRoute?.key) {
      if (activeRoute?.name) {
        // track screen
        const extraDataWeb = getExtraDataWeb()
        const extraData = {
          businessId: businessInfo?.id ?? '',
          campaignId: businessInfo?.defaultCampaignId ?? '',
          path,
          params,
          ...extraDataWeb
        }

        trackScreen(activeRoute.name, { extraData })
      }
    }
    // @ts-ignore
    lastRoute.current = activeRoute
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trackScreen = (
    screenName: string,
    { extraData }: { extraData?: Record<string, any> } = {}
  ) => {
    try {
      if (!screenName) {
        throw new Error('Analytics.ScreenView: No screen name provided')
      }
      analytics.event(AnalyticsEvents.SCREEN_VIEW, { screen_name: screenName, ...extraData })
    } catch (error) {
      console.warn('Error: useAnalytics.trackScreen:', error)
      // log error
    }
  }

  return {
    analytics,
    navigationStateSubscriber
  }
}

export { useAnalytics }
