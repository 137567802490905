import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

const COOKIE_UID = 'uid'
const COOKIE_ANON_UID = 'anonymous_uid'

export class AnalyticsPersistenceManager {
  static setAnonymousId(id: string) {
    Cookies.set(COOKIE_ANON_UID, id)
  }

  static setUserId(id: string) {
    Cookies.set(COOKIE_UID, id)
  }

  static getUserId() {
    return Cookies.get(COOKIE_UID)
  }

  static setCampaignId(id: string) {
    Cookies.set(COOKIE_UID, id)
  }

  static getCampaignId() {
    return localStorage.getItem('campaign')
  }

  static getContext() {
    return {
      page: {
        referrer: document.referrer,
        path: window.location.pathname,
        search: window.location.search,
        url: window.location.href
      },
      userAgent: window.navigator.userAgent,
      locale: window.navigator.language
    }
  }

  static getOrigin() {
    return window.location.origin
  }

  static async getAnonymousId() {
    let anonymousId = Cookies.get(COOKIE_ANON_UID)
    // Set anonymous_uid if it doesn't exist
    if (!anonymousId) {
      anonymousId = uuidv4()
      Cookies.set(COOKIE_ANON_UID, anonymousId)
    }
    return anonymousId
  }
}
