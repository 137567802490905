/**
 * This class manages avatar audio in the P2P network via MediaStream sharing.
 *
 * It is available on `P2PAvatars.audio` if `P2PAvatars.customAudio == false`.
 */
export default class P2PAvatarBrowserAudio {
  /** @type {P2PAvatars} Reference to main class */
  main = null

  /** @type {AudioContext} The audio context to use. */
  // context = null

  /** If true, no audio is sent to remote peers */
  muted = true

  /** @type {MediaStreamAudioDestinationNode} The output audio node, to be sent to remote peers */
  // outputNode = null

  /** @type {AudioNode} Output audio node. If null, will use AudioContext.destination. */
  // destinationNode = null

  /** List of peers we are receiving audio from */
  incomingAudio = []

  /** If true, will spatialize remote user's audio in 3D space. If false, will just adjust volume only. */
  spatializeAudio = true

  /**
   * If true, will adjust the Audio Context's listener node based on our position. If false, will not touch the listener, but it'll be up to you to adjust it manually.
   * TODO: Not implemented yet
   */
  updateListenerPositions = false

  /** @type {MediaStream} The audio input stream to broadcast to remote peers */
  get microphoneStream() {
    return this._microphoneStream
  }
  set microphoneStream(v) {
    // Stop if unchanged
    if (v == this._microphoneStream) return
    this._microphoneStream = v

    // Update audio nodes
    this.onMicrophoneStreamUpdated()
  }

  /** Constructor */
  constructor(main) {
    // Store references
    this.main = main
  }

  /** Setup the audio input processor */
  async start() {
    // Only do once
    if (this._started) return
    this._started = true

    // Create audio context if needed
    // if (!this.context) {
    //     console.debug(`[P2PAvatarAudio] Creating new WebAudio context`)
    //     this.context = new AudioContext()
    // }

    // Keep position updates
    this.positionTimer = setInterval(this.updatePosition.bind(this), 1000 / 5)

    // Create output node
    // this.outputNode = this.context.createMediaStreamDestination()
    // this.onMicrophoneStreamUpdated()
  }

  /** Called on shutdown */
  stop() {
    // Remove audio nodes
    // this.outputNode?.disconnect()
    // this.outputNode = null
    // this.microphoneNode?.disconnect()
    // this.microphoneNode = null

    // Remove timer
    clearInterval(this.positionTimer)
  }

  /** @private Called constantly to keep the position updated */
  updatePosition() {
    // Go through all peers
    for (let peer of this.incomingAudio) peer.update()
  }

  /** @private Called when the host specifies a new stream */
  onMicrophoneStreamUpdated() {
    // Stop if not set up
    if (!this._started) return

    // Remove old node
    // this.microphoneNode?.disconnect()
    // this.microphoneNode = null

    // Check if null
    if (!this.microphoneStream) return

    // Create new input node
    // this.microphoneNode = this.context.createMediaStreamSource(this.microphoneStream)
    // this.microphoneNode.connect(this.outputNode)

    // this.microphoneNode = this.context.createOscillator()
    // this.microphoneNode.connect(this.outputNode)
    // this.microphoneNode.start()
  }

  /** @private Shut down and remove audio from a peer */
  removePeerAudio(connectionID) {
    // Find existing peer
    let peer = this.incomingAudio.find(p => p.connection.connectionID == connectionID)
    if (!peer) return

    // Shut it down
    peer.stop()
    this.incomingAudio = this.incomingAudio.filter(p => p != peer)
  }

  /** @private Receive audio stream from remote peer */
  receiveStreamFromPeer(connection, stream) {
    // Remove existing peer if any
    this.removePeerAudio(connection.connectionID)

    // Stop if we're a light bot, we don't need to play audio since no-one will hear it
    if (window.swAutomation?.light) return

    // Create new peer audio
    let peer = new PeerAudio(this, connection, stream)
    this.incomingAudio.push(peer)
  }
}

/** Represents a single peer's audio */
class PeerAudio {
  /** @type {P2PAvatarBrowserAudio} Reference to the main audio system */
  audio = null

  /** @type {P2PAvatar} The remote connection this audio is for */
  connection = null

  /** @type {MediaStream} The incoming audio stream for this peer */
  stream = null

  /** @type {MediaStreamAudioSourceNode} The audio source node, audio received from the remote peer */
  // inputNode = null

  /** @type {PannerNode} If we are spatializing the audio, this is the PannerNode which does that */
  // pannerNode = null

  /** @type {GainNode} If we are not spatializing the audio, this is the gain node we use to control the volume */
  // gainNode = null

  /** @type {HTMLAudioElement} Player to play the audio on Web */
  player = null

  /** The full volume radius of this user. If we are closer than this, this user will be fully loud. */
  fullVolumeRadius = 2

  /** The silence radius of this user. If we are further than this, this user cannot be heard. */
  silenceRadius = 10

  /** Constructor */
  constructor(audio, connection, stream) {
    // Store vars
    this.audio = audio
    this.connection = connection
    this.stream = stream

    // Listen for stream end
    stream
      .getAudioTracks()[0]
      .addEventListener('ended', () => audio.removePeerAudio(connection.connectionID))

    // Stop if not on Web ... on Native the WebRTC streams are played automatically
    if (typeof window == 'undefined' || !window.Audio) return

    // Create player
    this.player = new Audio()
    this.player.srcObject = stream
    this.player.play()

    // Create incoming audio node
    // this.inputNode = audio.context.createMediaStreamSource(stream)

    // Check if we are spatializing
    // if (audio.spatializeAudio) {

    //     // Create spatializer node
    //     this.pannerNode = new PannerNode(audio.context, {
    //         distanceModel: 'linear',
    //         refDistance: 0.01,
    //         maxDistance: 0.02
    //     })
    //     this.inputNode.connect(this.pannerNode)
    //     this.pannerNode.connect(audio.destinationNode || audio.context.destination)

    // } else {

    //     // Create gain node
    //     this.gainNode = audio.context.createGain()
    //     this.inputNode.connect(this.gainNode)
    //     this.gainNode.connect(audio.destinationNode || audio.context.destination)
    //     this.gainNode.gain.value = 0

    // }

    // HACK: On Chrome audio doesn't play unless it's assigned to an audio element ... so create a dummy element
    // this.player = new Audio()
    // this.player.muted = true
    // this.player.srcObject = stream
  }

  /** Shut down this remote peer audio player */
  stop() {
    // Stop audio tracks
    for (let track of this.stream.getAudioTracks()) track.stop()

    // Shut down the player
    if (this.player) this.player.srcObject = null
    this.player = null

    // // Disconnect the audio nodes
    // this.inputNode?.disconnect()
    // this.pannerNode?.disconnect()
    // this.gainNode?.disconnect()
  }

  /** Update positions and volumes */
  update() {
    // Get desired hearing radius of this remote user
    this.silenceRadius = Math.max(0.01, this.connection.audioRadius)

    // We auto calculate the full volume radius, 25% of the silence radius
    this.fullVolumeRadius = this.silenceRadius * 0.25

    // Check how to update
    // eslint-disable-next-line no-constant-condition
    if (true) this.updateReactNative()
    // if (this.gainNode)
    //     this.updateGain()
    // else if (this.pannerNode)
    //     this.updatePanner()
  }

  /** Updates playability on the extremely limited React Native environment */
  updateReactNative() {
    // Calculate volume based on distance
    let ourX = this.audio.main.sharedMetadata.x || 0
    let ourY = this.audio.main.sharedMetadata.y || 0
    let ourZ = this.audio.main.sharedMetadata.z || 0
    let x = this.connection.metadata.x || 0
    let y = this.connection.metadata.y || 0
    let z = this.connection.metadata.z || 0
    let distance = Math.sqrt((ourX - x) ** 2 + (ourY - y) ** 2 + (ourZ - z) ** 2)

    // Check if in hearing radius and enable/disable the stream
    for (let track of this.stream.getAudioTracks()) track.enabled = distance <= this.silenceRadius
  }

  /** @private Updates the volume when not spatializing */
  // updateGain() {

  // // Calculate volume based on distance
  // let ourX = this.audio.main.sharedMetadata.x || 0
  // let ourY = this.audio.main.sharedMetadata.y || 0
  // let ourZ = this.audio.main.sharedMetadata.z || 0
  // let x = this.connection.metadata.x || 0
  // let y = this.connection.metadata.y || 0
  // let z = this.connection.metadata.z || 0
  // let distance = Math.sqrt((ourX - x)**2 + (ourY - y)**2 + (ourZ - z)**2)
  //     if (distance <= this.fullVolumeRadius) {

  //         // Full volume
  //         this.gainNode.gain.value = 1

  //     } else if (distance >= this.silenceRadius) {

  //         // Muted
  //         this.gainNode.gain.value = 0

  //     } else {

  //         // Somewhere in between
  //         let range = this.silenceRadius - this.fullVolumeRadius
  //         let factor = (distance - this.fullVolumeRadius) / range
  //         this.gainNode.gain.value = 1 - factor

  //     }

  // }

  // /** Updates the panner node */
  // updatePanner() {

  //     // Update position
  //     this.pannerNode.positionX.value = this.connection.metadata.x || 0
  //     this.pannerNode.positionY.value = this.connection.metadata.y || 0
  //     this.pannerNode.positionZ.value = this.connection.metadata.z || 0
  //     this.pannerNode.refDistance = this.fullVolumeRadius
  //     this.pannerNode.maxDistance = this.silenceRadius

  // }
}
