import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { applySnapshot, types } from 'mobx-state-tree'

import type { AnalyticsApi } from '../Analytics'
import { AnalyticsStore } from '../Analytics'
import type { PluginableProps } from '../BasePlugin'
import type { VatomIncApi } from '../BVatomInc'
import { VatomIncApiStore } from '../BVatomInc'
import type { DataPool } from '../DataPool'
import { composePluginableDataPoolStore } from '../DataPool'
import type { NftFilter } from '../NftFilter'
import { NftFilterStore } from '../NftFilter'
import type { ServiceStoreType } from '../Services/ServiceStore'
import { ServiceStore } from '../Services/ServiceStore'
import type { SpaceStoreType } from '../Spaces'
import { SpaceStore } from '../Spaces'
import type { WalletPreference } from '../WalletPreference'
import { WalletPreferenceStore } from '../WalletPreference'

const RootSDKStore = types
  .model('RootSDKStore')
  .props({
    analytics: types.optional(AnalyticsStore, {}),
    service: types.optional(ServiceStore, {}),
    vatomIncApi: types.optional(VatomIncApiStore, {}),
    walletPreference: types.optional(WalletPreferenceStore, {}),
    nftFilter: types.optional(NftFilterStore, {}),
    spaces: types.optional(SpaceStore, {})
  })
  .actions(self => ({
    clear() {
      applySnapshot(self, {})
    }
  }))
  .actions(self => {
    const afterCreate = () => {
      // constructor
    }
    const beforeDestroy = () => {
      // teardown
    }
    return { afterCreate, beforeDestroy }
  })

export interface RootSDKStoreType extends Instance<typeof RootSDKStore> {
  dataPool: DataPool
  walletPreference: WalletPreference
  nftFilter: NftFilter
  service: ServiceStoreType
  analytics: AnalyticsApi
  vatomIncApi: VatomIncApi
  spaces: SpaceStoreType
}
export type RootSDKStoreSnapshot = SnapshotOut<RootSDKStoreType>

export const composeRootSDKStore = (props: PluginableProps) => {
  // Onion Layer
  // ----------------------------
  // sdk RootSDKStore
  // dataPool
  // - plugins
  //  - regions
  //    - objects (Tokens that normalize to the NFT data)
  //      - faces (TBD)
  // - regions (composited regions, pointers into the plugins' regions)
  const dataPool = composePluginableDataPoolStore(props)
  const ComposedRootSDKStore = types.compose(RootSDKStore, types.model({ dataPool }))
  type ComposedRootSDKStore = Instance<typeof ComposedRootSDKStore>
  return ComposedRootSDKStore
}
