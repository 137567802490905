import { Platform } from 'react-native'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import * as FileSystem from 'expo-file-system'

import { useSDK } from '../../../SDKProvider'

import { matrixHost } from './api'
import { matrixQueryKeys, useMatrixUser } from './queries'
import type { MatrixData } from './types'

export const useJoinRoom = () => {
  const sdk = useSDK()
  const matrixUser = useMatrixUser()
  const queryClient = useQueryClient()
  const queryKey = matrixQueryKeys.getMatrixFullStateSync(matrixUser.data?.access_token)
  return useMutation({
    mutationFn: async (alias: string) => {
      try {
        if (!alias) {
          return true
        }
        const space = await sdk.vatomIncApi.getSpace(alias)
        if (space.matrixRoomId) {
          sdk.spaces.addUpdate(space)
          await fetch(`${matrixHost}/_matrix/client/v3/rooms/${space.matrixRoomId}/join`, {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${matrixUser.data?.access_token}`
            })
          })
        }
        return true
      } catch (error: any) {
        throw error?.response?.data?.error ?? error
      }
    },
    onSuccess: () => {
      // we don't really want to do this but the polling returns some funky data that thinks member is still joined/leaved
      queryClient.invalidateQueries({ queryKey })
    }
  })
}

export const useLeaveRoom = () => {
  const matrixUser = useMatrixUser()
  const queryClient = useQueryClient()
  const queryKey = matrixQueryKeys.getMatrixFullStateSync(matrixUser.data?.access_token)

  return useMutation({
    mutationFn: async (roomId: string) => {
      await fetch(`${matrixHost}/_matrix/client/v3/rooms/${roomId}/leave`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${matrixUser.data?.access_token}`
        })
      })
    },
    onMutate: async (roomId: string) => {
      queryClient.setQueryData<MatrixData>(queryKey, currentMatrixData => {
        if (currentMatrixData?.rooms.join[roomId]) {
          delete currentMatrixData?.rooms.join[roomId]
        }

        return currentMatrixData
      })

      return { roomId }
    },
    onSuccess: (_, _v, context) => {
      // we don't really want to do this but the polling returns some funky data that thinks member is still joined/leaved
      queryClient.invalidateQueries({ queryKey })
    }
  })
}

export const useForgetRoom = () => {
  const matrixUser = useMatrixUser()

  return useMutation({
    mutationFn: async (roomId: string) => {
      await fetch(`${matrixHost}/_matrix/client/v3/rooms/${roomId}/forget`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${matrixUser.data?.access_token}`
        })
      })
    }
  })
}

const uploadedMediaWeb = async (blob: ArrayBufferLike, accessToken?: string) => {
  try {
    const response = await axios
      .post(`${matrixHost}/_matrix/media/v3/upload?access_token=${accessToken}`, blob, {
        headers: {
          'Content-Type': 'image/jpeg'
        }
      })
      .then(data => data.data)
    return response as { content_uri: string }
  } catch (error) {
    console.error('matrix.mutation.uploadedMediaWeb', error)
    return undefined
  }
}

const uploadedMediaWithFileSystem = async (fileUri: string, accessToken?: string) => {
  try {
    const response = await FileSystem.uploadAsync(
      `${matrixHost}/_matrix/media/v3/upload?access_token=${accessToken}`,
      fileUri,
      {
        fieldName: 'file',
        httpMethod: 'POST',
        uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
        headers: {
          'Content-Type': 'image/jpeg'
        }
      }
    )
    return JSON.parse(response.body ?? '{}') as { content_uri: string }
  } catch (error) {
    console.error('matrix.mutation.uploadedMediaWithFileSystem', error)
    return undefined
  }
}
const isWeb = Platform.OS === 'web'
export const useUploadMediaMutation = () => {
  const { data: matrixUser } = useMatrixUser()
  return useMutation({
    mutationFn: async (file: string) => {
      if (isWeb) {
        const bufferFile = Buffer.from(file, 'base64')
        return await uploadedMediaWeb(bufferFile, matrixUser?.access_token)
      }
      return await uploadedMediaWithFileSystem(file, matrixUser?.access_token)
    }
  })
}
