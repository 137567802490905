import React from 'react'
import { Dimensions, Platform } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import type { IToastProps } from 'native-base'
import { Avatar, Box, HStack, Icon, IconButton, Text, Toast as T } from 'native-base'

import { IconsSVG } from './icons'

export const Status = {
  warning: 'warning',
  success: 'success',
  info: 'info',
  error: 'error'
} as const
export type StatusType = keyof typeof Status

const StatusIcons = {
  [Status.warning]: IconsSVG.Warning,
  [Status.success]: IconsSVG.Success,
  [Status.info]: IconsSVG.Info,
  [Status.error]: IconsSVG.Error
}

export interface ToastProps extends IToastProps {
  title?: string
  status?: StatusType
  avatar?: string
  duration?: number
  withMargin?: boolean
  TitleComponent?: React.FC
}

const TOAST_INSET = 50

export const Toast = ({
  title,
  status,
  duration,
  placement,
  avatar,
  withMargin = false,
  TitleComponent,
  ...rest
}: ToastProps) => {
  const offsetTopWeb = placement === 'top' || placement === undefined ? TOAST_INSET : 0
  const toastWidth = Dimensions.get('window').width - (withMargin ? 32 : 0)
  const StatusIcon = status && StatusIcons[status]
  const toast = () => (
    <Box
      shadow={1}
      backgroundColor={'#11141E'}
      p={4}
      rounded={4}
      w={toastWidth}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      _web={{
        top: -offsetTopWeb // Reset the top prop from the library for web
      }}
    >
      <HStack alignItems={'center'}>
        {StatusIcon && (
          <Box mr={2}>
            <StatusIcon width={16} height={16} />
          </Box>
        )}
        {avatar ? <Avatar source={{ uri: avatar }} size={6} mr={2} /> : null}
      </HStack>
      <Box flexGrow={1} flexShrink={0} flexBasis={'1'}>
        {title ? (
          <Text fontWeight="semibold" color={'#D9D9DB'} noOfLines={2} ellipsizeMode="tail">
            {title}
          </Text>
        ) : null}
        {TitleComponent ? <TitleComponent /> : null}
      </Box>
      <Box ml={2}>
        <IconButton
          onPress={() => T.closeAll()}
          size={3}
          w={3}
          hitSlop={20}
          icon={<Icon as={AntDesign} name="close" color={'#B3B4B7'} />}
        />
      </Box>
    </Box>
  )

  T.show({
    duration,
    placement: placement ?? 'top',
    render: toast,
    ...rest
  })
}
