import P2PAvatar from './P2PAvatar'
import P2PAvatarBrowserAudio from './P2PAvatarBrowserAudio'
import P2PAvatarWorker from './P2PAvatars.worker'
// import { Worker } from 'react-native-workers'

/**
 * This class handles connecting to other users via P2P and establishing an audio and data channel
 * between users.
 *
 * @abstract
 */
export default class P2PAvatars {
  /** The group ID (space ID) to connect to */
  groupID = ''

  /** The user ID of the current user */
  userID = ''

  /** @type {P2PAvatars} Current (last started) manager. */
  static current = null

  /** Maximum number of connections */
  maxConnections = 32

  /** Maximum number of connections in the connecting state */
  maxPendingConnections = 10

  /** Number of extra incoming connections to support */
  get maxIncomingConnections() {
    return this.maxConnections / 2
  }

  /** Distance at which to throttle realtime data updates */
  realtimeDataThrottleDistance = 20

  /** Timeout duration when connecting to a peer */
  connectionTimeout = 10000

  /** @type {P2PAvatar[]} List of outgoing P2P connections */
  connections = []

  /** Shared data fields. Other user metadata will be available at P2PAvatar.metadata. */
  sharedMetadata = {
    // Default position
    x: 0,
    y: 0,
    z: 0,

    // Default orientation quternion
    ox: 0,
    oy: 0,
    oz: 0,
    ow: 1
  }

  /** List of connection IDs that have been used */
  connectionIDs = []

  /** List of known peers. */
  knownPeers = []

  /** Wether we should use the custom audio system or not. */
  _customAudio = false
  get customAudio() {
    return this._customAudio
  }
  set customAudio(v) {
    // Stop if unchanged
    if (this._customAudio == v) return

    // Stop if system is already started
    if (this.isStarted)
      throw new Error(
        'Unable to change audio subsystem type while running. Please change it before calling start().'
      )

    // Warning, custom audio is removed for now to save bundle size, since we're not using it in the main app
    if (v) throw new Error('Custom audio support has been removed.')

    // Set new audio manager
    let prevContext = this.audio.context
    let prevDestination = this.audio.destinationNode
    this._customAudio = v
    this.audio = v ? null /*new P2PAvatarAudio(this)*/ : new P2PAvatarBrowserAudio(this)
    this.audio.context = prevContext
    this.audio.destinationNode = prevDestination
  }

  /** @type {P2PAvatarBrowserAudio} Audio system manager */
  audio = new P2PAvatarBrowserAudio(this)

  /** @type {Worker} A WebWorker which handles all of the heavy operations */
  // worker = null

  /** @type {P2PAvatarWorker} Fake worker, since RN we can't use workers */
  worker = null

  /** @type {any[]} A list of messages to be send to the Worker once it's loaded */
  workerPendingMessages = []
  workerIsLoaded = false

  /** If true, no audio will be transmitted. */
  noAudio = false

  /**
   * Create and start connecting to avatars.
   *
   * @param {string} groupID The group ID of users to connect to. This is the same as the Space ID.
   * @param {string} userID The user ID of the current user.
   * @param {boolean} noAudio If true, avatars will act in data-only mode, and not transmit any audio.
   */
  constructor(groupID, userID, noAudio) {
    this.groupID = groupID
    this.userID = userID
    this.noAudio = noAudio
  }

  /** Sets the user's current position */
  setPosition(x, y, z) {
    this.sharedMetadata.x = x || 0
    this.sharedMetadata.y = y || 0
    this.sharedMetadata.z = z || 0
    this.setMetadata()
  }

  /** Sets the user's current orientation as a quaternion */
  setOrientation(x, y, z, w) {
    this.sharedMetadata.ox = x || 0
    this.sharedMetadata.oy = y || 0
    this.sharedMetadata.oz = z || 0
    this.sharedMetadata.ow = w || 0
    this.setMetadata()
  }

  /**
   * Notify that a peer has been discovered.
   *
   * @returns The Peer object for this peer.
   */
  peerDiscovered(userID, x, y, z) {
    // Stop if it's blank or our userID
    if (!userID || userID == this.userID) return

    // Get existing peer
    let peer = this.knownPeers.find(p => p.userID == userID)
    if (!peer) {
      // Create new peer
      peer = { userID, x, y, z, distance: -1, retries: 0 }
      this.knownPeers.push(peer)
    }

    // Update values
    peer.retries = 0 // <-- We've seen this one again, so let it connect again
    peer.lastSeen = Date.now()

    // Update position if provided
    if (x || y || z) {
      // Store position
      peer.x = x || 0
      peer.y = y || 0
      peer.z = z || 0

      // Calculate distance
      let ourX = this.sharedMetadata.x || 0
      let ourY = this.sharedMetadata.y || 0
      let ourZ = this.sharedMetadata.z || 0
      peer.distance =
        Math.sqrt((ourX - peer.x) ** 2 + (ourY - peer.y) ** 2 + (ourZ - peer.z) ** 2) || 0
    }

    // Return peer
    return peer
  }

  /** Create a new P2PAvatar class for the specified user. Subclass can override this to provide it's own P2PAvatar subclass. */
  createAvatarInstance() {
    return new P2PAvatar()
  }

  /**
   * Sends a signal to a remote user. The remote user's instance must call onSignal() in response to this.
   * It is up to the subclass to handle how the signal gets distributed to the remote user.
   *
   * @param {string} userID The user to send the signal to.
   * @param {any} data The signal data to pass. This is a JSON-ifyable object.
   */
  sendSignal(userID, data) {
    throw new Error('Abstract function. Subclass should implement this.')
  }

  /**
   * Call this when a signal for the current user is received.
   *
   * @param {string} fromUserID The User ID of the user who sent this signal.
   * @param {any} data The data passed from the remote user.
   */
  onSignal(fromUserID, signal) {
    // Check for a connection
    let connection = this.connections.find(
      c => c.connectionID == signal.connectionID && c.userID == fromUserID
    )
    if (!connection) {
      // Notify peer discovered
      let peer = this.peerDiscovered(fromUserID, signal.x, signal.y, signal.z)

      // Stop if already used
      if (this.connectionIDs.includes(signal.connectionID)) return

      // Sort peers by distance
      this.knownPeers.sort((a, b) => a.distance - b.distance)

      // Stop if this connection is unwanted
      let idx = this.knownPeers.indexOf(peer)
      if (idx == -1 || idx > this.maxConnections) {
        console.debug(
          `[P2PAvatars] Ignored incoming connection since it's outside our wanted range: user=${fromUserID}`
        )
        return
      }

      // Stop if there's already a connection to this user. We are only considering outgoing connections if they're fully connected,
      // but if we already have an incoming connection then we'll prioritise that one over this new one even if it's not connected fully.
      // let existingOutgoingConnection = this.connections.find(c => c.userID == fromUserID && c.isInitiator && c.isConnected)
      // let existingIncomingConnection = this.connections.find(c => c.userID == fromUserID && !c.isInitiator)
      // let existingConnection = existingOutgoingConnection || existingIncomingConnection
      // if (existingConnection) {
      //     console.warn(`[P2PAvatars] Ignored incoming connection attempt from user ${fromUserID}, we've already got an ${existingConnection.isInitiator ? 'outgoing' : 'incoming'} one for this user.`)
      //     return
      // }

      // Stop if there are too many incoming connections
      // let incomingCount = this.connections.reduce((prev, conn) => prev + (!conn.isConnected && !conn.isInitiator ? 0 : 1), 0)
      // if (incomingCount >= this.maxIncomingConnections)
      //     return

      // No connection! This is an incoming connection request in this case.
      // Check if too many connections already
      // let ourX = this.sharedMetadata.x || 0
      // let ourY = this.sharedMetadata.y || 0
      // let ourZ = this.sharedMetadata.z || 0
      // let x = signal.x ?? 99999
      // let y = signal.y ?? 99999
      // let z = signal.z ?? 99999
      // let distance = Math.sqrt((ourX - x)**2 + (ourY - y)**2 + (ourZ - z)**2)
      // let furthestConnection = findLast(this.connections, c => c.isConnected)
      // if (this.connections.length >= this.maxConnections + this.maxPendingConnections && furthestConnection && distance < furthestConnection.distance) {

      //     // Too many connections, but this incoming connection is higher priority
      //     console.debug("[P2PAvatars] Maximum connection limit reached, but incoming signal is higher priority. Closing our furthest connection.")
      //     furthestConnection.close()

      // } else if (this.connections.length >= this.maxConnections + this.maxPendingConnections) {

      //     // Too many connections and the new one is too far away, ignore it
      //     console.warn("[P2PAvatars] Maximum connection limit reached, incoming signal ignored due to being too far away.")
      //     return

      // }

      // Create new connection
      connection = this.createAvatarInstance()
      connection.manager = this
      connection.userID = fromUserID
      connection.distance = peer.distance
      this.connections.push(connection)
      this.connectionIDs.push(signal.connectionID)

      // Start connection
      connection.start(false, signal.connectionID)
    }

    // Pass signal
    connection.onSignal(signal)
  }

  /** Start the connections. After starting, this instance will be accessible via `P2PAvatars.current`. */
  start() {
    // If this instance has been stopped, it can't be used again. A new instance must be created.
    if (this.isStopped)
      throw new Error('Cannot reuse P2PAvatars. Please create a new instance instead.')

    // Only do once
    if (this.isStarted) return
    this.isStarted = true

    // Store it
    console.debug(`[P2PAvatars] Starting. group=${this.groupID} user=${this.userID}`)
    P2PAvatars.current = this

    // Create worker
    // this.worker = new Worker(new URL("./P2PAvatars.worker.js", import.meta.url))
    // this.worker.addEventListener('message', this.onWorkerMessage.bind(this))
    // this.workerPendingMessages = []
    // this.workerIsLoaded = false

    // Create fake worker ... this is really messy
    let listeners = []
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let main = this
    let customSelf = {
      listeners: {},

      // Called by worker
      addEventListener(name, func) {
        listeners[name] = listeners[name] || []
        listeners[name].push(func)
      },

      // Called by worker to post message to us
      postMessage(data) {
        main.onWorkerMessage({ data })
      }
    }
    this.workerPendingMessages = []
    this.workerIsLoaded = false
    this.worker = new P2PAvatarWorker(customSelf)
    this.worker.postMessage = function (data) {
      // Called by us to send a message to the worker
      for (let func of listeners['message'] || []) func({ data })
    }

    // Start audio subsystem
    if (!this.noAudio) this.audio.start()

    // Start connection timer
    this.connectionTimer = setInterval(this.onConnectionTimer.bind(this), 2000)
  }

  /** Shut down the avatar system and disconnect all peers. */
  stop() {
    // Only do once
    if (!this.isStarted) return
    this.isStarted = false
    this.isStopped = true

    // Remove it
    console.debug(`[P2PAvatars] Shutting down.`)
    if (P2PAvatars.current == this) P2PAvatars.current = null

    // Stop all peer connections
    for (let conn of this.connections) conn.close()

    // Kill worker
    this.workerIsLoaded = false
    this.worker?.terminate()
    this.worker = null

    // Stop timers
    clearInterval(this.connectionTimer)

    // Stop audio subsystem
    this.audio.stop()
  }

  /** Called when a message is received from the Worker */
  onWorkerMessage(e) {
    // Check message
    if (e.data.action == 'worker-loaded') {
      // Send all pending messages
      this.workerIsLoaded = true
      for (let msg of this.workerPendingMessages)
        this.worker.postMessage(msg.payload, msg.transferables)
      this.workerPendingMessages = []
    } else if (e.data.action == 'broadcast-packet') {
      // We have received an encoded packet we should pass on to remote peers
      let sendToFullPeers = e.data.sendTo == 'full' || e.data.sendTo == 'all'
      let sendToSlowPeers = e.data.sendTo == 'slow' || e.data.sendTo == 'all'
      for (let connection of this.connections) {
        // Skip if still connecting
        if (!connection.isConnected) continue

        // Check connection type
        let canSend =
          (connection.sendFullPackets && sendToFullPeers) ||
          (!connection.sendFullPackets && sendToSlowPeers)
        if (!canSend) continue

        // Send it
        connection.stats.tempBytesWritten += e.data.packet.byteLength
        connection.peer.write(e.data.packet)
      }
    } else if (e.data.action == 'metadata-in') {
      // We have decoded incoming metadata from a remote peer
      let connection = this.connections.find(c => c.connectionID == e.data.connectionID)
      if (!connection) return

      // Update metadata
      connection._onMetadataUpdate(e.data.metadata)
    }
  }

  /** Send a message to the Worker */
  postWorkerMessage(payload, transferables) {
    // Check if loaded
    if (this.workerIsLoaded) {
      // Just send immediately
      this.worker.postMessage(payload, transferables)
    } else {
      // Worker is still loading, queue the messages
      this.workerPendingMessages.push({ payload, transferables })
    }
  }

  /** Called every so often to manage connections */
  onConnectionTimer() {
    // Stop if not started
    if (!this.isStarted) return

    // Do updates
    let now = Date.now()

    // Remove timed out peers
    for (let conn of this.connections) {
      // Skip if connected
      if (conn.isConnected) continue

      // Skip if time is ok
      if (now - conn.connectionStartedAt < this.connectionTimeout) continue

      // Timed out!
      console.debug(`[P2PAvatar #${conn.index}] Connection failed, timed out...`)
      conn.close()
    }

    // Calculate distance from each connection
    let ourX = this.sharedMetadata.x || 0
    let ourY = this.sharedMetadata.y || 0
    let ourZ = this.sharedMetadata.z || 0
    for (let conn of this.connections) {
      // Stop if not connected
      if (!conn.isConnected || !conn.metadataReceived) continue

      // Calculate distance
      let x = conn.metadata.x || 0
      let y = conn.metadata.y || 0
      let z = conn.metadata.z || 0
      conn.distance = Math.sqrt((ourX - x) ** 2 + (ourY - y) ** 2 + (ourZ - z) ** 2)

      // Keep this peer active
      this.peerDiscovered(conn.userID, conn.metadata.x, conn.metadata.y, conn.metadata.z)

      // Check if space within lod 0
      if (conn.distance <= this.realtimeDataThrottleDistance) {
        // Allow streams
        conn.sendFullPackets = true
      } else {
        // Send slow data only
        conn.sendFullPackets = false
      }
    }

    // Sort connections by distance
    this.connections.sort((a, b) => a.distance - b.distance)

    // Close duplicate connections. This can happen because both peers see each other at the same time and start a connection to the other side.
    // In this case, we have four P2PAvatar instances in play, but we want to only close one of them. We will close the Initiator with the lowest
    // connection ID.
    for (let initiatorConnection of this.connections) {
      // Ignore if still connecting
      if (!initiatorConnection.isConnected) continue

      // Ignore if not initiator
      if (!initiatorConnection.isInitiator) continue

      // Find duplicate
      let incomingDuplicate = this.connections.find(
        c => c != initiatorConnection && c.userID == initiatorConnection.userID && !c.isInitiator
      )
      if (!incomingDuplicate) continue

      // Stop if the initiator is not sorted first
      if (initiatorConnection.connectionID.localeCompare(incomingDuplicate.connectionID) > 0)
        continue

      // Found a duplicate!
      console.debug(
        `[P2PAvatars] Closing duplicate connection: user=${initiatorConnection.userID} connection=${initiatorConnection.connectionID}`
      )
      initiatorConnection.close()
    }

    // Calculate distance from all known peers
    for (let i = 0; i < this.knownPeers.length; i++) {
      // Calculate distance
      let peer = this.knownPeers[i]
      peer.distance = Math.sqrt((ourX - peer.x) ** 2 + (ourY - peer.y) ** 2 + (ourZ - peer.z) ** 2)

      // Check if peer should be removed
      let inactive = now - peer.lastSeen > 120000
      let peerConnection = this.connections.find(
        c => c.userID == peer.userID && c.isConnected && c.metadataReceived
      )
      if (inactive && !peerConnection) {
        // Remove old peer
        this.knownPeers.splice(i--, 1)
        continue
      }

      // Update distance based on connected peer
      if (peerConnection) {
        peer.retries = 0
        peer.lastSeen = now
        peer.x = peerConnection.metadata.x || peer.x
        peer.y = peerConnection.metadata.y || peer.y
        peer.z = peerConnection.metadata.z || peer.z
        peer.distance = peerConnection.distance || peer.distance
      }
    }

    // Sort peers by distance
    this.knownPeers.sort((a, b) => a.distance - b.distance)

    // Get list of peers we want to be connected to
    let desiredPeers = this.knownPeers.slice(0, this.maxConnections)

    // Disconnect any peers outside of this list
    for (let connection of this.connections) {
      // Ensure it's in the list
      if (desiredPeers.find(p => p.userID == connection.userID)) continue

      // It's not! Shut it down
      console.debug(
        `[P2PAvatar #${connection.index}] Shutting down connection to ${
          connection.userID
        } since they are out of range now. distance=${
          connection.distance
        } index=${this.knownPeers.findIndex(p => p.userID == connection.userID)}`
      )
      connection.close()
    }

    // Connect to peers we want to be connected to
    let numConnecting = this.connections.reduce(
      (prev, current) => prev + (current.isConnected ? 0 : 1),
      0
    )
    for (let peer of desiredPeers) {
      // Check if already connected to this peer
      let peerConnection = this.connections.find(c => c.userID == peer.userID)
      if (peerConnection) continue

      // Stop if we've attempted to connect to this peer recently
      if (Date.now() - peer.lastConnectionTime < 10000) continue

      // Check if we are connecting to too many connections already
      if (numConnecting >= this.maxPendingConnections) break

      // We can connect to this user
      console.debug(
        `[P2PAvatars] Connecting to new peer: id=${peer.userID} retries=${
          peer.retries
        } distance=${Math.floor(peer.distance)}m`
      )
      let connection = this.createAvatarInstance()
      connection.manager = this
      connection.userID = peer.userID
      connection.distance = peer.distance || 0
      this.connections.push(connection)
      numConnecting += 1

      // Start the connection
      connection.start()
      peer.retries += 1
      peer.lastConnectionTime = Date.now()
    }
  }

  /** Update shared field */
  setMetadata(fields) {
    // Update fields
    if (fields) Object.assign(this.sharedMetadata, fields)
    this.sharedMetadata._date = Date.now()

    // Pass our metadata to the Worker
    this.postWorkerMessage({ action: 'metadata-update', metadata: this.sharedMetadata })
  }
}
