import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

export const LocationModel = types.model('LocationModel', {
  country: types.optional(types.string, 'US'),
  latitude: types.optional(types.number, 0),
  locality: types.optional(types.string, ''),
  longitude: types.optional(types.number, 0),
  postal_code: types.optional(types.string, ''),
  region: types.optional(types.string, '')
})

export type Location = Instance<typeof LocationModel>
export type LocationSnapshot = SnapshotOut<typeof LocationModel>
