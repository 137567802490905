function pad(num: number, size: number) {
  return (1e15 + num + '').slice(-size)
}

export class FixedLengthArray {
  private arr: string[]

  constructor(size: number) {
    // create real array to store values, hidden from outside
    this.arr = new Array(size).fill('')
  }

  getKey(eventArgs: any[]) {
    const currentDate = new Date()
    const dateTime = currentDate.toLocaleString()
    eventArgs.push({ dateTime })
    const millis = pad(currentDate.getMilliseconds(), 3).charAt(0) // pad out to three characters with leading zeros
    eventArgs.push({ millis })
    const key = JSON.stringify(eventArgs)
    return key
  }

  get(i: number) {
    return this.arr[i]
  }

  find(...args: any[]) {
    const key = this.getKey(args)
    return this.arr.find($ => $ == key)
  }

  push(...args: any[]) {
    this.arr.unshift(this.getKey(args))
    this.arr.pop()
  }

  get length() {
    return this.arr.length
  }
}
