// import performance from 'react-native-performance'
import { Platform } from 'react-native'
import type { Plugin, RootSDKStoreType } from '@vatom/sdk/core'
import { composeRootSDKStore, RegionType } from '@vatom/sdk/core'
import type { IAnyModelType } from 'mobx-state-tree'
import { onSnapshot, SnapshotOut } from 'mobx-state-tree'

import logger from '../logger'
// import { roughSizeOfObject } from '../utils/roughSizeOfObject'
// import { Environment } from "../environment"
import * as storage from '../utils/storage'

import type { SDKProviderProps } from './SDKProvider'

/**
 * The key we'll be saving our state as within async storage.
 */
const buster = 2
const ROOT_STATE_STORAGE_KEY = getRootStateStorageKey()

function getRootStateStorageKey() {
  if (Platform.OS === 'web') {
    return isEmbedded() ? `embedded-root-sdk-${buster}` : `root-sdk-${buster}`
  }
  return `root-sdk`
}

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment() {
  // const env = new Environment()
  // await env.setup()
  // return env
  return {}
}

/**
 * Setup the root state.
 */
export async function setupRootSdkStore(props: SDKProviderProps) {
  logger.info('setupRootSdkStore')
  let rootStore: RootSDKStoreType
  let data: any

  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment()
  const classes: IAnyModelType[] = props.plugins ?? []
  const plugins: Plugin[] = classes.map(PluginClass =>
    PluginClass.create({ name: PluginClass.name })
  )
  const blankSnapshot = { dataPool: {} }
  const RootSDKStore = composeRootSDKStore({ ...props, classes, plugins })
  try {
    // load data from storage
    data = (await storage.load(ROOT_STATE_STORAGE_KEY)) || blankSnapshot
    // logger.info('[setupRootSdkStore] storage data', data)
    logger.info(
      '[setupRootSdkStore] storage data.dataPool.regions.length',
      data.dataPool?.regions?.length
    )
    logger.info(
      '[setupRootSdkStore] storage data.dataPool.plugins.BVatomPlugin.regions.length',
      data.dataPool?.plugins?.BVatomPlugin?.regions?.length
    )
    //
    // DEBUG: wipe store on boot:
    //
    // rootStore = RootSDKStore.create({ dataPool: {} }, env)
    //
    // PRODUCTION:
    // performance.mark('RootSDKStore.create', {
    //   detail: {
    //     countTokens: data.dataPool?.countTokens ?? 0
    //   }
    // })
    // logger.info('[performance size]', roughSizeOfObject(data))
    rootStore = RootSDKStore.create(data, env)
    // performance.measure('MeasureRootSDKStoreCreate', {
    //   start: 'RootSDKStore.create',
    //   detail: {
    //     countTokens: rootStore.dataPool?.countTokens ?? 0
    //   }
    // })
    // logger.info('[performance mark]', performance.getEntriesByType('measure'))

    logger.info('[setupRootSdkStore] restored successfully')
    logger.info(
      '[setupRootSdkStore] rootStore.dataPool.regions.length',
      rootStore.dataPool?.regions?.length,
      rootStore.dataPool?.regions?.map(region => region._id)
    )
  } catch (error) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootSDKStore.create(blankSnapshot, env)

    logger.error('********************** [setupRootSdkStore] **************************')
    logger.error(
      '*******                                                                   *******'
    )
    logger.error(
      '*******                    FAILED TO RESTORE SDK FROM DISK!                    *******'
    )
    logger.error(
      '*******                                                                   *******'
    )
    logger.error(
      '*********************************************************************************'
    )
    logger.error(`Error: ${error}`)

    // but please inform us what happened
    // __DEV__ && logger.tron.error(e.message, null)
  }

  // reactotron logging
  // if (__DEV__) {
  //   env.reactotron.setRootStore(rootStore, data)
  // }

  // track changes & save to storage
  onSnapshot(rootStore, snapshot => {
    // logger.info('[setupRootSdkStore.onSnapshot] storage data', snapshot)
    // logger.info(
    //   '[setupRootSdkStore.onSnapshot] storage data.dataPool.regions.length',
    //   snapshot.dataPool?.regions?.length
    // )
    // logger.info(
    //   '[setupRootSdkStore.onSnapshot] storage data.dataPool.plugins.BVatomPlugin.regions.length',
    //   snapshot.dataPool?.plugins?.BVatomPlugin?.regions?.length
    // )

    try {
      // NOTE: There is a maximum string length for LocalStorage.setItem Javascript strings
      //   https://jsfiddle.net/wbbgyst0/21/
      const unprotectedSnapshot = JSON.parse(JSON.stringify(snapshot))

      // do not save erc regions
      if (unprotectedSnapshot.dataPool.plugins?.ErcPlugin?.regions) {
        unprotectedSnapshot.dataPool.plugins.ErcPlugin.regions = []
      }

      // do not save solana regions
      if (unprotectedSnapshot.dataPool.plugins?.SolanaPlugin?.regions) {
        unprotectedSnapshot.dataPool.plugins.SolanaPlugin.regions = []
      }
      if (unprotectedSnapshot?.dataPool?.regions) {
        unprotectedSnapshot.dataPool.regions = []
      }

      if (Platform.OS === 'web') {
        // do not save vatom regions
        if (unprotectedSnapshot.dataPool.plugins?.BVatomPlugin?.regions) {
          unprotectedSnapshot.dataPool.plugins.BVatomPlugin.regions = []
        }
        // do not save new vatom regions
        if (unprotectedSnapshot.dataPool.plugins?.NewVatomPlugin?.regions) {
          unprotectedSnapshot.dataPool.plugins.NewVatomPlugin.regions = []
        }
        // do not save new vatom regions
        if (unprotectedSnapshot.dataPool.plugins?.NewVatomPlugin?.regions) {
          unprotectedSnapshot.dataPool.plugins.NewVatomPlugin.regions = []
        }

        // do not save erc regions
        if (unprotectedSnapshot.dataPool.plugins?.ErcPlugin?.regions) {
          unprotectedSnapshot.dataPool.plugins.ErcPlugin.regions = []
        }

        // do not save solana regions
        if (unprotectedSnapshot.dataPool.plugins?.SolanaPlugin?.regions) {
          unprotectedSnapshot.dataPool.plugins.SolanaPlugin.regions = []
        }
        if (unprotectedSnapshot?.dataPool?.regions) {
          unprotectedSnapshot.dataPool.regions = []
        }
      } else {
        // do not save geo regions for mobile

        if (unprotectedSnapshot.dataPool.plugins?.BVatomPlugin?.regions) {
          unprotectedSnapshot.dataPool.plugins.BVatomPlugin.regions =
            unprotectedSnapshot.dataPool?.plugins?.BVatomPlugin?.regions?.filter(
              // @ts-ignore
              region => region.id !== RegionType.geopos
            )
        }
        if (unprotectedSnapshot.dataPool?.regions.length > 0) {
          unprotectedSnapshot.dataPool.regions = unprotectedSnapshot.dataPool.regions.filter(
            // @ts-ignore
            region => region.id !== RegionType.geopos
          )
        }
      }

      storage.save(ROOT_STATE_STORAGE_KEY, unprotectedSnapshot)
    } catch (error) {
      logger.error('********************** [setupRootSdkStore] **************************')
      logger.error(
        '*******                                                                   *******'
      )
      logger.error(
        '*******                    FAILED TO SAVE SDK TO DISK!                    *******'
      )
      logger.error(
        '*******                                                                   *******'
      )
      logger.error(
        '*********************************************************************************'
      )
      logger.error(`Error: ${error}`)
    }
  })

  // track changes & log
  // onPatch(rootStore, (patch, reversePatch) => {
  // logger.info('[setupRootSdkStore.onSnapshot] storage data', patch)
  // logger.info(
  //   '[setupRootSdkStore.onSnapshot] storage data.dataPool.regions.length',
  //   snapshot.dataPool.regions.length
  // )
  // logger.info(
  //   '[setupRootSdkStore.onSnapshot] storage data.dataPool.plugins.BVatomPlugin.regions.length',
  //   snapshot.dataPool.plugins.BVatomPlugin.regions.length
  // )
  // })

  // register plugins
  logger.info('[setupRootSdkStore] plugins.length', plugins.length)
  try {
    plugins.map(plugin => rootStore.dataPool.register(plugin))
  } catch (error) {
    logger.error('[setupRootSdkStore] plugins.map.register', plugins.length)
  }

  return rootStore
}

function isEmbedded() {
  const isWeb = Platform.OS === 'web'
  if (!isWeb) return false

  let isEmbedded =
    (window.parent && window.parent !== window) ||
    sessionStorage?.getItem('isEmbedded') === 'true' ||
    sessionStorage?.getItem('embeddedType') !== null ||
    false
  isEmbedded = JSON.parse(isEmbedded.toString().toLowerCase())
  return isEmbedded
}

export const clearRootSDKStore = async () => {
  storage.remove(getRootStateStorageKey())
}

// clearRootSDKStore()
