import { VatomWallet } from './utils/WalletSdk'
import SDKHandlers from './utils/WalletSdk/SDKHandlers'
import { App } from './App.base'

// @ts-ignore
if (typeof process !== 'undefined' && process.browser) {
  // @ts-ignore
  window._frameTimestamp = null
}

// setups the wallet sdk
VatomWallet.setup()

// register handlers for spaces app
VatomWallet._shared.handle('walletsdk:performAction', SDKHandlers.performAction)
VatomWallet._shared.handle('walletsdk:combineToken', SDKHandlers.combineTokens)
VatomWallet._shared.handle('walletsdk:trashToken', SDKHandlers.trashToken)
VatomWallet._shared.handle('walletsdk:getToken', SDKHandlers.getToken)
VatomWallet._shared.handle('walletsdk:getPublicToken', SDKHandlers.getPublicToken)
VatomWallet._shared.handle('walletsdk:listTokens', SDKHandlers.listTokens)
VatomWallet._shared.handle('walletsdk:isLoggedIn', SDKHandlers.isLoggedIn)
VatomWallet._shared.handle('walletsdk:getPublicProfile', SDKHandlers.getPublicProfile)
VatomWallet._shared.handle('walletsdk:getCurrentUserPoints', SDKHandlers.getCurrentUserPoints)
VatomWallet._shared.handle('walletsdk:getCurrentUser', SDKHandlers.getCurrentUser)
VatomWallet._shared.handle('walletsdk:getBusinessTabs', SDKHandlers.getBusinessTabs)
VatomWallet._shared.handle('walletsdk:navigateToTab', SDKHandlers.navigateToTab)
VatomWallet._shared.handle('walletsdk:navigate', SDKHandlers.navigate)
VatomWallet._shared.handle('walletsdk:logOut', SDKHandlers.logOut)
VatomWallet._shared.handle('walletsdk:openCommunity', SDKHandlers.openCommunity)
VatomWallet._shared.handle('walletsdk:navigationIsReady', SDKHandlers.navigationISready)
VatomWallet._shared.handle(
  'walletsdk:dispatchNavigationAction',
  SDKHandlers.dispatchNavigationAction
)
VatomWallet._shared.handle('walletsdk:linkTo', SDKHandlers.linkTo)

export default App
