import type { BoundingBoxModel } from '@vatom/sdk/core'
import { RegionStore } from '@vatom/sdk/core'
import type { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import logger from '../logger'
import type { VatomModelSnapshotIn, ViewPlacement } from '../models/VatomModel'
import { VatomModel } from '../models/VatomModel'
import { VatomApiStore } from '../modules/VatomApiStore'

// "image": {
//       "type": "image/png",
//       "ref": "https://resources.vatominc.com/Cox1qh6ggb/83636ab0-16bf-11ef-aadd-df26eff4aa87.png?1716219730590"
//   },
//   "name": "Default",
//   "rule": {}

interface MapReadyV1State {
  location: {
    lat: number
    lon: number
  }
}
interface StudioInfoV1State {
  businessId: string
  campaignId: string
  objectDefinitionId: string
}

interface MetaV1State {
  displayName: string
  description: string
  category: null
  isDraft: boolean
}

export interface VatomResponse {
  id: string
  viewPlacements: ViewPlacement[]
  state: {
    'map-ready-v1': MapReadyV1State
    'studio-info-v1': StudioInfoV1State
    'meta-v1': MetaV1State
    [behaviorId: string]: unknown
  }
  createdAt: string
  updatedAt: string
}

interface VatomsGeoMapRequest {
  bottomLeft: {
    lat: number
    lon: number
  }
  topRight: {
    lat: number
    lon: number
  }
}

interface VatomsGeoMapResponse {
  items: VatomResponse[]
}

export function convertItemToVatomModel(item: VatomResponse): VatomModelSnapshotIn {
  return {
    id: item.id,
    parentId: '.',
    owner: '',
    lastOwner: '',
    author: '',
    type: 'vatom-new',
    created: new Date(item.createdAt),
    modified: new Date(item.updatedAt),
    state: item.state,
    viewPlacements: item.viewPlacements
  }
}

const className = 'VatomGeoMapRegionStore'

// export const VatomGeoMapRegionStore = VatomRegionStore.named(className)
export const VatomGeoMapRegionStore = RegionStore.named(className)
  .props({
    className,
    _tokens: types.optional(types.map(VatomModel), {}),
    _api: types.late(() => types.safeReference(VatomApiStore))
  })
  .views(self => ({
    /** Our state key is the current user's ID */
    get stateKey() {
      return `geomap:VatomNet:${self._id}`
    },
    get inventoryApi() {
      return self.rootStore.service.network
    },
    get vatoms(): VatomModelSnapshotIn[] {
      // @ts-ignore
      return self.tokens as VatomModelSnapshotIn[]
    },
    get isLoading() {
      console.log('self._isLoading vatomGeo: ', self._isLoading)
      return self._isLoading
    },
    get api() {
      return self.rootStore.service.network
    },
    get decodedDescriptor() {
      if (self.descriptor) {
        return JSON.parse(self.descriptor) as SnapshotOut<typeof BoundingBoxModel>
      }
      return null
    }
  }))
  .views(self => ({
    get socketApi() {
      return self._api!
    },
    get socket() {
      return this.socketApi.webSockets
    }
  }))
  .views(self => ({
    get ARTokens() {
      return self.tokens.filter(t => t.position?.coordinates)
    }
  }))
  .actions(self => ({
    getItem(id: string) {
      return self.vatoms.find(t => t.id === id)
    }
  }))
  .actions(self => ({
    pushTokens(tokens: VatomModelSnapshotIn[]) {
      for (const obj of tokens) {
        try {
          const instance = VatomModel.create(obj)
          self._tokens.set(instance.id, instance)
        } catch (e) {
          console.error('Error creating vatom model', {
            incommingSnapshot: obj,
            error: e
          })
        }
      }
      // Clean tokens from previous bounding box or previous state
      const tokensToRemove = self.vatoms.filter(
        existingToken => !tokens.find(t => existingToken.id === t.id)
      )
      console.log('LOG: VatomGeoMapRegion > pushTokens > tokensIdsToRemove:', tokensToRemove)
      tokensToRemove.forEach(t => self._tokens.delete(t.id))
    }
  }))
  .actions(self => ({
    async fetchPage(args?: unknown) {
      return
    },
    async load(): Promise<string[]> {
      // Start the webscoket server if it is not running
      // self.startSockets().then(() => {
      //   // Send region command to the WebSocket
      //   this.sendRegionCommand()
      // })

      self.setIsLoading(true)
      logger.info('[VatomGeoMapRegion.load] loading...')

      logger.info('[VatomGeoMapRegion.load] self.decodedDescriptor', self.decodedDescriptor)

      // const limit = 100

      const defaultPos = {
        lat: 0,
        lon: 0
      }

      const payload: VatomsGeoMapRequest = {
        bottomLeft: self.decodedDescriptor?.bottom_left ?? defaultPos,
        topRight: self.decodedDescriptor?.top_right ?? defaultPos
      }
      logger.info('[VatomGeoMapRegion.load] payload', payload)
      // const payloadTest = { bottomLeft: { lat: -108, lon: 25 }, topRight: { lat: -107, lon: 24 } }

      const loadedIDs: string[] = []

      const response = await self.api.post('/vatoms/geo-map/search', JSON.stringify(payload))
      const data = response.data as VatomsGeoMapResponse

      const newVatoms: VatomModelSnapshotIn[] = []

      data.items.forEach(item => {
        const transformedItem = convertItemToVatomModel(item)
        newVatoms.push(transformedItem)
      })
      console.log('LOG: VatomGeoMapRegion > load > newVatoms:', newVatoms)
      self.pushTokens(newVatoms)

      self.setIsLoading(false)
      return loadedIDs
    }
    /** Sends the region command up the websocket to enable region monitoring */
    // sendRegionCommand() {
    //   // Stop if WebSocket is not connected
    //   console.log('sendRegionCommand', self.socket.isOpen)
    //   if (!self.socket.isOpen || !self.decodedDescriptor) return

    //   // Convert our coordinates into the ones needed by the command
    //   const topLeft = {
    //     lat: Math.max(self.decodedDescriptor.top_right.lat, self.decodedDescriptor.bottom_left.lat),
    //     lon: Math.min(self.decodedDescriptor.top_right.lon, self.decodedDescriptor.bottom_left.lon)
    //   }
    //   const bottomRight = {
    //     lat: Math.min(self.decodedDescriptor.top_right.lat, self.decodedDescriptor.bottom_left.lat),
    //     lon: Math.max(self.decodedDescriptor.top_right.lon, self.decodedDescriptor.bottom_left.lon)
    //   }

    //   // Create command payload
    //   const cmd = {
    //     id: '1',
    //     version: '1',
    //     type: 'command',
    //     cmd: 'monitor',
    //     payload: {
    //       top_left: topLeft,
    //       bottom_right: bottomRight
    //     }
    //   }

    //   // Send it up
    //   logger.info('Sending WS command: ' + JSON.stringify(cmd))
    //   self.socket.sendMessage(cmd)
    // }
  }))
  .actions(self => ({
    // so both inentory and geo region have a reload method
    reload: self.load
  }))
// .actions(self => {
// const onWebSocketOpen = async (msg: any) => {
//   // Full refresh this region, in case any messages were missed
//   self.load()
//   // Send region command again
//   self.sendRegionCommand()
// }

// return { onWebSocketOpen }
// })

export type VatomGeoMapRegionStoreType = Instance<typeof VatomGeoMapRegionStore>
export type VatomGeoMapRegion = VatomGeoMapRegionStoreType
export type VatomGeoMapRegionSnapshotIn = SnapshotIn<typeof VatomGeoMapRegionStore>
export type VatomGeoMapRegionSnapshotOut = SnapshotOut<typeof VatomGeoMapRegionStore>
