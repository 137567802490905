import { logger } from 'react-native-logs'

const config = {
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3
  },
  transportOptions: {
    colors: {
      info: 'greenBright',
      warn: 'yellowBright',
      error: 'redBright'
    },
    extensionColors: {
      'sdk:core': 'blue'
    }
  },
  dateFormat: 'iso',
  enabled: __DEV__ ? true : false
}

const rnLogger = logger.createLogger(config)

const log = rnLogger.extend('sdk:core')

export default log
