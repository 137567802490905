import DeviceInfo from 'react-native-device-info'
import type { QueryFunctionContext } from '@tanstack/react-query'

import type { matrixQueryKeys } from './queries'
import type { MatrixUserData } from './types'

export const matrixHost = 'https://matrix.vatom.com'

export const fetchMatrixUser = async ({
  queryKey
}: QueryFunctionContext<ReturnType<(typeof matrixQueryKeys)['getMatrixUser']>>) => {
  const deviceId = await DeviceInfo.getUniqueId().then(uniqueId => uniqueId)
  const [{ accessToken }] = queryKey
  const res = await fetch(`${matrixHost}/_matrix/client/v3/login`, {
    method: 'POST',
    body: JSON.stringify({
      type: 'org.matrix.login.jwt',
      token: accessToken,
      device_id: deviceId
    })
  })

  const data = (await res.json()) as MatrixUserData
  return data
}

export const fetchMatrixSync = async ({
  nextBatch,
  token,
  timeout
}: {
  nextBatch?: string
  token?: string
  timeout?: boolean
}) => {
  const url = !nextBatch
    ? '/_matrix/client/v3/sync?full_state=true'
    : `/_matrix/client/v3/sync?since=${nextBatch}${timeout ? '&timeout=30000' : ''}`
  const res = await fetch(`${matrixHost}${url}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  })
  if (res.status !== 200) {
    console.error('POLL SYNC ERROR: ', res.status, res.statusText)
  }
  const data = await res.json()
  return data
}

export const fetchMatrixSyncNew = async ({
  queryKey: [{ nextBatch, matrixToken }]
}: QueryFunctionContext<ReturnType<(typeof matrixQueryKeys)['getMatrixPollStateSync']>>) => {
  console.log('Poll sync starting for next batch: ', nextBatch)

  const url = !nextBatch
    ? '/_matrix/client/v3/sync?full_state=true'
    : `/_matrix/client/v3/sync?since=${nextBatch}${nextBatch ? '&timeout=30000' : ''}`
  const res = await fetch(`${matrixHost}${url}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${matrixToken}`
    })
  })

  const data = await res.json()
  return data
}

export const fetchMatrixPushers = async (token?: string, userId?: string) => {
  const model = DeviceInfo.getDeviceId()

  const res = await fetch(`${matrixHost}/_matrix/client/v3/pushers/set?access_token=${token}`, {
    method: 'POST',
    body: JSON.stringify({
      app_display_name: 'Vatom',
      app_id: 'com.vatom.app.ios',
      append: false,
      data: {
        url: 'https://businesses.api.vatominc.com/_matrix/push/v1/notify'
      },
      device_display_name: model,
      kind: 'http',
      lang: 'en',
      profile_tag: 'default',
      pushkey: userId
    })
  })

  return res
}

export const getMatrixUserDisplayName = async (token?: string, userId?: string) => {
  const res = await fetch(
    `${matrixHost}/_matrix/client/v3/profile/${userId}/displayname?access_token=${token}`,
    {
      method: 'GET'
    }
  )
  const data = await res.json()
  return data
}

export const setMatrixUserDisplayName = async (
  userName?: string,
  token?: string,
  userId?: string
) => {
  const res = await fetch(
    `${matrixHost}/_matrix/client/v3/profile/${userId}/displayname?access_token=${token}`,
    {
      method: 'PUT',
      body: JSON.stringify({
        displayname: userName
      })
    }
  )
  return res
}
