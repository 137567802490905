import type { Region, RegionTypes, SessionTokenType } from '@vatom/sdk/core'
import {
  BasePluginStore,
  RegionIds,
  Sentry,
  SessionSource,
  SessionType,
  withRootSDKStore
} from '@vatom/sdk/core'
import { getConfig } from '@vatom/sdk/react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import type { IAnyType, ISerializedActionCall } from 'mobx-state-tree'
import { isAlive, onAction, types } from 'mobx-state-tree'

import logger from '../logger'
import { VatomModel } from '../models/VatomModel'
import { VatomApiStore } from '../modules/VatomApiStore'
import { VatomGeoMapRegionStore } from '../regions/VatomGeoMapRegion'
import { InventoryRegionStore } from '../regions/VatomInventoryRegion'

const className = 'NewVatomPlugin'

const VatomPluginBase = BasePluginStore.named(`${className}Base`)
  .props({
    api: types.optional(VatomApiStore, { id: `VatomApiStore` }),
    className
  })
  .extend(withRootSDKStore)
  .actions(self => ({
    clearAuth() {
      const sdk = self.rootStore
      sdk.dataPool.user.logout()
      sdk.dataPool.user.clearCache()
      sdk.service.setToken(undefined)
      sdk.dataPool.sessionStore.clear()
    },
    clearCache() {
      logger.info('[VatomPluginBase.clearCache]')
      self.api.store.userID = null
      self.api.store.refreshToken = null
      self.api.store.assetProvider = []
      self.regions.map(r => r.clear())
    }
  }))
  .actions(self => ({
    async checkToken(sessionToken: SessionTokenType) {
      logger.info('[VatomPluginBase.checkToken]', !!sessionToken, sessionToken)
      try {
        // Verify the access token
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const decodedToken: any = jwtDecode(sessionToken.accessToken)
        const expirationTime = decodedToken.exp * 1000
        logger.info('[VatomPluginBase.checkToken] expirationTime:', expirationTime)

        // quick calc to determine if the token has expired
        if (Date.now() + 5000 > expirationTime) {
          logger.info('[VatomPluginBase.checkToken]: token expired')
          logger.info('[VatomPluginBase.checkToken]: attempt refresh...', sessionToken.refreshToken)
          // Refresh the token
          const { data } = await axios.post('/v1/access_token', undefined, {
            baseURL: 'https://api.vi.vatom.network',
            headers: {
              'App-Id': getConfig().appID,
              Authorization: `Bearer ${sessionToken.refreshToken}`
            }
          })

          logger.info('[VatomPluginBase.checkToken]: data', data)
          self.api.store.token = data.payload.access_token.token
          if (sessionToken.setAccessToken) {
            sessionToken.setAccessToken(data.payload.access_token.token)
          }
        } else {
          self.api.store.token = sessionToken.accessToken
        }
        logger.info('[VatomPluginBase.checkToken] self.api.store.token', self.api.store.token)
      } catch (error) {
        logger.error('[VatomPluginBase.checkToken] ERROR', error)
        Sentry.captureMessage(String(`VatomPluginBase.checkToken: ${String(error)}`))
        self.clearAuth()
      }
    }
  }))

type PossibleRegion = {
  [Property in RegionTypes]: IAnyType
}

const AllRegions: PossibleRegion = {
  [RegionIds.inventory]: InventoryRegionStore,
  [RegionIds.geopos]: VatomGeoMapRegionStore
}
const AllRegionsArray = Object.values(AllRegions)

export const NewVatomPlugin = types
  .compose(VatomPluginBase, types.model('vatom-placeholder', {}))
  .props({
    regions: types.optional(
      types.array(
        types.union(
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dispatcher: (snapshot: any): IAnyType => {
              const region = AllRegions[snapshot.id as RegionTypes]
              if (!region) {
                throw new Error(
                  `[${className}.regions dispatcher] Unable to discriminated region`,
                  snapshot.id
                )
              }
              return region
            }
          },
          ...AllRegionsArray
        )
      ),
      []
    )
  })
  .named(className)
  .extend(withRootSDKStore)
  .views(self => ({
    get availableRegions() {
      return AllRegionsArray
    },
    get availableTokens() {
      return [VatomModel]
      // return []
    }
  }))
  .actions(self => ({
    region(id: RegionTypes, descriptor?: string): Region {
      try {
        logger.info(`[${className}.region] Looking for region in plugin`, id, descriptor)
        const region = self.regions.find(r => r.matches(id, descriptor))
        if (region) {
          logger.info(`[${className}.region] Found region`, region)
          return region
        }

        logger.info(`[${className}.region] Creating new region in plugin`, id, descriptor)
        let newRegion = null
        if (id === RegionIds.geopos) {
          // Add new region for geo-map new API
          newRegion = VatomGeoMapRegionStore.create({
            id,
            descriptor,
            _id: `VatomGeoMapRegionStore-${id}-${descriptor}`
          })
        } else {
          newRegion = InventoryRegionStore.create({
            id,
            _id: `VatomInventoryRegionStore-${id}`
          })
        }
        self.regions.push(newRegion)
        return newRegion
      } catch (error) {
        logger.error(`[${className}.region] creating region`, error)
        throw error
      }
    }
  }))
  .actions(self => {
    let _initializing = false
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function initializeSessions(sessions?: any) {
      logger.info(`[${className}.initializeSessions]: start`)
      _initializing = true

      const session = sessions.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ($: any) => $.type === SessionType.JWT && $.source === SessionSource.VatomNetwork
      )

      try {
        if (!session) {
          _initializing = false
          return
        }

        const currentUserId = self.api.store.userID
        const sessionToken = session.value as SessionTokenType

        if (self.api.store.refreshToken !== sessionToken.refreshToken) {
          self.api.setRefreshToken(sessionToken.refreshToken || null)
        }

        await self.checkToken(sessionToken)

        const id = RegionIds.inventory
        let region = self.regions.find(r => r.matches(id))
        if (!region) {
          region = self.region(RegionIds.inventory)
          logger.info(`[${className}.initializeSessions]: creating region: `, region.id)
          const compositeRegion = self.rootStore.dataPool.region(region.id)
          logger.info(
            `[${className}.initializeSessions]: adding region to compositeRegion: `,
            compositeRegion.id
          )
          compositeRegion.addRegion(region)
          logger.info(
            `[${className}.initializeSessions] compositeRegion`,
            compositeRegion.id,
            compositeRegion.regions.length
          )
        }

        if (self.api.store.userID !== currentUserId) {
          // Reload the region because this is a new or different user
          logger.warn('[VatomPlugin.initializeSessions] different user')
          region.clear()
        }

        region.load()
      } catch (error) {
        logger.error(`[${className}.initializeSessions]`, error)
      } finally {
        _initializing = false
      }
    }

    async function onSessionsChanged(data: ISerializedActionCall): Promise<void> {
      if (!isAlive(self)) {
        return
      }

      if (data.path === '/dataPool/sessionStore' && data.name === 'delete') {
        logger.info(`[${className}.onSessionsChanged DELETED]`, data)
      }

      if (data.path === '/dataPool/sessionStore' && data.name === 'update') {
        const region = self.regions.find(r => r.matches(RegionIds.inventory))
        if (region) {
          await region.load()
        }
      }

      if (data.path !== '/dataPool/sessionStore' || !['add'].includes(data.name)) {
        return
      }
      console.log('LOG: NewVatomPlugin > onSessionsChanged >` initializeSessions', _initializing)
      if (!_initializing) initializeSessions(data.args)
    }

    async function init() {
      logger.info(`[${className}.init]`)
      onAction(self.rootStore, onSessionsChanged)

      // Check if there are any existing credentials to initialize the plugin
      await initializeSessions(self.rootStore?.dataPool?.sessionStore?.sessions)
    }
    return {
      init
    }
  })
