import type { Leaves } from './types'

export interface IColorHues {
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

const grayCool = {
  50: '#F8F9FA',
  100: '#F1F3F5',
  200: '#E9ECEF',
  300: '#DEE2E6',
  400: '#CED4DA',
  500: '#ADB5BD',
  600: '#868E96',
  700: '#3F4A55',
  800: '#29313A',
  900: '#11141E'
} as const

const grayDarkMode = {
  50: '#D9D9DB',
  100: '#B3B4B7',
  200: '#83858A',
  300: '#67696F',
  400: '#54565D',
  500: '#41434B',
  600: '#373A42',
  700: '#2E3039',
  800: '#242730',
  900: '#1B1D27'
} as const

const textLightMode = {
  // 100: '#CED4DA',
  100: '#ECECED',
  300: '#868E96',
  600: '#3F4A55',
  900: '#11141E'
} as const
const textDarkMode = {
  100: '#D9D9DB',
  200: '#C6C7C9',
  300: '#B3B4B7',
  600: '#707278',
  900: '#3F4A55'
} as const

const systemColorsLight = {
  red: '#DD4267',
  yellow: '#F5B700',
  green: '#34C759',
  blue: '#2EA7FF',
  orange: '#FF8000'
} as const
const systemColorsDark = {
  red: '#C84D6B',
  yellow: '#CDAC4B',
  green: '#389D52',
  blue: '#3798E4',
  orange: '#EB8117'
} as const

export const colors = {
  contrastThreshold: 7,
  // Singleton colors
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  // black: '#11141E',
  white: '#FFFFFF',
  lightText: '#868E96',
  extraLightText: '#CED4DA',
  darkText: '#001226',
  bodyText: '#3F4A55',
  normalText: '#343A40',
  disabled: '#E9ECEF',
  textLightMode,
  textDarkMode,
  systemColorsLight,
  systemColorsDark,
  brand: {
    50: '#fed0be',
    100: '#feab8c',
    200: '#fd9973',
    300: '#fd875a',
    400: '#fd7540',
    500: '#FF8000',
    600: '#d43d03',
    700: '#bb3602',
    800: '#a22e02',
    900: '#702001'
  },
  accent: {
    50: '#99d4ff',
    100: '#66bfff',
    200: '#4db4ff',
    300: '#34a9ff',
    400: '#1a9eff',
    500: '#0085e6',
    600: '#0068b3',
    700: '#00599a',
    800: '#004a80',
    900: '#002d4d'
  },
  grayCool,
  grayDarkMode,
  gray: {
    50: '#e9f3fe',
    100: '#d1dae0',
    200: '#b9bfc7',
    300: '#9ea6ad',
    400: '#848c94',
    500: '#6b737b',
    600: '#525961',
    700: '#394046',
    800: '#21262d',
    900: '#dEE2e6'
  },
  trueGray: {
    50: '#f0f2f4',
    100: '#d6d8da',
    200: '#bbbec2',
    300: '#f1f3f5',
    400: '#818b95',
    500: '#68727d',
    600: '#515860',
    700: '#3b3f44',
    800: '#232629',
    900: '#0b0d0f'
  },
  blueGray: {
    50: '#eff1f6',
    100: '#d1d5de',
    200: '#b2b9c9',
    300: '#929eb5',
    400: '#7382a2',
    500: '#596889',
    600: '#46516a',
    700: '#323a4b',
    800: '#1e232d',
    900: '#090c10'
  },
  red: {
    50: '#ffe5ef',
    100: '#f8bccb',
    200: '#ee92a8',
    300: '#e56785',
    400: '#dc3c63',
    500: '#c32349',
    600: '#981939',
    700: '#6e1029',
    800: '#440717',
    900: '#1e0007'
  },
  green: {
    50: '#e2fdea',
    100: '#bdf0cb',
    200: '#98e6ac',
    300: '#70da8b',
    400: '#49cf6b',
    500: '#30b651',
    600: '#238e3e',
    700: '#16652b',
    800: '#083e18',
    900: '#001602'
  },
  orange: {
    50: '#fff9da',
    100: '#ffecad',
    200: '#ffdf7d',
    300: '#ffd24b',
    400: '#ffc51a',
    500: '#e6ab00',
    600: '#b38500',
    700: '#805f00',
    800: '#4e3900',
    900: '#1d1300'
  },
  lightBlue: {
    50: '#dff6ff',
    100: '#b3e2ff',
    200: '#84cffc',
    300: '#57bcfb',
    400: '#34a8fa',
    500: '#258fe1',
    600: '#2ea7ff',
    700: '#0d4f7e',
    800: '#00304d',
    900: '#00111e'
  },

  dark: {
    50: '#18181b',
    100: '#27272a',
    200: '#3f3f46',
    300: '#52525b',
    400: '#71717a',
    500: '#a1a1aa',
    600: '#d4d4d8',
    700: '#e4e4e7',
    800: '#f4f4f5',
    900: '#fafafa'
  },
  linkedin: {
    50: '#E8F4F9',
    100: '#CFEDFB',
    200: '#9BDAF3',
    300: '#68C7EC',
    400: '#34B3E4',
    500: '#00A0DC',
    600: '#008CC9',
    700: '#0077B5',
    800: '#005E93',
    900: '#004471'
  },
  facebook: {
    50: '#c0dafb',
    100: '#90bef9',
    200: '#78b0f7',
    300: '#5fa2f6',
    400: '#4794f5',
    500: '#1778f2',
    600: '#0b60cb',
    700: '#0a55b2',
    800: '#09499a',
    900: '#06326a'
  },
  messenger: {
    50: '#D0E6FF',
    100: '#B9DAFF',
    200: '#A2CDFF',
    300: '#7AB8FF',
    400: '#2E90FF',
    500: '#0078FF',
    600: '#0063D1',
    700: '#0052AC',
    800: '#003C7E',
    900: '#002C5C'
  },
  whatsapp: {
    50: '#dffeec',
    100: '#b9f5d0',
    200: '#90edb3',
    300: '#65e495',
    400: '#3cdd78',
    500: '#22c35e',
    600: '#179848',
    700: '#0c6c33',
    800: '#01421c',
    900: '#001803'
  },
  twitter: {
    50: '#E5F4FD',
    100: '#C8E9FB',
    200: '#A8DCFA',
    300: '#83CDF7',
    400: '#57BBF5',
    500: '#1DA1F2',
    600: '#1A94DA',
    700: '#1681BF',
    800: '#136B9E',
    900: '#0D4D71'
  },
  telegram: {
    50: '#E3F2F9',
    100: '#C5E4F3',
    200: '#A2D4EC',
    300: '#7AC1E4',
    400: '#47A9DA',
    500: '#0088CC',
    600: '#007AB8',
    700: '#006BA1',
    800: '#005885',
    900: '#003F5E'
  },
  // Derived colors
  background: {} as IColorHues,
  backdrop: {} as IColorHues,
  danger: {} as IColorHues,
  error: {} as IColorHues,
  success: {} as IColorHues,
  warning: {} as IColorHues,
  muted: {} as IColorHues,
  primary: {} as IColorHues,
  info: {} as IColorHues,
  secondary: {} as IColorHues,
  light: {} as IColorHues,
  tertiary: {} as IColorHues
}

colors.background = colors.blueGray
colors.backdrop = colors.gray
colors.danger = colors.red
colors.error = colors.red
colors.success = colors.green
colors.warning = colors.orange
colors.muted = colors.trueGray
colors.primary = colors.brand
colors.secondary = colors.accent
colors.tertiary = colors.green
colors.info = colors.lightBlue
colors.light = colors.trueGray

export default colors
// export type IColors = Leaves<ITheme['colors']>;
export type IColors = Leaves<typeof colors>
