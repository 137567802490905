export const CommunitiesRoutes = {
  Communities: 'Communities',
  CommunitiesHome: 'CommunitiesHome',
  Room: 'Room',
  RoomView: 'RoomView',
  AddMessageModal: 'AddMessageModal',
  Thread: 'Thread',
  Members: 'Members',
  MemberProfile: 'MemberProfile',
  Reply: 'Reply',
  QuestionsList: 'QuestionsList',
  AddQuestionModal: 'AddQuestionModal',
  SketchesList: 'SketchesList',
  AddSketchModal: 'AddSketchModal'
} as const

export const RoomRoutes = {
  Room: 'Room',
  RoomMembers: 'RoomMembers',
  RoomThread: 'RoomThread',
  RoomNewMessage: 'RoomNewMessage',
  RoomReply: 'RoomReply',
  RoomQuestionList: 'RoomQuestionList',
  RoomNewQuestion: 'RoomNewQuestion',
  RoomSketchList: 'RoomSketchList',
  RoomNewSketch: 'RoomNewSketch'
} as const

export const CommunitiesRoomRoutes = {
  CommunitiesRoom: `Communities${RoomRoutes.Room}`,
  CommunitiesRoomMembers: `Communities${RoomRoutes.RoomMembers}`,
  CommunitiesRoomThread: `Communities${RoomRoutes.RoomThread}`,
  CommunitiesRoomNewMessage: `Communities${RoomRoutes.RoomNewMessage}`,
  CommunitiesRoomReply: `Communities${RoomRoutes.RoomReply}`,
  CommunitiesRoomQuestionList: `Communities${RoomRoutes.RoomQuestionList}`,
  CommunitiesRoomNewQuestion: `Communities${RoomRoutes.RoomNewQuestion}`,
  CommunitiesRoomSketchList: `Communities${RoomRoutes.RoomSketchList}`,
  CommunitiesRoomNewSketch: `Communities${RoomRoutes.RoomNewSketch}`
} as const

export type AllConnectScreens = typeof RoomRoutes & typeof CommunitiesRoomRoutes

// Inventory Tabnav
export const WalletRoutes = {
  NFTs: 'NFTs',
  Coins: 'Coins'
} as const

// Spaces Tabnav
export const SpacesRoutes = {
  Spaces: 'Spaces',
  People: 'People'
} as const

// Home Tabnav
export const TabRoutes = {
  Home: 'Home',
  DMs: 'DMs',
  Wallet: 'Wallet',
  Map: 'Map',
  MapAr: 'MapAr',
  Connect: 'Connect',
  Space: 'Space',
  Coins: 'Coins',
  People: 'People'
} as const

// App Stack
export const AppRoutes = {
  Claim: 'Claim',
  connect: 'connect',
  businessConnect: 'businessConnect',
  NFTDetail: 'NFTDetail',
  FindToken: 'FindToken',
  FindToken_Business: 'FindToken_Business',
  NFTSellDetail: 'NFTSellDetail',
  updateProfile: 'updateProfile',
  home: 'home',
  settingsMetamask: 'settingsMetamask',
  editProfile: 'editProfile',
  manageAccounts: 'manageAccounts',
  // profileOther:'profileOther',
  profileUser: 'profileUser',
  profileOtherUser: 'profileOtherUser',
  profileOtherUser_Business: 'profileOtherUser_Business',
  MemberProfile: 'MemberProfile',
  sendConfirmation: 'sendConfirmation',
  sendNFTConfirmation: 'sendNFTConfirmation',
  followPeople: 'followPeople',
  invitePeople: 'invitePeople',
  sendToChainConfirmation: 'sendToChainConfirmation',
  Acquire: 'Acquire',
  Acquire_Business: 'Acquire_Business',
  AcquirePubVariation: 'AcquirePubVariation',
  AcquirePubVariation1: 'AcquirePubVariation1',
  AcquirePubVariation2: 'AcquirePubVariation2',
  NftFilters: 'NftFilters',
  CoinsFilters: 'CoinsFilters',
  CameraScreen: 'CameraScreen',
  MintSuccess: 'MintSuccess',
  BackupToCloud: 'BackupToCloud',
  RestoreFromCloud: 'RestoreFromCloud',
  DeleteFromCloud: 'DeleteFromCloud',
  ChangeBackupPassword: 'ChangeBackupPassword',
  CloudBackupList: 'CloudBackupList',
  NewWalletStart: 'NewWalletStart',
  NewWalletBackup: 'NewWalletBackup',
  NewWalletConfirm: 'NewWalletConfirm',
  ImportWalletStart: 'ImportWalletStart',
  ImportWalletRecover: 'ImportWalletRecover',
  WalletAddressManagement: 'WalletAddressManagement',
  ShowPrivateKey: 'ShowPrivateKey',
  EditAddressName: 'EditAddressName',
  MapAR: 'MapAR',
  TokenAR: 'TokenAR',
  LogoutCallback: 'LogoutCallback',
  HelloWorld: 'HelloWorld',
  LoginCallback: 'LoginCallback',
  BusinessNFTDetail: 'BusinessNFTDetail',
  coin: 'coin',
  CoinDetail_Business: 'CoinDetail_Business',
  NFTDetail_Business: 'NFTDetail_Business',
  BusinessProxy: 'BusinessProxy',
  ARGame: 'ARGame',
  Redirect: 'Redirect',
  followList: 'followList',
  Metaverse: 'Metaverse',
  ...RoomRoutes,
  ...CommunitiesRoomRoutes,
  // DMs
  DmScreen: 'DmScreen',
  Settings: 'Settings',
  BlockChainWallets: 'BlockChainWallets',
  BlockChainNetwork: 'BlockChainNetwork',
  BlockChainNetworkAdd: 'BlockChainNetworkAdd',
  BlockChainNetworkEdit: 'BlockChainNetworkEdit',
  // NFT Option Actions
  SendNFT: 'SendNFT',
  SendNFT_Business: 'SendNFT_Business',
  SendNFTShare: 'SendNFTShare',
  SendNFTShare_Business: 'SendNFTShare_Business',
  DropNFT: 'DropNFT',
  DropNFT_Business: 'DropNFT_Business',
  SellNFT: 'SellNFT',
  SellNFT_Business: 'SellNFT_Business',
  MintNFT: 'MintNFT',
  MintNFT_Business: 'MintNFT_Business',

  // Fungible
  FungibleTokenDetail: 'FungibleTokenDetail',
  FungibleTokenTransfer: 'FungibleTokenTransfer',
  FungibleTokenRecipient: 'FungibleTokenRecipient',
  FungibleTokenConfirmation: 'FungibleTokenConfirmation',

  // Web3 Modals
  AddressAndNetworkSelector: 'AddressAndNetworkSelector',
  SignMessage: 'SignMessage',
  WalletRequestPermission: 'WalletRequestPermission',
  WalletSwitchEthereumChain: 'WalletSwitchEthereumChain',
  WalletSendTransaction: 'WalletSendTransaction'
} as const
