import React from 'react'
import type { ImageSourcePropType } from 'react-native'
import type { IButtonProps } from 'native-base'
import { Button as NbButton, HStack, Image } from 'native-base'

export interface ButtonProps extends IButtonProps {
  /**
   * Children components.
   */
  children?: React.ReactNode
  /**
   * Boolean that render image to left of button text when truthy.
   */
  hasImage?: boolean
  /**
   * Image height used if hasImage === true
   */
  imageHeight?: string | number
  /**
   * Image width used if hasImage === true
   */
  imageWidth?: string | number
  /**
   * Image source used if hasImage === true
   */
  imgSource?: ImageSourcePropType
  /**
   * Alt attribute for Image accessibility
   */
  imgAlt?: string
}

export const Button = ({
  hasImage,
  children,
  imageHeight,
  imageWidth,
  imgSource,
  imgAlt,
  ...props
}: ButtonProps) => {
  return (
    <NbButton {...props}>
      {hasImage ? (
        <HStack space={2} justifyContent="center" alignItems="center">
          <Image
            accessibilityLabel="button"
            accessibilityHint="button"
            alignSelf="center"
            height={imageHeight}
            width={imageWidth}
            source={imgSource}
            alt={imgAlt ?? 'button'}
            accessibilityIgnoresInvertColors
          />
          {children}
        </HStack>
      ) : (
        children
      )}
    </NbButton>
  )
}

export default Button
