import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import { LocationModel } from './LocationModel'

export const UserModel = types
  .model('UserModel', {
    bio: types.optional(types.string, ''),
    default_business_id: types.maybeNull(types.string),
    default_space_id: types.maybeNull(types.string),
    email: types.optional(types.string, ''),
    email_verified: types.optional(types.boolean, false),
    location: types.optional(LocationModel, {}),
    name: types.optional(types.string, ''),
    phone_number: types.optional(types.string, ''),
    phone_number_verified: types.optional(types.boolean, false),
    picture: types.optional(types.string, ''),
    sub: types.optional(types.string, ''),
    updated_at: types.optional(types.number, 0),
    wallet_address: types.optional(types.string, ''),
    website: types.optional(types.string, ''),
    guest: types.optional(types.boolean, false),
    deferred_deeplink: types.maybeNull(types.string)
  })
  .views(self => ({
    get firstName() {
      return self.name.split(' ')[0]
    }
  }))

export type User = Instance<typeof UserModel>
export type UserSnapshot = SnapshotOut<typeof UserModel>
