// import Map3D from "../Map3D"
// import Server from "../Server"
// import Users from "../Users"
// import AudioManager from "./AudioManager"
import MessageManager from './MessageManager'
import P2PAvatars from './P2PAvatars'
import SWebP2PAvatar from './SWebP2PAvatar'

/**
 * Subclass of P2PAvatars which provides SWeb-specific logic
 */
export default class SWebP2PAvatars extends P2PAvatars {
  /** Mute state */
  get muted() {
    return this.sharedMetadata.muted
  }
  set muted(muted) {
    // Apply metadata
    this.setMetadata({ muted: muted ? 1 : 0 })

    // // Attach audio
    // if (muted) {

    //     // Just remove the stream
    //     this.audio.microphoneStream = null

    // } else {

    //     // Get the input stream from the AudioManager, then set it
    //     AudioManager.checkInputDeviceAvailability().then(e => {
    //         this.audio.microphoneStream = this.muted ? null : AudioManager.mixerInputStream
    //     })

    // }

    // Enable or disable the audio tracks
    for (let track of this.audio.microphoneStream?.getAudioTracks() || []) track.enabled = !muted
  }

  /** @type {MessageManager} Message manager */
  messages = null

  /** Constructor */
  constructor(noAudio = false, messages, spaceID, userID) {
    // Setup class
    super(spaceID, userID, noAudio)

    // Bind functions
    this.messages = messages
    this.spaceID = spaceID
    this.userID = userID
    this.onMsg = this.onMsg.bind(this)
    // this.onAudioInputChanged = this.onAudioInputChanged.bind(this)

    // If we're using light bots, lower the max connection count
    if (window.swAutomation?.light) this.maxConnections = 16
  }

  /** Called on startup */
  start() {
    // Set which audio subsystem to use, the custom one or the browser's builtin one
    this.customAudio = false //!Server.dimension.data.p2p_browser_audio

    // Call super
    super.start()

    // Add listener for signals
    this.messages.addMessageListener('p2p.avatars.signal', this.onMsg)

    // Listen for audio input changes
    // AudioManager.addEventListener('input.changed', this.onAudioInputChanged)

    // Listener for snapshot updates
    // Server.analytics.addEventListener('updated', this.onSnapshotUpdated)
    this.onSnapshotUpdated()
  }

  /** Called on stop */
  stop() {
    super.stop()

    // Remove listener
    this.messages.removeMessageListener('p2p.avatars.signal', this.onMsg)
    // Server.analytics.removeEventListener('updated', this.onSnapshotUpdated)

    // Listen for audio input changes
    // AudioManager.removeEventListener('input.changed', this.onAudioInputChanged)
  }

  /** Trigger the update cycle. Must be called every frame. */
  update() {
    // Update realtime data with out current values
    // this.sharedMetadata.volume = Map3D.main.source.userAvatar.volume || 0
    // this.sharedMetadata.x = Map3D.main.source.userAvatar.container.position.x
    // this.sharedMetadata.y = Map3D.main.source.userAvatar.container.position.y
    // this.sharedMetadata.z = Map3D.main.source.userAvatar.container.position.z
    // this.sharedMetadata.orientation = Map3D.main.source.userAvatar.orientation || 0
    // this.sharedMetadata.ox = Map3D.main.source.userAvatar.container.quaternion.x
    // this.sharedMetadata.oy = Map3D.main.source.userAvatar.container.quaternion.y
    // this.sharedMetadata.oz = Map3D.main.source.userAvatar.container.quaternion.z
    // this.sharedMetadata.ow = Map3D.main.source.userAvatar.container.quaternion.w
    this.setMetadata()
  }

  /** Called when the presence snapshot is updated */
  onSnapshotUpdated = async () => {
    // // Go through snapshot data
    // let snapshot = Server.analytics.getPresenceSnapshot()
    // for (let i = 0 ; i < snapshot.length ; i += 4) {
    //     // Get fields
    //     let id = snapshot[i]
    //     let x = snapshot[i+1]
    //     let y = snapshot[i+2]
    //     let z = snapshot[i+3]
    //     // Warning: Snapshot data is 1 minute outdated, so we should only update a user if they don't already exist
    //     if (this.knownPeers.find(p => p.userID == id))
    //         continue
    //     // Notify p2p system
    //     this.peerDiscovered(id, x, y, z)
    // }
  }

  /** Sends a signal to a remote user. */
  sendSignal(userID, data) {
    // Send it via MessageManager
    this.messages.sendMessage(
      'p2p.avatars.signal',
      { spaceID: this.spaceID, ...data },
      userID,
      false
    )
  }

  /** Called when a message is received via MessageManager */
  onMsg(data, fromID) {
    // Ignore if it's not from this space ID. This is to prevent connecting accidentally to the same user in a different space. This
    // still doesn't fix the issue of a user being in the same space multiple times though...
    if (data.spaceID && data.spaceID != this.spaceID)
      return console.warn(
        `[P2PAvatars] Ignored signal since sender is in a different space. We are in ${this.spaceID} but the signal came from ${data.spaceID}.`
      )

    // Pass signal to P2PAvatars
    this.onSignal(fromID, data)
  }

  /** Create a new P2PAvatar class for the specified user. Subclass can override this to provide it's own P2PAvatar subclass. */
  createAvatarInstance() {
    return new SWebP2PAvatar()
  }

  /** Called when the audio input device changes */
  // onAudioInputChanged() {

  //     // Reset the active stream
  //     this.audio.microphoneStream = this.muted ? null : AudioManager.mixerInputStream

  // }
}
