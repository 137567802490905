// import { FaceStore } from '@vatom/BVatom/plugin'

import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { SnapshotIn, types } from 'mobx-state-tree'

import type { ViewMode } from './View'

export enum TokenTypes {
  VatomBasic = 'vatom_basic', // not mintable
  VatomUnMinted = 'vatom_unminted',
  VatomMintedCustodial = 'vatom_minted_custodial',
  VatomMintedNonCustodial = 'vatom_minted_non_custodial',
  Erc721 = 'erc721',
  Solana = 'solana',
  VatomNew = 'vatom-new'
  // vatom = 'vatom',
  // erc = 'erc'
}

export type crateVesrsionTypes = 'crate-v2' | 'crate-v3'

const ERC721ContractMetadata = types.model('ERC721ContractMetadata', {
  name: types.string,
  description: types.string,
  image: types.string,
  external_link: types.string,
  seller_fee_basis_points: types.number,
  fee_recipient: types.string
})
const ERC721MetadataTrait = types.model('ERC721MetadataTrait', {
  trait_type: types.string,
  value: types.union(types.string, types.number)
})

export type ERC721MetadataSnapshot = SnapshotOut<typeof ERC721Metadata>

export const ERC721Metadata = types.model('ERC721Metadata', {
  name: types.optional(types.string, ''),
  category: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  image: types.optional(types.string, ''),
  // OpenSea additions
  external_uri: types.maybe(types.string),
  attributes: types.array(ERC721MetadataTrait),
  // OpenSea specific
  background_color: types.maybe(types.string),
  animation_url: types.maybe(types.string),
  youtube_url: types.maybe(types.string),
  contract_data: types.maybe(ERC721ContractMetadata)
})

export type ActivitySnaphot = SnapshotOut<typeof Activity>

const Activity = types.model('Activity', {
  date: types.string,
  name: types.string,
  price: types.string,
  initiatorId: types.string,
  recipientId: types.string
})

export type BlockchainInfoSnapshot = SnapshotOut<typeof BlockchainInfo>

export const BlockchainInfo = types.model('BlockchainInfo', {
  tokenId: types.string,
  network: types.maybe(types.string),
  networkName: types.string,
  networkIcon: types.string,
  contractAddress: types.maybe(types.string),
  owner: types.string,
  tokenLink: types.maybe(types.string)
})

export type EditionInfoSnapshot = SnapshotOut<typeof EditionInfo>

export const EditionInfo = types.model('EditionInfo', {
  numbered: types.number,
  scarcity: types.number
})
export enum ListingStatus {
  Unlisted = 'unlisted',
  Active = 'active',
  Pending = 'pending',
  Failed = 'failed',
  Unknown = ''
}
export enum SellChannel {
  Marketplace = 'marketplace',
  Direct = 'direct',
  None = 'none'
}

export type CommerceInfoSnapshot = SnapshotOut<typeof CommerceInfo>

export const CommerceInfo = types.model('CommerceInfo', {
  currency: types.maybe(types.string),
  status: types.maybe(types.enumeration<ListingStatus>(Object.values(ListingStatus))),
  channel: types.maybe(types.enumeration<SellChannel>(Object.values(SellChannel))),
  price: types.union(types.string, types.number),
  error: types.maybe(types.string),
  checkoutUrl: types.maybe(types.string),
  productUrl: types.maybe(types.string),
  saleDate: types.maybe(types.string)
})

export type AnalyticsDataSnapshot = SnapshotOut<typeof AnalyticsData>

export const AnalyticsData = types.model('AnalyticsData', {
  businessId: types.string,
  blueprintId: types.string,
  campaignId: types.string,
  objectDefinitionId: types.string
})

export type StudioInfoSnapshot = SnapshotOut<typeof StudioInfo>

export const StudioInfo = types.model('StudioInfo', {
  businessId: types.string,
  blueprintId: types.string,
  campaignId: types.string,
  objectDefinitionId: types.string
})
// TODO: I think there is a BV structure we can borrow that has VR coords as well
export type PositionSnapshot = SnapshotOut<typeof Position>

export const Position = types.model('Position', {
  type: 'Point',
  coordinates: types.array(types.number)
})
const ARCoordinate = types.model('ARCoordinate', {
  x: types.maybe(types.number),
  y: types.maybe(types.number),
  z: types.maybe(types.number)
})
export const ARTransform = types.model('ARTransform', {
  scale: types.maybe(ARCoordinate),
  translate: types.maybe(ARCoordinate),
  rotate: types.maybe(ARCoordinate)
})

export const AnimationRule = types.model('AnimationRule', {
  on: types.string,
  play: types.maybe(types.string),
  target: types.maybe(types.string),
  value: types.maybe(types.string),
  sound: types.maybe(
    types.model({
      resource_name: types.string,
      is_positional: types.boolean
    })
  )
})

export type ResourceSnapshot = SnapshotOut<typeof Resource>

const ImagePolicy = types.model('ImagePolicy', {
  count_max: types.maybe(types.number),
  field: types.maybe(types.string),
  value: types.maybe(types.union(types.string, types.number, types.boolean)),
  resource: types.maybe(types.string)
})

const Color = types.model('Color', {
  r: types.number,
  g: types.number,
  b: types.number,
  a: types.number
})

const FaceConfig = types.model('FaceConfig', {
  image: types.maybe(types.string),
  scale: types.maybe(types.string),
  image_mode: types.maybe(types.string),
  image_policy: types.maybe(types.array(ImagePolicy)),
  layerImage: types.maybe(types.string),
  empty_image: types.maybe(types.string),
  full_image: types.maybe(types.string),
  padding_start: types.maybe(types.number),
  padding_end: types.maybe(types.number),
  direction: types.maybe(types.string),

  // For video
  cover: types.maybe(types.string),
  video: types.maybe(types.string),

  // For crate
  unlock_key: types.maybe(types.string),
  activate_action: types.maybe(types.string),

  // For 3d
  scene: types.maybe(types.string),
  animation_rules: types.maybe(types.array(AnimationRule)),
  ar_transform: types.maybe(ARTransform),
  auto_rotate: types.maybe(types.boolean),

  bgColor: types.maybe(Color)
})

// const FaceConstraints = types.model('FaceConstraints', {
//   platform: types.string,
//   view_mode: types.string
// })

// const FaceProperties = types.model('FaceProperties', {
//   config: types.maybe(FaceConfig),
//   constraints: FaceConstraints,
//   resources: types.array(types.string),
//   display_url: types.string
// })

// export type FaceStoreType = Instance<typeof FaceStore>
// export const FaceStore = types.model('FaceStore', {
//   id: types.string,
//   properties: FaceProperties,
//   // meta: {};
//   template: types.string
// })

export const Resource = types.model('Resource', {
  name: types.string,
  url: types.maybe(types.string),
  type: types.string,
  ar_transform: types.maybe(ARTransform),
  animation_rules: types.maybe(types.array(AnimationRule)),
  auto_rotate: types.maybe(types.boolean)
})

const FaceConstraints = types.model('FaceConstraints', {
  platform: types.optional(types.string, ''),
  view_mode: types.optional(types.string, '')
})

const FaceProperties = types
  .model('FaceProperties', {
    // config: types.maybe(FaceConfig),
    // config: types.maybe(FacePropertiesConfig),
    // config: types.optional(types.frozen(), {}),
    config: types.optional(types.string, JSON.stringify({})),
    // config: types.maybe(types.frozen({})),
    // // config: types.union(types.null, types.undefined, types.frozen()),
    // config: types.optional(
    //   // types.union(types.null, types.undefined, types.maybe(FaceConfig), types.frozen()),
    //   types.union(types.null, types.undefined, types.frozen()),
    //   {}
    // ),
    constraints: types.optional(FaceConstraints, {}),
    resources: types.optional(types.array(types.string), []),
    display_url: types.optional(types.string, '')
  })
  .views(self => ({
    get parsedConfig(): any {
      try {
        const config = JSON.parse(self.config)
        return config
      } catch (e) {
        console.error('Error parsing face config', self.config, e)
        return {}
      }
    }
  }))

export const FaceStore = types.model('FaceStore', {
  id: types.string,
  properties: types.optional(FaceProperties, {}),
  // meta: {};
  template: types.string
})

export type FaceStoreType = Instance<typeof FaceStore>
export type FaceStoreSnapshot = SnapshotOut<typeof FaceStore>

export enum ResourceFaceType {
  image = 'native://image',
  video = 'native://video',
  generic3D = 'native://generic-3d',
  imagePolicy = 'native://image-policy'
}

export const FaceType = types.model('FaceType', {
  type: types.string,
  id: types.maybe(types.enumeration<ResourceFaceType>(Object.values(ResourceFaceType))),
  image: types.maybe(types.string),
  animation_url: types.maybe(types.string),
  image_type: types.maybe(types.string),
  animation_url_type: types.maybe(types.string)
})

export const Token = types
  .model('Token')
  .props({
    id: types.identifier,
    type: types.string,
    parentId: types.string,
    owner: types.string,
    author: types.string,
    lastOwner: types.maybe(types.string),
    modified: types.maybe(types.Date),
    shouldShowNotification: types.maybe(types.optional(types.boolean, true)),
    created: types.Date,
    tokenUri: types.maybeNull(types.string),
    vatomFaces: types.maybe(types.array(FaceStore)),
    faces: types.maybe(types.array(FaceType)),
    contractAddress: types.maybe(types.string),
    network: types.maybe(types.string),
    quantity: types.optional(types.number, 1),
    contractType: types.maybe(types.string)
  })
  .actions(self => ({
    setShouldShowNotification(shouldShowNotification: boolean) {
      self.shouldShowNotification = shouldShowNotification
    },
    performAction(name: string, payload?: any, extra?: any): Promise<any> {
      throw new Error("Subclasses must override Token's performAction()")
    },
    setModified(modified: Date) {
      self.modified = modified
    },
    unlockKey(view: ViewMode | undefined): string | undefined {
      console.warn(
        'Base Token unlockKey() implementation is being called. This should be overridden by the subclass.'
      )
      return undefined
    }
  }))
  .views(self => ({
    get threeDInfo(): {
      url?: string
      autoRotate: boolean
      // animationRules: AnimationRule[]
    } {
      throw new Error("Subclasses must override Token's get threeDInfo()")
    },
    get displayImage(): string | undefined {
      throw new Error("Subclasses must override Token's get image()")
    },
    get isBusinessFolder(): boolean {
      console.warn(
        'Base Token isBusinessFolder() implementation is being called. This should be overridden by the subclass.'
      )
      return false
    },
    get isCrate(): boolean {
      console.warn(
        'Base Token isCrate() implementation is being called. This should be overridden by the subclass.'
      )
      return false
    },
    get getCrateVersion(): crateVesrsionTypes | undefined {
      console.warn(
        'Base Token getCrateVersion() implementation is being called. This should be overridden by the subclass.'
      )
      return undefined
    },
    getFaceId(mode: ViewMode): string {
      throw new Error("Subclasses must override Token's getFaceId()")
    },
    get actions(): string[] {
      throw new Error("Subclasses must override Token's get actions()")
    },
    get metadata(): ERC721MetadataSnapshot {
      throw new Error("Subclasses must override Token's get metadata()")
    },
    get commerceInfo(): CommerceInfoSnapshot | undefined {
      // Subclasses can override Token's get commerceInfo()
      return undefined
    },
    get provenance(): ActivitySnaphot[] {
      // Subclasses can override Token's get provenance()
      return []
    },
    get editionInfo(): EditionInfoSnapshot | undefined {
      // Subclasses can override Token's get editionInfo()xz
      return undefined
    },
    get blockchainInfo(): BlockchainInfoSnapshot | undefined {
      // Subclasses can override Token's get blockchainInfo()
      return undefined
    },
    get studioInfo(): StudioInfoSnapshot | undefined {
      // Subclasses can override Token's get studioInfo()
      return undefined
    },
    get shareInfo(): string | undefined {
      // Subclasses can override Token's get studioInfo()
      return undefined
    },
    get analyticsData(): AnalyticsDataSnapshot | undefined {
      // Subclasses can override Token's get studioInfo()
      return undefined
    },
    get position(): PositionSnapshot | undefined {
      // Subclasses can override Token's get position()
      return undefined
    },
    get resources(): ResourceSnapshot[] {
      // Subclasses can override Token's get resources()
      return []
    },
    get supportedAddresses() {
      throw new Error("Subclasses must override Token's get supportedAddresses()")
    },
    get isMinted(): boolean | undefined {
      throw new Error("Subclasses must override Token's get supportedAddresses()")
    },
    get royalties(): number | undefined {
      // throw new Error("Subclasses must override Token's get royalties()")
      return undefined
    },
    get hasCardView(): boolean {
      throw new Error("Subclasses must override Token's get hasCardView()")
    },
    getTokenType() {
      if (self.type === 'vatom-new') {
        return TokenTypes.VatomNew
      }
      if (self.type === 'vatom') {
        if (this.blockchainInfo?.network) {
          if (this.blockchainInfo?.tokenLink) {
            // if (token.blockchainInfo?.owner === custodialAccount?.value) {
            return TokenTypes.VatomMintedCustodial // It has to be custodial of else it would not be returned by inventory call
            // } else {
            // setTokenType(TokenType.VatomMintedNonCustodial)
            // }
          } else {
            return TokenTypes.VatomUnMinted
          }
        } else {
          return TokenTypes.VatomBasic
        }
      } else {
        if (self.tokenUri?.includes('blockv')) {
          return TokenTypes.VatomMintedNonCustodial
        } else if (self?.type === 'solana') {
          return TokenTypes.Solana
        } else {
          return TokenTypes.Erc721
        }
      }
    },
    // async getChildren(): Promise<TokenType[]> {
    //   // Subclasses can override Token's getChildren()
    //   return []
    // },

    getNetworkName(network: string | undefined) {
      switch (network) {
        case 'polygon':
        case 'matic_mainnet':
          return 'Polygon Mainnet'
        case 'matic_testnet':
          return 'Polygon Testnet'
        case 'bsc_mainnet':
          return 'Binance Mainnet'
        case 'bsc_testnet':
          return 'Binance Testnet'
        case 'mainnet':
          return 'Ethereum Mainnet'
        case 'testnet':
          return 'Ethereum Testnet'
        case 'kaleido':
          return 'Kaleido'
        case 'palm_mainnet':
          return 'Palm'
        case 'solana':
          return 'Solana'
        case 'casper_testnet':
          return 'Casper Testnet'
        case 'casper_mainnet':
          return 'Casper'
        case 'sepolia':
          return 'Ethereum Sepolia'
        default:
          return 'Unknown'
      }
    },
    getNetworkIcon(network: string | undefined) {
      switch (network) {
        case 'polygon':
        case 'matic_mainnet':
        case 'matic_testnet':
          return require('../res/network-polygon.png')
        case 'bsc_mainnet':
        case 'bsc_testnet':
          return require('../res/network-dex.png')
        case 'mainnet':
        case 'testnet':
          return require('../res/network-ethereum.png')
        case 'kaleido':
          return require('../res/network-kaleido.png')
        case 'palm_mainnet':
          return require('../res/network-palm.png')
        case 'solana':
          return require('../res/network-solana.png')
        case 'casper_testnet':
          return require('../res/network-casper.png')
        case 'casper_mainnet':
          return require('../res/network-casper.png')
        default:
          return require('../res/pixel.png')
      }
    },
    getTokenLink(network: string, contract: string, txId?: string): string | undefined {
      if (!network || !contract) return

      const tokenId = self.id
      switch (network) {
        case 'polygon':
        case 'matic_mainnet':
          return `https://polygonscan.com/token/${contract}?a=${tokenId}`
        case 'matic_testnet':
          return `https:///mumbai.polygonscan.com/token/${contract}?a=${tokenId}`
        case 'bsc_mainnet':
          return `https://bscscan.com/token/${contract}?a=${tokenId}`
        case 'bsc_testnet':
          return `https://testnet.bscscan.com/token/${contract}?a=${tokenId}`
        case 'mainnet':
          return `https://etherscan.io/token/${contract}?a=${tokenId}`
        case 'testnet':
          return `https://goerli.etherscan.io/token/${contract}?a=${tokenId}`
        case 'kaleido':
          return `https://etherscan.io/token/${contract}?a=${tokenId}`
        case 'palm_mainnet':
          return `https://explorer.palm.io/token/${contract}?a=${tokenId}`
        case 'solana':
          return `https://solscan.io/token/${contract}`
        case 'casper_testnet':
          return `https://testnet.cspr.live/deploy/${txId}`
        case 'casper_mainnet':
          return `https://cspr.live/deploy/${txId}`
        default:
          return 'Unknown'
      }
    }
  }))

export type TokenType = Instance<typeof Token>
export type TokenSnapshot = SnapshotOut<typeof Token>
export type TokenMap = Record<string, TokenSnapshot>
