import type { RootSDKStoreType } from '@vatom/sdk/core'
import { SessionSource, SessionToken, SessionType, Utils } from '@vatom/sdk/core'
import { allSettled, ensureAppConfig, getConfig } from '@vatom/sdk/react'
import assert from 'assert'
import axios from 'axios'
import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { getRoot, getSnapshot, types } from 'mobx-state-tree'
import * as QueryString from 'query-string'

import logger from '../../logger'
import type { SpaceType } from '../Spaces'
import type { IdentitySnapshot } from '../User/UserIdentityModel'
import { IdentityType, UserIdentityModel } from '../User/UserIdentityModel'

export const CSSProperties = types.model('CSSProperties', {
  color: types.maybe(types.string)
})

export const LogoTheme = types.model('LogoTheme', {
  src: types.string,
  style: CSSProperties
})

export const PageConfigHeaderTheme = types.model('PageConfigHeaderTheme', {
  logo: types.union(types.string, LogoTheme)
})

export const PageConfigTheme = types.model('PageConfigTheme', {
  header: PageConfigHeaderTheme,
  iconTitle: types.maybe(CSSProperties),
  emptyContainer: types.maybe(CSSProperties),
  icon: types.maybe(CSSProperties),
  main: CSSProperties,
  emptyState: CSSProperties,
  pointsHeader: types.maybe(CSSProperties),
  pageTheme: types.maybe(types.string)
})

export const PageConfigText = types.model('PageConfigText', {
  emptyState: types.string
})

export const PageConfigIconFeatures = types.model('PageConfigIconFeatures', {
  badges: types.maybe(types.boolean),
  editions: types.maybe(types.boolean),
  titles: types.maybe(types.boolean)
})

export const PageConfigVatomFeature = types.model('PageConfigVatomFeature', {
  autoOpen: types.maybe(types.array(types.string)),
  disableNewTokenToast: types.boolean,
  ignoreNewTokenToast: types.array(types.string),
  pinned: types.maybe(types.array(types.string))
})

export const FooterIcon = types.model('FooterIcon', {
  src: types.string,
  title: types.string,
  id: types.string,
  link: types.string
})

export const PageConfigFooterFeatureIcon = types.model('PageConfigVatomFeature', {
  id: types.maybe(types.string),
  link: types.maybe(types.string),
  src: types.maybe(types.string),
  title: types.maybe(types.string),
  initial: types.maybe(types.boolean),
  default: types.maybe(types.boolean)
})

export type BusinessIconType = Instance<typeof PageConfigFooterFeatureIcon>

export const PageConfigFooterFeature = types.model('PageConfigFooterFeature', {
  enabled: types.boolean,
  icons: types.maybe(types.array(PageConfigFooterFeatureIcon))
})

export const PageConfigPointsHeaderFeature = types.model('PageConfigPointsHeaderFeature', {
  enabled: types.maybe(types.boolean),
  position: types.maybe(types.string),
  title: types.maybe(types.string),
  channel: types.maybe(types.string)
})

export const PageConfigFeatures = types.model('PageConfigFeatures', {
  footer: PageConfigFooterFeature,
  vatom: PageConfigVatomFeature,
  pointsHeader: types.maybe(PageConfigPointsHeaderFeature),
  icon: types.maybe(PageConfigIconFeatures),
  coinsHeader: types.maybe(
    types.model('PageConfigCoinsHeader', {
      enabled: types.maybe(types.boolean)
    })
  )
})

export type PageConfigType = Instance<typeof PageConfig>
export type PageConfigSnapshot = SnapshotOut<typeof PageConfig>

export const PageConfig = types.model('PageConfig', {
  showHomePageIcon: types.boolean,
  icon: types.string,
  theme: PageConfigTheme,
  text: PageConfigText,
  features: PageConfigFeatures
})

const BrandConfigColorsTypes = types.model('BrandConfigColorsTypes', {
  light: types.string,
  dark: types.string
})
const BrandConfigColors = types.model('BrandConfigColors', {
  background: BrandConfigColorsTypes,
  primary: BrandConfigColorsTypes,
  text: BrandConfigColorsTypes,
  primaryText: BrandConfigColorsTypes
})

const BrandConfig = types.model('BrandConfig', {
  icon: types.maybe(types.string),
  mode: types.maybe(types.enumeration(['light', 'dark', 'system'])),
  colors: BrandConfigColors
})

export type BrandConfigColors = Instance<typeof BrandConfigColors>

export interface BusinessBrandConfig {
  icon?: string
  mode?: 'light' | 'dark' | 'system'
  colors?: {
    background: {
      light: string
      dark: string
    }
    primary: {
      light: string
      dark: string
    }
    text: {
      light: string
      dark: string
    }
    primaryText: {
      light: string
      dark: string
    }
  }
}

export type BusinessSnapshot = SnapshotOut<typeof Business>
export const Business = types.model('Business', {
  id: types.string,
  name: types.string,
  displayName: types.string,
  logoSrc: types.string,
  createdAt: types.Date,
  pageConfig: PageConfig,
  defaultCampaignId: types.string,
  brandConfig: BrandConfig
})

export interface BusinessCommunities {
  businessId: BusinessSnapshot['id']
  icon: string
  id: string
  isArchived: boolean
  isDefault: boolean
  name: string
}

export interface BusinessQuery {
  name?: string
  join?: boolean
  context?: string
}

export interface optionFieldInterface {
  value: string
  title: string
}

export type fieldsTypes =
  | 'boolean'
  | 'countryAndRegion'
  | 'date'
  | 'email'
  | 'enum'
  | 'multibool'
  | 'number'
  | 'string'
  | 'tel'

export interface BusinessFields {
  name: string
  required: boolean
  title: string
  type: fieldsTypes
  placeholder?: string
  disabled?: boolean
  autoComplete?: string
  pattern?: string
  subtitle?: string
  min?: number
  max?: number
  value?: string | optionFieldInterface[]
  options?: optionFieldInterface[]
}

export interface BusinessTerms {
  accepted?: string
  businessId: string
  latest: string
  markdown: string | null
  name: string
  url: string
  urls: string[]
  required: boolean
  prompt: boolean
}

export type ProfileSnapshot = SnapshotOut<typeof Profile>
export const Profile = types.model('Profile', {
  id: types.string,
  name: types.string,
  picture: types.string,
  default_business_id: types.maybe(types.string),
  birthday: types.string,
  preferred_username: types.maybe(types.string),
  sub: types.maybe(types.string)
})

export type PublicProfileSnapshot = SnapshotOut<typeof PublicProfile>

export const PublicProfile = Profile.named('PublicProfile').props({
  identities: types.array(UserIdentityModel)
})

export enum GrantType {
  Password = 'password',
  Code = 'code'
}

export interface Coordinates {
  latitude: number
  longitude: number
}

export enum PageThemeEnum {
  Dark = 'dark',
  Light = 'light',
  System = 'system'
}

export interface Account {
  billingInfo: BillingInfo
  sellerAccount: AccountInfo
  license: License
  seats: any
  invites: any
  stripeId: string
  ethAccount: string
}

export interface AccountInfo {
  loginLink: string
  status: AccountStatus
}

export interface BillingInfo {
  type: string
  creditCard?: {
    expiration?: {
      year: number
      month: number
    }
    cardNumberText?: string
  }
}

export enum AccountStatus {
  Enabled = 'enabled',
  InProcess = 'inProcess',
  Disabled = 'disabled'
}

export interface License {
  id: string
  name: string
  displayName: string
  productId: string
  // metaData: any;
  description?: string
  features?: string[]
  additionalFeatures?: string[]
  popular?: boolean
  isActive?: boolean
  relatedPlans?: {
    monthly?: {
      id: string
      amount: number
    }
    yearly?: {
      id: string
      amount: number
    }
  }
  pricingTiers?: Tier[]
  unitType: string
  unitPlanId?: string
  // Optional business info
  subscriptionId?: string
  subscriptionStatus?: SubscriptionStatus
  planId?: string

  level: number
  limits: any
}

export interface Tier {
  max: number
  unit_amount: number
}

export enum SubscriptionStatus {
  TrialExpiring = 'trial_expiring',
  Trialing = 'trialin',
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  Incomplete_Expired = 'incomplete_expired',
  Past_Due = 'past_due',
  Unpaid = 'unpaid'
}

export const VatomIncApiStore = types
  .model('VatomIncApiStore')
  .props({
    // identities: types.array(Identity),
    // profile: types.maybe(Profile),
    // business: types.maybe(Business)
  })
  // .extend(withRootSDKStore)
  .views(self => ({
    get rootStore(): any {
      // @TODO: Circular dependency:
      return getRoot<RootSDKStoreType>(self) as any
    }
  }))
  .actions(self => ({
    async mergeAccounts(sourceAccessToken: string, targetAccessToken: string) {
      await axios.post(
        '/users/merge',
        {
          sourceAccessToken,
          targetAccessToken
        },
        { baseURL: getConfig().api.oidc }
      )
    },
    async loginWithOtp(username: string, type: string, otp: string) {
      logger.info('[VatomInc loginWithOtp]', username, type, otp)
      const config = await ensureAppConfig()
      const { data } = await axios.post(
        '/token',
        new URLSearchParams({
          grant_type: 'urn:vatominc:params:oauth:grant-type:otp',
          client_id: config.authentication.clientId,
          scope: 'profile offline_access',
          identity_type: type,
          identity_value: username,
          otp
        }),
        { baseURL: config.api.oidc }
      )

      logger.info('[VatomInc loginWithOtp data]', data)

      return data
    },
    async performTokenExchange(token: string | undefined) {
      assert.notStrictEqual(token, undefined)
      const clientId = (await ensureAppConfig()).authentication.clientId

      logger.info('[VatomInc performTokenExchange]', getConfig().api.oidc, clientId, token)

      const { data } = await axios.post(
        '/token',
        new URLSearchParams({
          grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
          client_id: clientId,
          resource: 'https://api.blockv.io',
          subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
          subject_token: token!
        }),
        { baseURL: getConfig().api.oidc }
      )

      const date = new Date()

      // Add to sessionInfo
      self.rootStore.dataPool.sessionStore.add({
        type: SessionType.JWT,
        source: SessionSource.VatomNetwork,
        value: getSnapshot(
          SessionToken.create({
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
            expiresAt: new Date(
              new Date(date).setSeconds(date.getSeconds() + data.expires_in)
            ).getTime()
          })
        )
      })
    },
    // setIdentities(identities: IdentitySnapshot[]) {
    //   self.identities.replace(identities)
    // },
    async getIdentities(): Promise<IdentitySnapshot[]> {
      const { data } = await self.rootStore.service.oidc.get(`/me/identities`)
      const identities: IdentitySnapshot[] = data
        .map(($: IdentitySnapshot) => {
          const identity: IdentitySnapshot = {
            ...$,
            name: $.custodial ? 'Custodial' : Utils.toTitleCase($.name)
          }
          return identity
        })
        .filter(
          ($: IdentitySnapshot) =>
            $.type === IdentityType.BlockV ||
            $.type === IdentityType.Eth ||
            $.type === IdentityType.Email ||
            $.type === IdentityType.Phone ||
            $.type === IdentityType.Solana
        )
        .sort((a: IdentitySnapshot, b: IdentitySnapshot) => {
          if (a.custodial) {
            return -1
          }
          if (b.custodial) {
            return 1
          }
          if (a.preferred && !b.preferred) return -1

          if (b.preferred && !a.preferred) return 1
          // a must be equal to b
          return 0
        })

      // logger.info('IDENTITIES', identities)
      // this.setIdentities(identities)

      // identities
      //   .filter(i => !i.custodial)
      //   .forEach(id => {
      //     const key: string = id.type
      //     if (key === 'eth') {
      //       self.rootStore.dataPool.sessionStore.add({
      //         type: SessionType.Eth,
      //         value: id.value,
      //         source: SessionSource.Vatom
      //       })
      //     } else if (key === 'sol') {
      //       self.rootStore.dataPool.sessionStore.add({
      //         type: SessionType.Sol,
      //         value: id.value,
      //         source: SessionSource.Vatom
      //       })
      //     }
      // })

      return identities
    }

    // async getProfile() {
    //   self.profile = await self.rootStore.service.oidc.get(`/me`)
    // },
    // async updateProfile(updates: Partial<ProfileSnapshot>) {
    //   self.profile = await self.rootStore.service.oidc.patch(`/me`, updates)
    // }
  }))
  .volatile(self => ({
    async getPublicProfile(userId: string): Promise<PublicProfileSnapshot | undefined> {
      try {
        const response = await fetch(`https://users.vatom.com/${userId}`)
        if (response.status !== 200) {
          return undefined
        }

        const resp = await response.json()

        // If the user is undefined, the response will be {}
        const id = resp.id || resp.sub
        if (!id) return undefined

        return {
          ...resp,
          id
        }
      } catch (err) {
        return undefined
      }
    },
    async getPublicBusinessProfile(businessId: string): Promise<BusinessSnapshot | undefined> {
      try {
        const response = await fetch(`https://businesses.vatom.com/${businessId}`, {
          headers: {
            'Cache-Control': 'no-cache'
          }
        })
        if (response.status !== 200) {
          return undefined
        }

        return await response.json()
      } catch (err) {
        return undefined
      }
    },
    async campaignUserInfo(business: BusinessSnapshot, userId: string | undefined) {
      try {
        if (!userId) return undefined
        if (!business) return undefined

        logger.info('defaultCampaignId', business.defaultCampaignId)

        const campaignId = business.defaultCampaignId
        const businessId = business.id

        const key = `userInfo:${userId}:${businessId}:${campaignId}`

        logger.info('key ====>', key)

        return undefined
      } catch (error) {
        logger.info(error)
        return undefined
      }
    },
    async getUserPoints(userId: string, campaignId: string) {
      try {
        const response: any = await self.rootStore.service.points.get(
          `u/${userId}/campaign/${campaignId}/points`
        )
        return response.data
      } catch (error) {
        logger.info(error)
        return undefined
      }
    },
    async updateUserCampaignInfo(payload: any, business: BusinessSnapshot, accessToken: string) {
      const campaignId = business.defaultCampaignId
      const businessId = business.id

      const body = {
        businessId,
        campaignId,
        data: payload
      }
      await self.rootStore.service.oidc.patch(`/api/me/info`, body)

      // try {
      //   const response: any = await self.rootStore.service.points.get(
      //     `/u/${userId}/campaign/${campaignId}/points`
      //   )
      //   return response.data
      // } catch (error) {
      //   logger.info(error)
      //   return undefined
      // }
    },
    async updateLocation(coords: Coordinates) {
      const { data } = await self.rootStore.service.geo.get(
        `/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=${
          getConfig().googleMapsKey
        }`
      )

      let locality, region, country, postal_code

      for (const component of data.results[0].address_components) {
        if (component.types.includes('locality')) {
          locality = component.short_name
        } else if (component.types.includes('administrative_area_level_1')) {
          region = component.short_name
        } else if (component.types.includes('country')) {
          country = component.short_name
        } else if (component.types.includes('postal_code')) {
          postal_code = component.short_name
        }
      }
      await self.rootStore.service.oidc.patch(`/me`, {
        location: {
          latitude: coords.latitude,
          longitude: coords.longitude,
          locality,
          region,
          country,
          postal_code
        }
      })
      // Track Analytics Event
      self.rootStore.analytics.event('updateLocation', coords)
    },

    async getAccount(businessId: string): Promise<Account> {
      const response = await self.rootStore.service.billing.get(`/b/${businessId}/account`)
      return response.data
    },

    async findBusinesses(query: BusinessQuery): Promise<BusinessSnapshot[]> {
      const qString = QueryString.stringify(query, {
        skipNull: true
      })
      const response = await self.rootStore.service.studio.get(`/b?${qString}`)
      return response.data
    },

    async getBusiness(id: string, publicOnly: boolean): Promise<BusinessSnapshot> {
      const response = await self.rootStore.service.studio.get(
        `/b/${id}?publicOnly=${publicOnly}`,
        {
          headers: {
            'Cache-Control': 'no-cache'
          }
        }
      )

      return response.data
    },

    async getBusinesses(ids: string[]): Promise<BusinessSnapshot[]> {
      const response = await self.rootStore.service.studio.post(`/b/search`, {
        ids
      })

      return response.data
    },

    async getBusinessTerms(businessId?: string): Promise<BusinessTerms[]> {
      const { data } = await self.rootStore.service.oidc.get(
        `/api/me/terms${businessId ? '?business-id=' + businessId : ''}`
      )
      return data
    },

    async getBusinessForms(campaignId?: string, businessId?: string): Promise<BusinessFields[]> {
      const formQ = new URLSearchParams({
        flow: 'popup',
        'business-id': businessId ?? '',
        'campaign-id': campaignId ?? ''
      })

      const formRes: any = await self.rootStore.service.oidc.get(
        `/api/me/info/form?${formQ.toString()}`
      )

      return formRes.data ?? []
    },

    async decideBusinessTerms(termsName: string, accepted: boolean): Promise<BusinessTerms[]> {
      const { data } = await self.rootStore.service.oidc.patch(`/api/me/terms/${termsName}`, {
        accepted
      })
      return data
    },

    async decideBusinessForms(values: any, campaignId?: string, businessId?: string): Promise<any> {
      try {
        const body = JSON.stringify({
          businessId: businessId,
          campaignId: campaignId,
          data: values,
          flow: 'popup'
        })
        const res = await self.rootStore.service.oidc.post(`/api/me/info/form`, body)
        const response = JSON.parse(res)
        return response
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response) {
          return error?.response
        } else {
          return error
        }
      }
    },

    async getSpace(idOrAlias: string): Promise<SpaceType> {
      const payload = {
        accepted: true
      }
      const { data } = await self.rootStore.service.events.get(`/spaces/${idOrAlias}`, payload)
      return data
    },

    async getCommunities(businessId: string): Promise<BusinessCommunities[]> {
      const response = await self.rootStore.service.events.get(`/b/${businessId}/communities`)
      return response.data
    }
  }))
  .volatile(self => ({
    async getBusinessWithCommunities(ids: string[]): Promise<any> {
      const businesses = await self.getBusinesses(ids)
      const businessWithCommunities = allSettled(
        businesses.map(async b => {
          const communities = await self.getCommunities(b.id)
          console.log('LOG: > businessWithCommunities > communities:', communities)
          return {
            ...b,
            communities: communities ?? []
          }
        })
      )
      console.log(
        'LOG: > businessWithCommunities > businessWithCommunities:',
        businessWithCommunities
      )
      return businessWithCommunities
    }
  }))

export type VatomIncApi = Instance<typeof VatomIncApiStore>
