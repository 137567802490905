import './src/global'
import 'react-native-reanimated'
import 'react-native-gesture-handler'

// import { LogBox } from 'react-native'
import { registerRootComponent } from 'expo'

import App from './src/app/App'

global.exports = {}

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App)

// NOTE: for demos and presentations, we want to hide the logs
// LogBox.ignoreLogs(['Warning: ...']) // Ignore log notification by message
// LogBox.ignoreAllLogs() //Ignore all log notifications

// Test a change to kick off cache
