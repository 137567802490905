import type { NetInfoState, NetInfoSubscription } from '@react-native-community/netinfo'
import NetInfo, { NetInfoStateType, NetInfoWifiState } from '@react-native-community/netinfo'
import * as Network from 'expo-network'
import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import logger from '../../logger'
import { withRootStore } from '../extensions'

/**
 * A NetworkStore model.
 */

export const NetworkStore = types
  .model('NetworkStore')
  .props({
    ipAddress: types.optional(types.string, '0.0.0.0'),
    isNetworkConnected: types.optional(types.boolean, true)
  })
  .extend(withRootStore)
  .volatile(self => {
    const netInfoState = {
      type: NetInfoStateType.unknown,
      isConnected: null,
      isInternetReachable: null,
      details: null
    } as NetInfoState
    const eventSubscriptions: NetInfoSubscription[] = []
    return {
      eventSubscriptions,
      netInfoState
    }
  })
  .actions(self => ({
    setIpAddress(ip: string) {
      self.ipAddress = ip
    },
    setIsNetworkConnected(value: boolean) {
      self.isNetworkConnected = value
    },
    setNetInfo(netInfoState: NetInfoState) {
      logger.info('network-store setNetInfo', netInfoState)
      // NOTE: good place to check if network is accessible
      // if (!self.isNetworkConnected && netInfoState.isConnected) {
      //   logger.info('Reconnected....', !self.isNetworkConnected, netInfoState.isConnected)
      // }
      self.netInfoState = netInfoState
      this.setIsNetworkConnected(!!netInfoState.isConnected)
      // if (netInfoState.type === NetInfoStateType.wifi) {
      //   const state = netInfoState as NetInfoWifiState
      //   this.setIpAddress(state.details.ipAddress)
      // }
    }
  }))
  .actions(self => ({
    async fetchIpAddress() {
      const ipAddress = await Network.getIpAddressAsync()
      self.setIpAddress(ipAddress)
      return ipAddress
    }
  }))
  .actions(self => {
    const unsubscribe = NetInfo.addEventListener(state => self.setNetInfo(state))
    self.eventSubscriptions.push(unsubscribe)
    const afterAttach = async () => {
      try {
        const netInfoState = await NetInfo.fetch()
        self.setNetInfo(netInfoState)
        await self.fetchIpAddress()
        logger.info('network-store afterAttach', netInfoState)
      } catch (error) {
        logger.error('network-store', error)
      }
    }

    const beforeDestroy = () => {
      self.eventSubscriptions.map(unsubscribe => unsubscribe())
      unsubscribe()
    }

    return { afterAttach, beforeDestroy }
  })

/**
 * The NetworkStore instance.
 */
export type NetworkStoreType = Instance<typeof NetworkStore>

/**
 * The data of a NetworkStore.
 */
export type NetworkStoreSnapshot = SnapshotOut<typeof NetworkStore>
