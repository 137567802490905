import { useEffect } from 'react'
import { Platform } from 'react-native'
import { useAppConfig } from '@vatom/sdk/react'

import Alert from '../components/Alert'

export const useBrowserCompatibilityWarning = () => {
  const config = useAppConfig()
  useEffect(() => {
    if (!config.data) {
      return
    }
    // Check if any pattern in the array matches the userAgent
    const isInvalid = config.data.browserCompatbilityRegexes?.some(pattern => {
      const regex = new RegExp(pattern)
      return !regex.test(navigator.userAgent)
    })

    if (Platform.OS === 'web' && isInvalid) {
      Alert.showError({
        code: 99999
      })
    }
  }, [config.data])
}
