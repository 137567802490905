import { useCallback, useEffect } from 'react'
import { Platform } from 'react-native'
import { useIsAuthed } from '@vatom/sdk/react'
import { BroadcastChannel } from 'broadcast-channel'

import { useAuthLogout } from './useAuthLogout'

const isWeb = Platform.OS === 'web'

const CHANNEL_NAME = 'session-user'

const channel = isWeb ? new BroadcastChannel(CHANNEL_NAME) : undefined

const messageName = 'session-logout'

const sendLogoutMessage = () => {
  if (isWeb) {
    channel?.postMessage(messageName)
    channel?.close()
  }
}

const useBroadcastSession = () => {
  const isAuthed = useIsAuthed()
  const { doLogout } = useAuthLogout()

  const handler = useCallback(
    async (msg: string) => {
      console.log('LOG: useBroadcastSession > message:', msg)
      if (msg === messageName) {
        if (isAuthed) {
          await doLogout({ fromBroadcast: true })
        }
      } else {
        console.warn('useBroadcastSession: unhandled message:', msg)
      }
    },
    [doLogout, isAuthed]
  )

  useEffect(() => {
    isAuthed && channel?.addEventListener('message', handler)

    return () => {
      isAuthed && channel?.removeEventListener('message', handler)
    }
  }, [handler, isAuthed])
}

export { sendLogoutMessage, useBroadcastSession }
