import { Platform } from 'react-native'
import { AppConfigurationSchema as AppConfigurationModel, AuthSchema } from '@vatom/models'
import type { z } from 'zod'

export const AppConfigurationSchema = AppConfigurationModel.transform(values => ({
  ...values,
  get isBusinessLocked() {
    return values.businessId !== 'system'
  },
  oidcClientId: Platform.OS === 'web' ? values.oidcClientId : 'wallet-rn',
  authentication: AuthSchema.parse({
    clientId: Platform.OS === 'web' ? values.oidcClientId : 'wallet-rn',
    discoveryUrl: values.oidcAuthority,
    redirectUri: 'com.vatom://auth',
    scopes: ['openid', 'profile', 'email', 'offline_access']
  }),
  viewerId: Platform.OS === 'web' ? values.viewerId : 'wallet-rn'
}))

export type AppConfig = z.infer<typeof AppConfigurationSchema>
export type AppConfigInput = z.input<typeof AppConfigurationSchema>
