import Peer from 'simple-peer'
import { v4 as uuidv4 } from 'uuid'

import constants from '../constants'

/** Last connection index */
let LastConnectionIndex = 1

/**
 * This class represents a single connection to a remote peer. This class is responsible for fetching
 * and processing data and audio from that peer. It's a one-way connection that only reads incoming
 * streams.
 */
export default class P2PAvatar {
  /** @type {P2PAvatars} Manager reference */
  manager = null

  /** User ID of this user */
  userID = ''

  /** Index of this connection */
  index = LastConnectionIndex++

  /** Is outgoing or incoming connection */
  isInitiator = false

  /** Connection ID */
  connectionID = ''

  /** Number of signals sent */
  signalsSent = 0
  signalsReceived = 0

  /** @type {any} Remote side's metadata */
  metadata = {}
  metadataReceived = false

  /** True if connected */
  isConnected = false

  /** Distance from the user, 0 if unknown. */
  distance = 0

  /** True if we have sent the audio stream to this user */
  hasSentAudioStream = false

  /** True if we've received an audio stream from this peer */
  hasReceivedAudioStream = false

  /** @type {MediaStream} The incoming audio from this user, if any */
  incomingAudioStream = null

  /** Returns the output audio range of this remote peer, in meters. Override this to customize audio range for this user. */
  get audioRadius() {
    return 10
  }

  /** True if this peer should be able to hear us. Note that this is the inverse situation to the `audioRadius` field, which controls wether we can hear them. */
  get shouldSendAudio() {
    return this.distance > 0 && this.distance < 15
  }

  /** The amount of time after the remote peer shouldn't be able to hear us, until we remove our outgoing stream from this connection */
  streamRemovalTime = 5000

  /** Statistics */
  stats = {
    /** Ping time in milliseconds, -1 if not fetched yet */
    ping: -1,

    /** Read and write speed per second */
    bytesReadPerSecond: 0,
    bytesWrittenPerSecond: 0
  }

  /**
   * Start the connection process.
   *
   * @param {boolean} initiator True if this is an outgoing connection, false if incoming
   * @param {string} connectionID The connection ID. Can be blank if initiating, but must be set for incoming connections.
   */
  start(initiator = true, connectionID = null) {
    // Get audio stream to send
    console.debug(
      `[P2PAvatar #${this.index}] Starting connection. user=${this.userID} initiator=${initiator}`
    )
    let stream = this.shouldSendAudio ? this.manager.audio.microphoneStream : null

    // Create peer
    this.isInitiator = initiator
    this.connectionStartedAt = Date.now()
    this.connectionID = connectionID || uuidv4()
    this.peer = new Peer({
      initiator,
      config: {
        iceServers: constants.iceServers /*, iceTransportPolicy: relayOnly ? 'relay' : 'all'*/
      },
      stream,
      objectMode: true,
      channelConfig: {
        ordered: false
      }
    })

    // Notify stream sent
    if (stream) {
      this.audioStreamSentAt = Date.now()
      this.audioStreamSent = stream
      this.hasSentAudioStream = true
    }

    // Add listeners
    this.peer.on('signal', data => this.onSignalSend(data))
    this.peer.on('connect', e => this.onConnect())
    this.peer.on('data', e => this.onData(e))
    this.peer.on('stream', e => this.onStream(e))
    this.peer.on('close', e => this.close())
    this.peer.on('error', e => console.debug(`[P2PAvatar #${this.index}] Error: ${e.message}`))

    // Start stats timer
    // this.statsTimer = setInterval(this.doStatsCheck.bind(this), 5000)
  }

  /** Called when simple-peer has a signal for us to send to the remote side */
  onSignalSend(data) {
    // Adjust bitrate (https://discord.com/channels/612575111718895616/775871007822839813/973145247372677140)
    let bitrate = 48 // kbps
    if (data.sdp)
      data.sdp = data.sdp.replace(
        'useinbandfec=1',
        `useinbandfec=1; maxaveragebitrate=${Math.max(
          8000,
          isNaN(bitrate) ? 24000 : bitrate * 1000
        )}; maxplaybackrate=1000`
      )

    // Send it
    this.manager.sendSignal(this.userID, {
      msgID: this.signalsSent++,
      connectionID: this.connectionID,
      data,
      x: this.manager.sharedMetadata.x || 0,
      y: this.manager.sharedMetadata.y || 0,
      z: this.manager.sharedMetadata.z || 0
    })
  }

  /** Called when a signal is received from the remote side of this connection */
  onSignal(signal) {
    // Pass to SimplePeer
    this.peer.signal(signal.data)
    this.signalsReceived += 1
  }

  /** Called when the connection is established */
  onConnect() {
    // Connected!
    this.isConnected = true

    // Start audio check
    if (!this.audioCheckTimer)
      this.audioCheckTimer = setInterval(this.doAudioCheck.bind(this), 1000)

    // TODO: Once all browsers support transferable RTCDataChannel's, transfer the data channel to the Worker
    // background thread.
  }

  /** Do audio distance check */
  doAudioCheck() {
    // Stop if not connected
    if (!this.isConnected) return

    // Remvoe incoming stream if it has ended already
    if (this.incomingAudioStream && !this.incomingAudioStream.active) {
      console.debug(`[P2PAvatar #${this.index}] Incoming audio stream has ended`)
      this.incomingAudioStream = null
      this.hasReceivedAudioStream = false
    }

    // Stop if no audio to send
    if (!this.manager.audio.microphoneStream) return

    // Check what to do
    if (!this.hasSentAudioStream && this.shouldSendAudio) {
      // Send our audio stream
      this.hasSentAudioStream = true
      this.audioStreamSentAt = Date.now()
      this.audioStreamSent = this.manager.audio.microphoneStream
      this.peer.addStream(this.audioStreamSent)
    } else if (this.hasSentAudioStream && !this.shouldSendAudio) {
      // Check if enough time has passed
      if (Date.now() - this.audioStreamSentAt < this.streamRemovalTime) return

      // Stop the stream
      // Can't remove the outgoing stream since we are not cloning it any more ... we need to kill the connection.
      // It should come right back though automatically.
      // for (let track of this.audioStreamSent.getTracks())
      //     track.stop()

      // // Remove our stream
      // this.peer.removeStream(this.audioStreamSent)
      // this.audioStreamSentAt = 0
      // this.audioStreamSent = null
      // this.hasSentAudioStream = false
    } else if (this.shouldSendAudio) {
      // Audio is still being sent, update the date
      this.audioStreamSentAt = Date.now()
    }
  }

  /** Called on data received */
  onData(data) {
    // Check data type
    if (typeof data == 'string') {
      // Handle string packet
      this.onCommandPacket(data)
    } else {
      // Handle data packet
      this.onDataPacket(data)
    }
  }

  /** Called on command packet */
  onCommandPacket(data) {
    // Check packet type
    if (data.startsWith('ping:')) {
      // Send it back
      this.peer.write('pong:' + data.substring(5))
    } else if (data.startsWith('pong:')) {
      // Remote peer replied to our ping packet, update stats
      let pingDate = parseFloat(data.substring(5))
      this.stats.ping = Date.now() - pingDate
    }
  }

  /** Called on data packet */
  onDataPacket(data) {
    // Send packet to Worker to be decoded
    this.stats.tempBytesRead += data.buffer.byteLength
    this.manager.postWorkerMessage(
      { action: 'packet-in', packet: data.buffer, connectionID: this.connectionID },
      [data.buffer]
    )
  }

  /** @private Called when we receive a metadata update from the Worker */
  _onMetadataUpdate(m) {
    // Store it
    let oldDate = this.metadata._date
    this.metadata = m
    this.metadataReceived = true

    // Check if date changed
    if (this.metadata._date == oldDate) return

    // Notify updated
    this.onMetadataUpdate()
  }

  /** Called when the remote user's metadata is updated */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onMetadataUpdate() {}

  /** End the connection */
  close() {
    // Stop peer
    this.peer?.destroy()

    // Connection ended
    console.debug(`[P2PAvatar #${this.index}] Connection closed.`)

    // Stop audio check timer
    if (this.audioCheckTimer) {
      clearInterval(this.audioCheckTimer)
      this.audioCheckTimer = null
    }

    // Remove from active connection list
    this.manager.connections = this.manager.connections.filter(c => c != this)

    // Remove stats timer
    clearInterval(this.statsTimer)
  }

  /** Called when a stream is received */
  onStream(stream) {
    // Send stream to the audio subsystem
    console.debug(`[P2PAvatar #${this.index}] Received stream: id=${stream.id}`)
    this.incomingAudioStream = stream
    this.hasReceivedAudioStream = true
    if (this.manager.audio.receiveStreamFromPeer)
      this.manager.audio.receiveStreamFromPeer(this, stream)
  }

  /** Called every so often to check connection stats */
  // async doStatsCheck() {

  //     // Stop if still fetching
  //     if (this.isFetchingStats) return
  //     this.isFetchingStats = true

  //     // Catch errors
  //     try {

  //         // Get stats
  //         let stats = await this.peer._pc.getStats()

  //         // Parse stats
  //         let dataBytesSent = 0
  //         let dataBytesReceived = 0
  //         let timestamp = 0
  //         stats.forEach(report => {

  //             // Ignore ended streams
  //             if (report.ended)
  //                 return

  //             // Check report type
  //             if (report.type == 'transport') {
  //                 dataBytesSent += report.bytesSent
  //                 dataBytesReceived += report.bytesReceived
  //                 timestamp = report.timestamp
  //             }
  //             // if (report.type == 'track' && report.kind == 'video') {
  //             //     resolution = (report.frameWidth || 0) + 'x' + (report.frameHeight || 0)
  //             //     framesDropped += report.framesDropped || 0
  //             // }

  //         })

  //         // Stop if no timestamp
  //         if (!timestamp || timestamp <= this.lastRtcStatsDate) {
  //             return
  //         }

  //         // Fetch bytes changed since last report
  //         this.stats.bytesWrittenPerSecond = dataBytesSent - (this.lastDataBytesSent || 0)
  //         this.lastDataBytesSent = dataBytesSent
  //         this.stats.bytesReadPerSecond = dataBytesReceived - (this.lastDataBytesReceived || 0)
  //         this.lastDataBytesReceived = dataBytesReceived

  //         // Adjust based on time passed, since this can jitter quite a bit
  //         let timePassed = (timestamp - this.lastRtcStatsDate) / 1000
  //         this.lastRtcStatsDate = timestamp
  //         this.stats.bytesWrittenPerSecond /= timePassed
  //         this.stats.bytesReadPerSecond /= timePassed

  //         // Fix zero values
  //         this.stats.bytesWrittenPerSecond = this.stats.bytesWrittenPerSecond || 0
  //         this.stats.bytesReadPerSecond = this.stats.bytesReadPerSecond || 0

  //         // Send a ping packet
  //         this.peer.write('ping:' + Date.now())

  //     } catch (err) {
  //         console.warn(`[P2PAvatar #${this.index}] Unable to fetch debug stats: ${err.message}`)
  //     }

  //     // Done
  //     this.isFetchingStats = false

  // }
}

/** List of command opcodes */
export const Opcodes = {
  /** A ping packet, followed by a 4-byte random data payload */
  Ping: 0,

  /** A ping reply packet, followed by the same 4-byte random data payload */
  Pong: 1,

  /** A JSON command packet, followed by 4 bytes specifying the size, and then the utf8 encoded buffer after that */
  Command: 2,

  /** Update to the Realtime Data. Next two bytes specify number of fields, and each field after that is an 8-byte float. */
  UpdateRealtimeData: 3
}
