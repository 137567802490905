import { logger } from 'react-native-logs'

const config = {
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3
  },
  transportOptions: {
    colors: {
      info: 'greenBright',
      warn: 'yellowBright',
      error: 'redBright'
    },
    extensionColors: {
      wallet: 'magenta',
      'sdk:core': 'blue',
      'sdk:plugins:erc': 'red',
      'sdk:plugins:erc:react': 'red',
      'sdk:plugins:solana': 'red',
      'sdk:plugins:solana:react': 'red',
      'sdk:plugins:vatom': 'grey',
      'sdk:plugins:vatom:react': 'grey',
      'sdk:react': 'green',
      wombo: 'whiteBright',
      'ar-camera': 'blueBright',
      'business-sdk': 'magentaBright'
    }
  },
  dateFormat: 'iso',
  enabled: __DEV__ ? true : false
}

const log = logger.createLogger(config)

export default log
