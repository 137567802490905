import { useLayoutEffect } from 'react'

const REMOVE_SCROLL_CLASS_NAME = 'remove-scroll'

function addClassName() {
  const bodyEl = document?.getElementsByTagName('body')[0]
  if (bodyEl) {
    bodyEl.classList.add(REMOVE_SCROLL_CLASS_NAME)
  }
}
function removeClassName() {
  const bodyEl = document?.getElementsByTagName('body')[0]
  if (bodyEl) {
    bodyEl.classList.remove(REMOVE_SCROLL_CLASS_NAME)
  }
}

function removeBodyScroll() {
  return {
    add: addClassName,
    remove: removeClassName
  }
}

type UseRemoveBodyScroll = {
  enabled?: boolean
}

function useRemoveBodyScroll({ enabled }: UseRemoveBodyScroll = { enabled: true }) {
  useLayoutEffect(() => {
    const { add, remove } = removeBodyScroll()
    if (enabled) add()
    return () => {
      if (enabled) remove()
    }
  }, [enabled])
}

export { removeBodyScroll, useRemoveBodyScroll }
export type { UseRemoveBodyScroll }
