import { useQuery } from '@tanstack/react-query'
import { Firebase } from '@vatom/sdk/core'
import { SDKQueryClient, useSDK } from '@vatom/sdk/react'

async function getFirebase(sdk: ReturnType<typeof useSDK>) {
  try {
    const firebaseApp = await Firebase.init(sdk)
    if (!firebaseApp) {
      throw new Error(`firebaseApp could't load.`)
    }
    return firebaseApp
  } catch (error) {
    console.error('useFirebase.error:', error)
    return null
  }
}

export const useFirebase = () => {
  const sdk = useSDK()
  const fireBase = useQuery({
    queryKey: ['firebase-client', sdk.dataPool.sessionStore.vatomIncSessionToken],
    queryFn: async () => getFirebase(sdk),
    refetchOnWindowFocus: false
  })
  return fireBase
}

export const getFirebaseApp = async (sdk: ReturnType<typeof useSDK>) => {
  return await SDKQueryClient.ensureQueryData({
    queryKey: ['firebase-client', sdk.dataPool.sessionStore.vatomIncSessionToken],
    queryFn: async () => getFirebase(sdk)
  })
}
