import type { EventEmitter } from '@vatom/sdk/core'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import Axios from 'axios'
// import { clone, merge } from 'lodash-es'

// import logger from '../../../logger'

// import { CurlHelper } from './CurlHelper'

// export const ErrorUnauthorized401 = 'Unauthorized 401'

interface EmitterConfig {
  emitter: EventEmitter
  logoutEvent: string
}

// const times: Record<string, number> = {}

// const getTimesKey = (config?: AxiosRequestConfig) => {
//   if (!config) {
//     return 0
//   }
//   // logger.debug('getTimesKey', config)
//   const method = (config && config.method) || 'get'
//   return `${method}-${config.url}`
// }

// const requestHandler = () => {
//   return (config: AxiosRequestConfig) => {
//     const parsedURL = new URL(config.url || '/', config.baseURL)
//     const method = String(config.method) || 'get'
//     let headers = config.headers ? clone(config.headers[method]) : {}

//     if (parsedURL.hostname === 'localhost' || parsedURL.origin === Axios.defaults.baseURL) {
//       headers = merge({}, Axios.defaults.headers.common, headers)
//     }

//     config.headers[method] = headers

//     logger.info('[Network Request]', new CurlHelper(config).generateCommand())
//     // logger.info('[Network Request]', config)
//     times[getTimesKey(config)] = performance.now()

//     return config
//   }
// }

// const responseHandler = () => {
//   return (response: AxiosResponse) => {
//     const ts = Number(performance.now() - times[getTimesKey(response.config)])
//     // logger.info(
//     //   '[Network Response]',
//     //   `(${ts}ms)`,
//     //   // '\n',
//     //   // new CurlHelper(response.config).generateCommand(),
//     //   response.status,
//     //   // '\n',
//     //   response.data
//     // )

//     return response
//   }
// }

// const errorResponseHandler = (emitterConfig?: EmitterConfig) => {
//   return (error: AxiosError) => {
//     let ts = Number(performance.now() - times[getTimesKey(error.config)])

//     // check for errorHandle config
//     // if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
//     //   return Promise.reject(error)
//     // }

//     if (error.response) {
//       ts = Number(performance.now() - times[getTimesKey(error.response.config)])
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//       // log(error.response.data)
//       // log(error.response.status)
//       // log(error.response.headers)
//       const response = error.response
//       logger.info(
//         '[Network Error]',
//         `(${ts}ms)`,
//         // '\n',
//         new CurlHelper(error.config).generateCommand(),
//         // '\n',
//         response.statusText,
//         response.status,
//         // '\n',
//         response
//       )
//       if (response.status === 401 || response.status === 403) {
//         emitterConfig && emitterConfig.emitter.emit(emitterConfig.logoutEvent)
//         // emitter.emit(LogoutEvent)
//         // throw new Error(ErrorUnauthorized401)
//       } else if (response.status === 400) {
//         throw error
//       }
//     } else {
//       logger.info(
//         '[Network Error]',
//         error,
//         `(${ts}ms)`,
//         // '\n'
//         error.config,
//         error.request,
//         error.code
//         // new CurlHelper(error.config).generateCommand()
//       )
//     }

//     // Sentry.captureError(error)
//     throw error.response?.data.message
//       ? new Error(error.response.data.message)
//       : error.response?.statusText
//       ? new Error(error.response.statusText)
//       : error
//   }
// }

export class AxiosFactory {
  public static create(options: {
    axiosConfig?: AxiosRequestConfig
    emitterConfig?: EmitterConfig
  }): AxiosInstance {
    const instance = Axios.create({
      headers: { 'Content-Type': 'application/json' },
      timeout: 10000,
      ...options.axiosConfig
    })
    // instance.interceptors.request.use(requestHandler(), errorResponseHandler(options.emitterConfig))
    // instance.interceptors.response.use(
    //   responseHandler(),
    //   errorResponseHandler(options.emitterConfig)
    // )
    return instance
  }
}
